import axios from 'axios';

const BASE_URL = "https://rest.ino-vibe.ino-on.dev";

export const getProfile = (success, error) => {
    console.log("user.getProfile");

    var url = BASE_URL + "/rest/v3/user/profile";

    axios({
        method: "GET",
        url: url,
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "Authorization": "Bearer " + localStorage.getItem("access_token"),
        }
    }).then(resp => {
        const user = new User(resp.data.username, resp.data.group_id, resp.data.group_name);

        if(success != null) {
            success(user);
        }
    }).catch(err => {
        console.log(err);
        if(err != null) {
            error(err);
        }
    })

}

export const isAdmin = () => {
    var roles = [];
    if (localStorage.getItem('authorization') != null) {
        roles = JSON.parse(localStorage.getItem('authorization'))['roles'];
    }
    return roles.includes("admin");
};


class User {
    constructor(username, groupID, groupName) {
        this.username = username;
        this.groupID = groupID;
        this.groupName = groupName;
    }
}