import React from "react";

import "../css/CircleLoading.css";


const CircleLoading = ({color, hintColor, strokeWidth, size}) => {
    return (
        <div className={"circle_loading_container"}
            style={{
                border: `${strokeWidth} solid ${hintColor}`,
                borderLeft: `${strokeWidth} solid ${color}`,
                width: `${size}`,
                height: `${size}`,
            }}>
        </div>
    );
}

export default CircleLoading;