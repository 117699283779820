import React from "react";

import { Translation } from 'react-i18next';
import CloseButton from "./CloseButton";
import TrashButton from "./TrashButton";

import { eventStore, EVT_HIDE_INBOX_MODAL, EVT_SELECT_DEVICE } from "../App";
import { userInboxMsgs, setMsgRead, deleteMsg } from "../InoVibe/inbox";
import { formatDateString } from "../utils/dateutils";

import icon_excavation from "../images/icon-excavation.svg";
import icon_inclination from "../images/icon-inclination.svg";
import icon_nosignal from "../images/icon-nosignal.svg";
import icon_nomessage from "../images/icon-nomessage.svg";

import "../css/Inbox.css";


class Inbox extends React.Component {
    DISPLAY_COUNT_INCREASE_STEP = 50;

    constructor(props) {
        super(props);

        this.state = {
            show: false,
            msgs: [],
            unreadCount: 0,
            displayCount: this.DISPLAY_COUNT_INCREASE_STEP,
        }

        this.inboxSubscribe = null;
    }

    componentDidMount() {
        var current = new Date();
        var startDate = new Date();
        startDate.setDate(current.getDate() - 7);

        console.log(startDate, current);

        this.inboxSubscribe = userInboxMsgs.subscribe((newMsgs) => {
            console.log("Inbox: Total " + newMsgs.length + " loaded");

            var msgs = [];
            var unreadCount = 0;
            Object.keys(newMsgs).forEach(msgid => {
                if(!newMsgs[msgid].is_read) {
                    unreadCount++;
                }
                msgs.push(newMsgs[msgid]);
            });

            msgs.sort((a, b) => {
                return b.time_created - a.time_created;
            });

            this.setState({msgs: msgs, unreadCount: unreadCount});
        });
    }

    componentWillUnmount() {
        if(this.inboxSubscribe != null) {
            this.inboxSubscribe.unsubscribe()
        }
    }

    UNSAFE_componentWillReceiveProps(props) {
        if(props.show != null) {
            console.log("Inbox: componentWillReceiveProps");
            this.setState({
                show: props.show,
                displayCount: this.DISPLAY_COUNT_INCREASE_STEP,
            });
        }
    }

    render() {
        const current = new Date();
        var targetDate = new Date();
        targetDate.setDate(current.getDate() - 7);

        console.log("Inbox: " + this.state.msgs.length + " display");

        const {msgs, displayCount} = this.state;

        if(this.state.show) {
            return (
                <div className="inbox_container">
                    <div className="inbox_content">
                        <div className="inbox_close">
                            <CloseButton onClick={this.closeInbox.bind(this)}/>
                        </div>
                        <div className="inbox_title">
                            <Translation>
                                {
                                    (t) => <p>{ t("inbox-title", "Inbox") }</p>
                                }
                            </Translation>
                            </div>
                        <div className="inbox_menu">
                            <div className="inbox_menu__item">
                                <Translation>
                                    {
                                        t => <p>{ t("inbox-tab-alarm", "Alarm") }</p>
                                    }

                                </Translation>
                                </div>
                            <div></div>
                        </div>
                        <div className="inbox_control">
                            <div className="inbox_control__date">
                                <p>{targetDate.toLocaleDateString()} - {current.toLocaleDateString()}</p>
                            </div>
                        {
                            this.state.unreadCount > 0 ? (
                                <div className="inbox_control__clear" onClick={() => this.readAllMsgs()}>
                                    <Translation>
                                        {
                                            t => <p>{ t("inbox-read-all", "Read All") }</p>
                                        }
                                    </Translation>
                                </div>
                            ) : (
                                <div className="inbox_control__clear" onClick={() => this.deleteAllMsgs()}>
                                    <Translation>
                                        {
                                            t => <p>{ t("inbox-delete-all", "Delete All") }</p>
                                        }
                                    </Translation>
                                </div>
                            )
                        }
                        </div>
                        <div className="inbox_msg" onScroll={(e) => this.onScroll(e)}>
                        {
                            this.state.msgs.length === 0 ? (
                                <div className="inbox_msg__nomsg_container">
                                    <img alt="inbox_msg__nomsg" className="inbox_msg__nomsg" src={icon_nomessage}></img>
                                    <Translation>
                                        {
                                            t => <p>{ t("inbox-no-message", "There is no inbox messages") }</p>
                                        }
                                    </Translation>
                                </div>
                            ) : (
                                msgs.slice(0, displayCount).map((item, i) => {
                                    return <InboxAlarmMsg
                                                key={item.id}
                                                msgid = {item.id}
                                                devid={item.devid}
                                                alias={item.alias}
                                                is_read={item.is_read}
                                                is_removeed={item.is_removed}
                                                time_created={item.time_created}
                                                time_issued={item.time_issued}
                                                msg_type={item.msg_type}/>;
                                })
                            )
                        }
                        </div>
                    </div>
                    <div className="inbox_padding" onClick={() => this.closeInbox()}></div>
                </div>
            );
        } else {
            return null;
        }
    }

    closeInbox() {
        eventStore.dispatch({type: EVT_HIDE_INBOX_MODAL});
    }

    readAllMsgs() {
        console.log("Inbox: readAllMsgs");
        const { msgs } = this.state;
        var msgIDs = [];
        msgs.forEach(msg => {
            if(!msg.is_read) {
                msgIDs.push(msg.id);
            }
        });

        if(msgIDs.length > 0) {
            setMsgRead(msgIDs);
        }
    }

    deleteAllMsgs() {
        console.log("Inbox: deleteAllMsgs");
        const { msgs } = this.state;
        var msgIDs = [];
        msgs.forEach(msg => {
            msgIDs.push(msg.id);
        });

        if(msgIDs.length > 0) {
            deleteMsg(msgIDs);
        }
    }

    onScroll(e) {
        console.log(e.target.clientHeight, e.target.scrollHeight);

        if((e.target.scrollTop + e.target.clientHeight) === e.target.scrollHeight) {
            this.setState({
                displayCount: this.state.displayCount + this.DISPLAY_COUNT_INCREASE_STEP,
            });
        }
    }
}

class InboxAlarmMsg extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            onClosing: false
        };
    }

    render() {
        const {msgid, devid, alias, is_read, time_created, time_issued, msg_type} = this.props;
        var icon = null;

        if(msg_type === "no_signal") {
            icon = icon_nosignal;
        } else if(msg_type === "inclination") {
            icon = icon_inclination;
        } else {
            icon = icon_excavation;
        }

        return (
            <div className={"inbox_msg_container" + (this.state.onClosing ? " inbox_msg_closing" : "")}
                onTransitionEnd={() => { this.requestDelete(msgid) }}>
                <div className="inbox_msg_close">
                    <TrashButton onClick={() => this.setState({ onClosing: true })}/>
                </div>
                <div className="inbox_msg__msg_container">
                    <div className="inbox_msg__read_indicator">
                    {
                        is_read ? (
                            null
                        ) : (
                            <div></div>
                        )
                    }
                    </div>
                    <div className="inbox_msg__msg_icon">
                        <img src={icon} alt={"alarm_image"}/>
                    </div>
                    <div className="inbox_msg__msg_body">
                        <div className="inbox_msg__msg_title" onClick={() => this.selectMsg(msgid, devid)}>
                            <p>{devid.substring(devid.length - 6).toUpperCase()} ・ {alias}</p>
                        </div>
                        <div className="inbox_msg__msg_desc">
                            <Translation>
                                {
                                    t => <p>{ formatDateString(time_issued)} { t(msg_type, msg_type) }가 발생했습니다. </p>
                                }

                            </Translation>
                        </div>
                    </div>
                </div>
                <div className="inbox_msg__time"><p>{formatDateString(time_created)}</p></div>

            </div>
        );
    }

    selectMsg(msgID, devid) {
        console.log("Select Msg: ", msgID, devid);

        setMsgRead([msgID]);

        eventStore.dispatch({type: EVT_HIDE_INBOX_MODAL});
        eventStore.dispatch({type: EVT_SELECT_DEVICE, devid: devid});
    }

    requestDelete(msgID) {
        if(this.state.onClosing) {
            console.log("Remove Inbox Message ", msgID);
            deleteMsg(msgID);
        }
    }
}

export default Inbox;