import axios from 'axios';

const BASE_URL = "https://rest.ino-vibe.ino-on.dev";

export const AlertType = Object.freeze({
    NoSignal: "no_signal",
    Inclination: "inclination",
    Excavation: "excavation",
});

class Alert {
    constructor(entity) {
        this.devid = entity["devid"];
        this.groupID = entity["group_id"] === "" ? null : entity["group_id"];
        this.groupName = entity["group_name"] === "" ? null : entity["group_name"];
        this.issued = new Date(entity["issued"]);
        this.type = entity["type"];
        this.waveID = entity["waveid"] === "" ? null : entity["waveid"];
    }
}

export const listAlarmsByGroup = (from, to, success, error) => {
    var url = BASE_URL + "/rest/v3/alarm";
    url += "?from=" + from;
    url += "&to=" + to;

    console.log("alarm.listAlarmsByGroup ", url);

    axios({
        method: "GET",
        url: url,
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "Authorization": "Bearer " + localStorage.getItem("access_token"),
        }
    }).then(resp => {
        var alerts = [];
        resp.data.alarms.forEach(entity => {
            var newAlert = new Alert(entity);
            alerts.push(newAlert);
        });

        if(success != null) {
            success(alerts);
        }
    }).catch(err => {
        console.log(err);
        if(err != null) {
            error(err);
        }
    })
}