import axios from 'axios';

const BASE_URL = "https://rest.ino-vibe.ino-on.dev";

export const waveDetail = (waveid, success, error) => {
    var url = BASE_URL + "/rest/v3/wave/" + waveid;

    console.log(url);

    axios({
        method: "GET",
        url: url,
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "Authorization": "Bearer " + localStorage.getItem("access_token")
        }
    }).then(resp => {
        success(resp);
    }).catch(err => {
        console.log("ERROR", err);
        error(err);
    });
}