import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next';
import { getStatusLog } from '../InoVibe/device';
import { logEvent } from "../event";

import { Chart } from 'react-google-charts'

import '../css/TrendChart.css';


const TrendChart = ({ device }) => {
    const batteryChartOptions = {
        vAxis: {
            title: "%",
            viewWindow: { min: -5, max: 105 },
            titleTextStyle: {
                color: "#FFFFFF",
            },
            textStyle: {
                color: "#FFFFFF",
            },
            baselineColor: "#FFFFFF",
            gridlines: {
                color: "#676767",
            },
            minorGridlines: {
                color: "#37404C",
            },
        },
        hAxis: {
            titleTextStyle: {
                color: "#FFFFFF",
            },
            textStyle: {
                color: "#FFFFFF",
            },
            baselineColor: "#FFFFFF",
            gridlines: {
                color: "#676767",
            },
            minorGridlines: {
                color: "#37404C",
            },
        },
        legend: { position: "none" },
        lineWidth: 1,
        backgroundColor: { fill: "transparent" },
        series: {
            0: { color: "#48CDD8", lineWidth: 1.5 },
        },
        chartArea: {
            width: "80%",
            height: "75%",
        },
    };

    const batteryChartColumns = [
        { type: 'date', label: 'Date' },
        { type: 'number', label: 'Battery' }
    ];

    const temperatureChartOptions = {
        vAxis: {
            title: "˚C",
            viewWindow: { min: -30, max: 80 },
            titleTextStyle: {
                color: "#FFFFFF",
            },
            textStyle: {
                color: "#FFFFFF",
            },
            baselineColor: "#FFFFFF",
            gridlines: {
                color: "#676767",
            },
            minorGridlines: {
                color: "#37404C",
            },
        },
        hAxis: {
            titleTextStyle: {
                color: "#FFFFFF",
            },
            textStyle: {
                color: "#FFFFFF",
            },
            baselineColor: "#FFFFFF",
            gridlines: {
                color: "#676767",
            },
            minorGridlines: {
                color: "#37404C",
            },
        },
        legend: { position: "none" },
        lineWidth: 1,
        backgroundColor: { fill: "transparent" },
        series: {
            0: { color: "#48CDD8", lineWidth: 1.5 },
        },
        chartArea: {
            width: "80%",
            height: "75%",
        },
    };

    const temperatureChartColumns = [
        { type: 'date', label: 'Date' },
        { type: 'number', label: 'Temperature' }
    ];

    const RSSIChartOptions = {
        vAxis: {
            title: "dB",
            viewWindow: { min: -130, max: -50 },
            titleTextStyle: {
                color: "#FFFFFF",
            },
            textStyle: {
                color: "#FFFFFF",
            },
            baselineColor: "#FFFFFF",
            gridlines: {
                color: "#676767",
            },
            minorGridlines: {
                color: "#37404C",
            },
        },
        hAxis: {
            titleTextStyle: {
                color: "#FFFFFF",
            },
            textStyle: {
                color: "#FFFFFF",
            },
            baselineColor: "#FFFFFF",
            gridlines: {
                color: "#676767",
            },
            minorGridlines: {
                color: "#37404C",
            },
        },
        legend: { position: "none" },
        lineWidth: 1,
        backgroundColor: { fill: "transparent" },
        series: {
            0: { color: "#48CDD8", lineWidth: 1.5 },
        },
        chartArea: {
            width: "75%",
            height: "75%",
        },
    };

    const RSSIChartColumns = [
        { type: 'date', label: 'Date' },
        { type: 'number', label: 'RSSI' }
    ];

    const waveChartOptions = {
        title: "Wave",
        vAxis: { title: "mG" },
        hAxis: { title: "Seconds" }
    };

    const waveChartColumns = [
        { type: 'number', label: 'Second' },
        { type: 'number', label: 'X' },
        { type: 'number', label: 'Y' },
        { type: 'number', label: 'Z' },
    ];

    const [batteryChartData, setBatteryChartData] = useState([]);
    const [temperatureChartData, setTemperatureChartData] = useState([]);
    const [rssiChartData, setRSSIChartData] = useState([]);

    const { t } = useTranslation();

    const waveChartData = [];

    const defaultPageSize = 100;
    const dateBefore = 30;

    useEffect(() => {
        console.log("TrendChart", device.devid);
        logEvent("Page-TrendChart");

        var current = new Date();
        var dateFrom = new Date(current.getTime() - (1000 * 60 * 60 * 24 * dateBefore));

        getStatusLog(device.devid, dateFrom, defaultPageSize)
            .subscribe({
                next: (data) => {
                    console.log("getStatuslog sub", data);
                    setBatteryChartData(data.battery);
                    setTemperatureChartData(data.temperature);
                    setRSSIChartData(data.rssi);
                },
                error: (err) => {
                    console.log("getStatuslog err", err);
                },
            });
    }, []);

    var component = (device.devid.length === 8) ? (
        <div className="trend_chart__container">
            <Chart chartType="LineChart"
                key="{this.state.devid}-wave"
                rows={waveChartData}
                columns={waveChartColumns}
                options={waveChartOptions}
                width="100%"
                height="95%"></Chart>
        </div>
    ) : (
        <div className="trend_chart__container">
            <div>
                <div className="trend_chart__title_container">
                    <p className="trend_chart__title">{ t("battery") }</p>
                </div>
                <div className={"trend_chart__chart"}>
                    <Chart chartType="LineChart"
                        key="{this.state.devid}-bat"
                        rows={batteryChartData}
                        columns={batteryChartColumns}
                        options={batteryChartOptions}
                        width="100%"
                        height="100%" />
                </div>

                <div className="trend_chart__title_container">
                    <p className="trend_chart__title">{ t("temperature") }</p>
                </div>
                <div className={"trend_chart__chart"}>
                    <Chart chartType="LineChart"
                        key="{this.state.devid}-temp"
                        rows={temperatureChartData}
                        columns={temperatureChartColumns}
                        options={temperatureChartOptions}
                        width="100%"
                        height="100%" />
                </div>

                <div className="trend_chart__title_container">
                    <p className="trend_chart__title">{ t("rssi") }</p>
                </div>
                <div className={"trend_chart__chart"}>
                    <Chart chartType="LineChart"
                        key="{this.state.devid}-rssi"
                        rows={rssiChartData}
                        columns={RSSIChartColumns}
                        options={RSSIChartOptions}
                        width="100%"
                        height="100%" />
                </div>
            </div>
        </div>
    )

    return component;
}

export default TrendChart;