import React, { useState, useEffect } from "react";


export const TabControl = (props) => {
    const [selectIndex, setSelectIndex] = useState(0);
    const [tabContents, setTabContents] = useState([]);

    const { key } = props;

    useEffect(() => {
        var contents = [];
        props.children.forEach(child => {
            if (child != null) {
                contents.push(child);
            }
        });

        setTabContents(contents);
    }, [props, key]);

    const { menuBackgroundColor, menuSelectColor } = props;
    const { menuColor } = props;
    const { width, height } = props;

    console.log(width, height);

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            fontSize: "0.8em",
        }}>
            <div style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                height: "30px",
            }}>
                {
                    tabContents.map((content, i) => {
                        const bgColor = selectIndex === i ? menuSelectColor : menuBackgroundColor;
                        var style = {
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            cursor: "pointer",
                            backgroundColor: bgColor ? bgColor : "white",
                            color: menuColor,
                        };

                        return (
                            <div key={i} style={style}
                                onClick={() => { setSelectIndex(i); }}>
                                <p style={{ margin: "0px", padding: "0px", textAlign: "center" }}>{content.props.title}</p>
                            </div>
                        );
                    })
                }
            </div>
            <div style={{
                display: "flex",
                width: "100%",
                height: "100%",
                overflowY: "scroll",
            }}>{tabContents[selectIndex]}</div>
        </div>
    );
}

export const TabItem = (props) => {
    const { contentBackgroundColor } = props;

    return (
        <div style={{
            display: "flex",
            width: "100%",
            height: "100%",
            backgroundColor: contentBackgroundColor ? contentBackgroundColor : "white",
        }}>
            {props.children}
        </div>
    );
}