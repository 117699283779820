import * as Rx from 'rxjs';

import "firebase/auth";
import "firebase/firestore";

import { createFirestore } from "../InoVibe/firebase.js";
import { eventStore, EVT_NEW_INBOX_MSG, EVT_READ_ALL_INBOX_MSG } from "../App";

const FIRESTORE_MAX_WRITE_COUNT = 500;

class InboxMsg {
    constructor(fsID, fsDoc) {
        this.id = fsID;
        this.msg_type = fsDoc.msg_type;
        this.devid = fsDoc.devid;
        this.alias = fsDoc.alias;

        this.group_id = fsDoc.group_id;
        this.group_name = fsDoc.group_name;

        this.time_created = new Date(fsDoc.time_created.seconds * 1000);
        this.time_issued = new Date(fsDoc.time_issued.seconds * 1000);
        if(fsDoc.time_read != null) {
            this.time_read = new Date(fsDoc.time_read.seconds * 1000);
        }

        this.is_read = fsDoc.is_read;
        this.is_removed = fsDoc.is_removed;

        // Becase "is_closed" property added after Firestore entity created at first time,
        // "is_closed" property can be omited.
        // So remain null check code.
        // - jkkim@ino-on.com
        this.is_closed = fsDoc.is_closed == null ? false : fsDoc.is_closed;
    }
}

var firestoreDB = null;
export var userInboxMsgs = new Rx.BehaviorSubject({});

export const initializeInbox = () => {
    createFirestore(db => {
        console.log("Inbox create DB success");
        firestoreDB = db;
        monitorInboxMessages(localStorage.getItem("username"));
    }, err => {
        console.log("Inbox create DB failed " + err);
    })
}

const monitorInboxMessages = (email) => {
    // console.log("getInboxMsg for ", email, localStorage.getItem("firebase_token"));
    const current = new Date();
    var targetDate = new Date();
    targetDate.setDate(current.getDate() - 7);

    firestoreDB.collection("inbox").doc(email).get().then(inbox => {
        inbox.ref.collection("msg")
            .where("is_removed", "==", false)
            .where("time_issued", ">=", targetDate)
            .onSnapshot(snapshot => {
                snapshot.docChanges().forEach(change => {
                    const msg = change.doc;

                    if (change.type === "added") {
                        // console.log("Add");
                        userInboxMsgs.value[msg.id] = new InboxMsg(msg.id, msg.data());
                    }

                    if (change.type === "modified") {
                        console.log("modified");
                        userInboxMsgs.value[msg.id] = new InboxMsg(msg.id, msg.data());
                    }

                    if (change.type === "removed") {
                        console.log("remove");
                        delete userInboxMsgs.value[msg.id];
                    }
                });

                userInboxMsgs.next(userInboxMsgs.value);

                var msgList = [];
                Object.keys(userInboxMsgs.value).forEach(msgid => {
                    msgList.push(userInboxMsgs.value[msgid]);
                });

                var anyNewMsg = false;
                for (var i = 0; i < msgList.length; i++) {
                    if (!msgList[i].is_read) {
                        anyNewMsg = true;
                        break;
                    }
                }

                if (anyNewMsg) {
                    eventStore.dispatch({ type: EVT_NEW_INBOX_MSG });
                } else {
                    eventStore.dispatch({ type: EVT_READ_ALL_INBOX_MSG });
                }
            });
    });
}

export const setMsgRead = (msgIDs) => {
    console.log("inbox: setMsgRead ", msgIDs);
    const username = localStorage.getItem("username");

    let subSlices = splitSlice(msgIDs, FIRESTORE_MAX_WRITE_COUNT);
    subSlices.forEach(IDs => {
        var batch = firestoreDB.batch();
        IDs.forEach(msgID => {
            var docRef = firestoreDB.collection("inbox").doc(username).collection("msg").doc(msgID);
            batch.update(docRef, {is_read: true, time_read: new Date()});
        })

        batch.commit().then(() => {
            console.log("inbox: setMsgRead Success");
        }).catch(err => {
            console.log("inbox: setMsgRead Failed", err);
        });
    });
}

function splitSlice(slice, max) {
    var splits = [];
    var subSlice = [];
    slice.forEach(item => {
        subSlice.push(item);
        if(subSlice.length === max) {
            splits.push(subSlice);
            subSlice = [];
        }
    });

    if(subSlice.length > 0) {
        splits.push(subSlice);
    }

    return splits;
}

export const markMsgClosed = (msgIDs) => {
    console.log("inbox: markMsgClosed", msgIDs);
    const username = localStorage.getItem("username");

    let subSlices = splitSlice(msgIDs, FIRESTORE_MAX_WRITE_COUNT);
    subSlices.forEach(IDs => {
        var batch = firestoreDB.batch();
        IDs.forEach((msgID, i) => {
            var docRef = firestoreDB.collection("inbox").doc(username).collection("msg").doc(msgID);
            batch.update(docRef, {is_closed: true});
        });

        batch.commit().then(() => {
            console.log("inbox: markMsgClosed Success");
        }).catch(err => {
            console.log("inbox: markMsgClosed Failed", err);
        });
    });
}

export const deleteMsg = (msgIDs) => {
    console.log("inbox: deleteMsg ", msgIDs);
    const username = localStorage.getItem("username");

    let subSlices = splitSlice(msgIDs, FIRESTORE_MAX_WRITE_COUNT);
    subSlices.forEach(IDs => {
        var batch = firestoreDB.batch();
        IDs.forEach(msgID => {
            userInboxMsgs.value[msgID].is_removed = true;
            delete userInboxMsgs.value[msgID];
            userInboxMsgs.next(userInboxMsgs.value);

            var docRef = firestoreDB.collection("inbox").doc(username).collection("msg").doc(msgID);
            batch.update(docRef, {is_removed: true, is_read: true, time_read: new Date()});
        });

        batch.commit().then(() => {
            console.log("inbox: Transaction success");
        }).catch(err => {
            console.log("inbox: Transaction failed", err);
        });
    });
}