import * as Rx from 'rxjs';


export class InoVibeG {
    constructor(id) {
        this.devid = id;
        this.dev_type = 101;
        this.alias = null;

        this.installLocation = null;
        this.installer = null;

        this.timeCreated = null;
        this.timeUpdated = null;
        this.timeInstalled = null;

        this.isEnableDisplacement = false;
        this.isEnableTension = false;

        this.battery = null;
        this.temperature = null;
        this.humid = null;
        this.groupID = null;
    }

    data() {
        return {
            devid: this.id,
            alias: this.alias,
            dev_type: this.dev_type,
            installLocation: this.installLocation,
            installer: this.installer,
            timeCreated: this.timeCreated,
            timeUpdated: this.timeUpdated,
            timeInstalled: this.timeInstalled,
            isEnableDisplacement: this.isEnableDisplacement,
            isEnableTension: this.isEnableTension,
            battery: this.battery,
            temperature: this.temperature,
            humid: this.humid,
            groupID: this.groupID,
        };
    }
}

export const listInoVibeG = (db) => {
    console.log("InoVibeG.listInoVibeG");
    const subject = new Rx.Subject();

    db.collection("testbed-device")
        .where("install_location", "!=", "nil")
        .onSnapshot(docs => {
            var devs = [];

            docs.forEach(doc => {
                const newDev = createInoVibeG(doc.id, doc.data());
                devs.push(newDev);
            });

            subject.next(devs);
        })

    return subject;
}

export const listWave = (db, devid) => {
    // TODO: List wave.
}
export const retrieveWave = (db, waveid) => {
    // TODO: Get wave data.
}

export const retrieveState = (db, devid) => {
}

export const retrieveTension = (db, devid) => {
}

const createInoVibeG = (id, data) => {
    var newDev = new InoVibeG(id);
    newDev.alias = data.alias;

    newDev.installLocation = data.install_location;
    newDev.installer = data.installer;

    newDev.timeCreated = data.time_created.toDate();
    newDev.timeUpdated = data.time_updated.toDate();
    newDev.timeInstalled = data.time_installed.toDate();

    newDev.isEnableDisplacement = data.mode_displacement == null ? false : data.mode_displacement;
    newDev.isEnableTension = data.mode_tension == null ? false : data.mode_tension;
    newDev.groupID = data.group_id;

    // TODO: Calculate battery level in percentage.
    newDev.battery = data.battery_mv;
    newDev.temperature = data.temperature;
    newDev.humid = data.humid;

    return newDev;
}