import axios from "axios";
import { Observable } from "rxjs";


const BASE_URL = "https://rest.ino-vibe.ino-on.dev";

export const listGroups = () => {
    console.log("listGroups");

    return new Observable((observer => {
        const accessToken = localStorage.getItem("access_token");

        axios({
            method: "GET",
            url: BASE_URL + "/rest/v3/group",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "Authorization": `Bearer ${accessToken}`,
            }
        }).then(resp => {
            observer.next(resp.data);
        }).catch(e => {
            console.log("listGroups Err " + e);
        })
    }));
}

export const flatGroupTree = (groups) => {
    var groupMap = [];

    groups.forEach(g => {
        groupMap.push({ id: g.id, name: g.name, individual: g.individual });
        if (g.children != null && g.children.length !== 0) {
            const children = flatGroupTree(g.children);
            groupMap.push(...children);
        }
    });

    return groupMap;
}

export const createGroup = (name, parent) => {
    console.log("createGroup", name, parent);

    var parentGroupID = "";
    if (parent != null) {
        parentGroupID = parent.id;
    }

    return new Observable((observer => {
        const accessToken = localStorage.getItem("access_token");

        axios({
            method: "POST",
            url: BASE_URL + "/rest/v3/group",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "Authorization": `Bearer ${accessToken}`,
            },
            data: {
                parent_id: parentGroupID,
                name: name,
            }
        }).then(resp => {
            console.log("createGroups Resp");
            observer.next(resp.data);
        }).catch(e => {
            console.log("createGroups Err " + e);
            observer.error(e);
        })
    }));
}

export const getGroup = (id) => {
    console.log("getGroup");

    return new Observable(observer => {
        const accessToken = localStorage.getItem("access_token");
        axios({
            method: "GET",
            url: `${BASE_URL}/rest/v3/group/${id}`,
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "Authorization": `Bearer ${accessToken}`,
            },
        }).then(resp => {
            observer.next(resp.data);
        }).catch(e => {
            observer.error(e);
        });
    });
}

export const deleteGroup = (groupID) => {
    console.log("deleteGroup", groupID);

    if(groupID === "") {

        return;
    }

    return new Observable((observer => {
        const accessToken = localStorage.getItem("access_token");

        axios({
            method: "DELETE",
            url: BASE_URL + `/rest/v3/group/${groupID}`,
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "Authorization": `Bearer ${accessToken}`,
            },
        }).then(resp => {
            console.log("deleteGroup Resp", resp);
            observer.next(resp.data);
        }).catch(e => {
            console.log("deleteGroup Err", e);
            observer.error(e);
        });
    }));
}

export const listMember = (groupID) => {
    console.log("listMember");

    return new Observable(observer => {
        const accessToken = localStorage.getItem("access_token");
        axios({
            method: "GET",
            url: BASE_URL + `/rest/v3/group/${groupID}/member`,
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "Authorization": `Bearer ${accessToken}`,
            }
        }).then(resp => {
            console.log("listMember Resp", resp.data);
            observer.next(resp.data);
        }).catch(e => {
            console.log("listMember Err " + e);
        })
    });
}