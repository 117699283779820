import React from "react";


const CircleMark = () => {
    var style = {
        "width": "6px",
        "height": "6px",
        "backgroundColor": "#fc1c05",
        "borderRadius": "50%",
        "margin": "5px",
    };

    return (
        <div style={style}>
        </div>
    );
}

export default CircleMark;