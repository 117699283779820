import * as Rx from 'rxjs';


const CommentType = Object.freeze({
    User: 0,
    GroupChange: 1,
    InstallTest: 2,
    Registration: 3,
});

class LoRaDevice {
    constructor(ltid) {
        this.ltid = ltid;
        this.short_id = null;
        this.app_eui = null;
        this.app_key = null;
        this.is_registered = false;

        this.time_activate = null;
        this.time_register = null;
        this.time_outbound = null;

        this.user_register = null;
        this.user_outbound = null;

        this.buyer_group_id = null;

        this.snapshot = null;
    }

    static createFromSnapshot(snapshot) {
        var ltid = snapshot.id;
        var data = snapshot.data();

        var newDevice = new LoRaDevice(ltid)
        newDevice.snapshot = snapshot;
        newDevice.short_id = data.short_id;
        newDevice.app_eui = data.app_eui;
        newDevice.app_key = data.app_key;
        newDevice.is_registered = data.is_registered;

        newDevice.time_activate = data.time_activate != null ? data.time_activate.toDate() : null;
        newDevice.time_register = data.time_register != null ? data.time_register.toDate() : null;
        newDevice.time_outbound = data.time_outbound != null ? data.time_outbound.toDate() : null;

        newDevice.user_register = data.user_register;
        newDevice.user_outbound = data.user_outbound;

        newDevice.buyer_group_id = data.buyer_group_id;

        return newDevice;
    }

}

const listLoRaDevice = (db, limit, after) => {
    console.log("loraDevice.listLoRaDevice");

    const listSubject = new Rx.Subject();
    var q = db.collection("inovibe-lora-device")
        .orderBy("time_activate", "desc");

    if(after != null) {
        console.log("SearchSnapshot", after, after.snapshot);
        q = q.startAfter(after.snapshot);
    }

    q.limit(limit)
        .get()
        .then(docs => {
            var devs = [];
            docs.forEach(d => {
                devs.push(LoRaDevice.createFromSnapshot(d));
            });
            listSubject.next(devs);
        })
        .catch(err => {
            console.log("loraDevice.listLoRaDevice " + err);
        })

    return listSubject;
}

const findLoRaDevice = (db, shortID) => {
    console.log("loraDevice.findLoRaDevice " + shortID);

    const subject = new Rx.Subject([]);
    db.collection("inovibe-lora-device")
        .where("short_id", "==", shortID)
        .get()
        .then(devices => {
            var devs = [];
            devices.forEach(d => {
                devs.push(LoRaDevice.createFromSnapshot(d));
            });
            subject.next(devs);
        })
        .catch(err => {
            console.log("loraDevice.listLoRaDevice " + err);
        })

    return subject;
}

const changeDeviceBuyer = (db, device, group) => {
    console.log("loraDevice.changeDeviceBuyer");

    var field = {};
    if(group === null) {
        field["buyer_group_id"] = null;
    } else {
        field["buyer_group_id"] = group.id;
    }

    const groupSubject = new Rx.Subject();
    db.doc(`inovibe-lora-device/${device.ltid}`)
        .update(field)
        .then(() => {
            console.log("loraDevice.updateLoRaDevice", "success");
            groupSubject.next();
        })
        .catch(e => {
            console.log("loraDevice.updateLoRaDevice", "failed", e);
        });

    const commentSubject = insertDeviceGroupChangeComment(db, device.ltid, group);

    const changeSubject = new Rx.Subject();
    Rx.zip(groupSubject, commentSubject)
        .subscribe(() => {
            console.log("Change complete");
            changeSubject.next();
        });

    return changeSubject;
}

class Comment {

    constructor() {
        this.snapshot = null;
        this.username = null;
        this.time_created = null;
        this.msg = null;
        this.type = null;
    }

    static createFromSnapshot(snapshot) {
        const data = snapshot.data();

        var newComment = new Comment();
        newComment.snapshot = snapshot;
        newComment.username = data.username;
        newComment.time_created = data.time_created.toDate();
        newComment.type = data.type != null ? data.type : CommentType.User;

        newComment.msg = data.msg;
        newComment.data = data.data;

        return newComment;
    }

}

const listDeviceCommentStream = (db, ltid) => {
    console.log("loraDevice.listDeviceComment", ltid);
    const subject = new Rx.Subject();

    db.collection(`inovibe-lora-device/${ltid}/comment`)
        .orderBy("time_created", "desc")
        .onSnapshot(docs => {
            console.log("loraDevice.listDeviceComment: ok", docs);
            var comments = [];
            docs.forEach(d => {
                comments.push(Comment.createFromSnapshot(d));
            });
            subject.next(comments);
        });

    return subject;
}

const insertDeviceComment = (db, ltid, type, msg) => {
    const username = localStorage.getItem('username');
    console.log("loraDevice.insertDeviceComment", ltid, msg, username);

    const subject = new Rx.Subject();

    db.collection(`inovibe-lora-device/${ltid}/comment`)
        .add({
            "username": username,
            "type": type,
            "msg": msg,
            "time_created": new Date(),
        })
        .then(doc => {
            console.log("loraDevice.insertDeviceComment: ok");
            doc.get()
                .then(snapshot => {
                    subject.next(Comment.createFromSnapshot(snapshot));
                })
                .catch(e => {
                    console.log("Get failed " + e)
                });
        })
        .catch(err => {
            console.log("loraDevice.insertDeviceComment: failed", err);
        });

    return subject;
}

const insertDeviceGroupChangeComment = (db, ltid, group) => {
    const username = localStorage.getItem('username');
    console.log("loraDevice.insertDeviceComment", ltid, username, group);

    const subject = new Rx.Subject();

    db.collection(`inovibe-lora-device/${ltid}/comment`)
        .add({
            "username": username,
            "type": CommentType.GroupChange,
            "data": {
                "id": group.id,
                "name": group.name,
            },
            "time_created": new Date(),
        })
        .then(doc => {
            console.log("loraDevice.insertDeviceComment: ok");
            doc.get()
                .then(snapshot => {
                    subject.next(Comment.createFromSnapshot(snapshot));
                })
                .catch(e => {
                    console.log("Get failed " + e)
                });
        })
        .catch(err => {
            console.log("loraDevice.insertDeviceComment: failed", err);
        });

    return subject;
}

const deleteDeviceComment = (db, comment) => {
    console.log("loraDevice.deleteDeviceComment", comment.snapshot.ref);
    const subject = new Rx.Subject();

    comment.snapshot.ref
        .delete()
        .then(() => {
            console.log("loraDevice.deleteDeviceComment: ok");
            subject.next(comment);
        })
        .catch(err => {
            console.log("loraDevice.deleteDeviceComment: failed", err);
        })

    return subject;
}

export {
    CommentType,
    listLoRaDevice,
    findLoRaDevice,
    changeDeviceBuyer,
    listDeviceCommentStream,
    insertDeviceComment,
    deleteDeviceComment,
}