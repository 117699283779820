import React from "react";
import { logEvent } from "../event";

import { createStore } from "redux";
import { Chart } from "react-google-charts";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";

import CloseButton from "../components/CloseButton";
import WaveChart from "../components/WaveChart";
import { listAlarmsByGroup, AlertType } from "../InoVibe/alarm";
import { detailDevice } from "../InoVibe/device";
import { getProfile } from "../InoVibe/user";
import { weekNumber, weekRange } from "../utils/dateutils";
import { formatDateString } from "../utils/dateutils";

import icon_calendar from "../images/icon-calendar.svg";
import circle_spinner from '../images/circle-spinner.svg';
import ko from 'date-fns/locale/ko';

import "../css/report.css";
import "react-datepicker/dist/react-datepicker.css";

const EVT_SELECT_REPORT_WAVE = "SELECT_REPORT_WAVE";
const EVT_CLOSE_REPORT_WAVE = "CLOSE_REPORT_WAVE";

const eventHandler = (state, action) => {
    switch(action.type) {
        case EVT_SELECT_REPORT_WAVE:
            return Object.assign({}, state, {type: action.type, waveID: action.waveID});
        case EVT_CLOSE_REPORT_WAVE:
            return Object.assign({}, state, {type: action.type});
        default:
            return state;
    }
}

const eventStore = createStore(eventHandler);

registerLocale("ko", ko);

class Report extends React.Component {

    constructor(props) {
        super(props);

        logEvent("Page-Report");

        this.state = {
            alerts: [], user: null,
        };

        getProfile(user => {
            console.log(user);
            this.setState({ user: user });
        }, err => {
            console.log(err);
        });
    }

    // Report type component shoud be seperated.
    render() {
        return (
            <div className="report_container">
                <div className="report__header">
                    <div className="report__title"><p>이노바이브 보고서</p></div>
                    <div className="report__type">
                        <div><p>보고서 유형</p></div>
                        <div>
                            <select name="report__type__options">
                                <option value="alert">알림 내역 데이터</option>
                            </select>
                        </div>
                    </div>
                    <div className="report__spacer"></div>
                    <div className="report__group">
                        <div><p>내 그룹</p></div>
                        <div><p>{this.state.user == null ? "--" : this.state.user.groupName}</p></div>
                    </div>
                </div>
                <AlertReport></AlertReport>
            </div>
        );
    }

}

class AlertReport extends React.Component {

    ALERT_WINDOW_SIZE = 100;

    filterAlertType = Object.freeze({
        All: "all",
        NoSignal: "no_signal",
        Inclination: "inclination",
        Excavation: "excavation",
    });

    alignAlertType = Object.freeze({
        Daily: "daily",
        Weekly: "weekly",
        Monthly: "monthly",
    });

    constructor(props) {
        super(props);

        this.state = {
            query: {
                alertType: this.filterAlertType.All,
                dateStart: null,
                dateEnd: null,
            },
            alerts: [],
            alertsWindow: [],
            windowOffset: 0,
            statistic: {
                daily: {},
            },
            chartData: {
                alertTypePieChart: [],
                activityBarChart: [],
                trendLineChart: [],
            },
            statActiveDevice: [["ID", "공사감지", "기울기", "신호없음"]],
            showLoading: false,
            waveModal: {
                isOpen: false,
                selectWaveID: null,
            },
            trendChartAlign: this.alignAlertType.Daily,
            deviceCache: {}
        };
    }

    createAlertTypePieChartData(alerts) {
        var statByType = {
            noSignal: 0,
            inclination: 0,
            excavation: 0,
        };

        alerts.forEach(alert => {
            if(alert.type === AlertType.NoSignal) {
                statByType.noSignal++;
            } else if(alert.type === AlertType.Inclination) {
                statByType.inclination++;
            } else if(alert.type === AlertType.Excavation) {
                statByType.excavation++;
            }
        });

        return [
            ["Type", "Count"],
            ["공사감지", statByType.excavation],
            ["기울기", statByType.inclination],
            ["신호없음", statByType.noSignal],
        ];
    }

    createActivityBarChartData(alerts) {
        var statByDevices = {};

        alerts.forEach(alert => {
            var statByThisDevice;
            if(!(alert.devid in statByDevices)) {
                statByThisDevice = {
                    noSignal: 0,
                    inclination: 0,
                    excavation: 0,
                    total: 0,
                };
                statByDevices[alert.devid] = statByThisDevice;
            } else {
                statByThisDevice = statByDevices[alert.devid];
            }

            if(alert.type === AlertType.NoSignal) {
                statByThisDevice.noSignal++;
            } else if(alert.type === AlertType.Inclination) {
                statByThisDevice.inclination++;
            } else if(alert.type === AlertType.Excavation) {
                statByThisDevice.excavation++;
            }
            statByThisDevice.total++;
        });

        var deviceTotals = [];
        Object.keys(statByDevices).forEach(devid => {
            deviceTotals.push({devid: devid, total: statByDevices[devid].total});
        });

        deviceTotals.sort((a, b) => {
            return b.total - a.total;
        });

        var newStatActiveDevice = [[
            "ID","공사감지", { role: "annotation" },
            "기울기", { role: "annotation" },
            "신호없음", { role: "annotation" }]];

        if(deviceTotals.length > 0) {
            let devid = deviceTotals[0].devid;
            let stat = statByDevices[devid];

            let annotationExcavation = stat.excavation === 0 ? null : stat.excavation;
            let annotationInclination = stat.inclination === 0 ? null : stat.inclination;
            let annotationNoSignal = stat.noSignal === 0 ? null : stat.noSignal;

            newStatActiveDevice.push(["1. " + devid.substring(devid.length - 6).toUpperCase(),
                stat.excavation, annotationExcavation,
                stat.inclination, annotationInclination,
                stat.noSignal, annotationNoSignal]);
        }

        if(deviceTotals.length > 1) {
            let devid = deviceTotals[1].devid;
            let stat = statByDevices[devid];

            let annotationExcavation = stat.excavation === 0 ? null : stat.excavation;
            let annotationInclination = stat.inclination === 0 ? null : stat.inclination;
            let annotationNoSignal = stat.noSignal === 0 ? null : stat.noSignal;

            newStatActiveDevice.push(["2. " + devid.substring(devid.length - 6).toUpperCase(),
                stat.excavation, annotationExcavation,
                stat.inclination, annotationInclination,
                stat.noSignal, annotationNoSignal]);
        }

        if(deviceTotals.length > 2) {
            let devid = deviceTotals[2].devid;
            let stat = statByDevices[devid];

            let annotationExcavation = stat.excavation === 0 ? null : stat.excavation;
            let annotationInclination = stat.inclination === 0 ? null : stat.inclination;
            let annotationNoSignal = stat.noSignal === 0 ? null : stat.noSignal;

            newStatActiveDevice.push(["3. " + devid.substring(devid.length - 6).toUpperCase(),
                stat.excavation, annotationExcavation,
                stat.inclination, annotationInclination,
                stat.noSignal, annotationNoSignal]);
        }

        return newStatActiveDevice;
    }

    categorizeAlert(alert, alignType) {
        const timeOptions = {
            hour12: false,
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
        }

        const { dateStart, dateEnd } = this.state.query;
        var rangeBegin, rangeEnd;

        if(alignType === this.alignAlertType.Daily) {
            const issueDate = new Date(alert.issued.getFullYear(), alert.issued.getMonth(), alert.issued.getDate());
            return issueDate.toLocaleDateString("ko-KR", timeOptions);
        } else if(alignType === this.alignAlertType.Weekly) {
            var weekNo = weekNumber(alert.issued);
            var range = weekRange(alert.issued.getFullYear(), weekNo);
            rangeBegin = range[0];
            rangeEnd = range[1];

            if(rangeBegin < dateStart) {
                rangeBegin = dateStart;
            }

            if(rangeEnd > dateEnd) {
                rangeEnd = dateEnd;
            }

            return rangeBegin.toLocaleString("ko-KR", timeOptions) + " - " + rangeEnd.toLocaleString("ko-KR", timeOptions);
        } else {
            const issuedDate = alert.issued;
            rangeBegin = new Date(issuedDate.getFullYear(), issuedDate.getMonth(), 1);
            rangeEnd = new Date(issuedDate.getFullYear(), issuedDate.getMonth() + 1, 1);
            rangeEnd = new Date(rangeEnd.getTime() - 86400000);

            if(rangeBegin < dateStart) {
                rangeBegin = dateStart;
            }

            if(rangeEnd > dateEnd) {
                rangeEnd = dateEnd;
            }

            return rangeBegin.toLocaleString("ko-KR", timeOptions) + " - " + rangeEnd.toLocaleString("ko-KR", timeOptions);
        }
    }

    createTrendLineChartData(alerts, alignType) {
        alerts.sort((a, b) => {
            return a.issued.getTime() - b.issued.getTime();
        });

        var trendStats = {};
        alerts.forEach(alert => {
            const statEntry = this.categorizeAlert(alert, alignType);

            var stat = null;
            if(!(statEntry in trendStats)) {
                stat = {
                    noSignal: 0,
                    inclination: 0,
                    excavation: 0,
                }
                trendStats[statEntry] = stat;
            } else {
                stat = trendStats[statEntry];
            }

            if(alert.type === AlertType.NoSignal) {
                stat.noSignal++;
            } else if(alert.type === AlertType.Inclination) {
                stat.inclination++;
            } else if (alert.type === AlertType.Excavation) {
                stat.excavation++;
            }

        });

        var lineChartData = [["Category", "공사감지", "기울기", "신호없음"]];
        Object.keys(trendStats).forEach(entry => {
            const curStat = trendStats[entry];
            lineChartData.push([entry, curStat.excavation, curStat.inclination, curStat.noSignal]);
        });

        return lineChartData;
    }

    componentDidMount() {
        this.setDateRangeBefore(7);
        this.queryReport();

        this.eventStoreSubscribe = eventStore.subscribe(() => {
            const type = eventStore.getState()["type"];

            switch(type) {
                case EVT_SELECT_REPORT_WAVE:
                    const waveID = eventStore.getState()["waveID"];
                    console.log("Select wave ", waveID);
                    this.setState({
                        waveModal: {
                            isOpen: true,
                            selectWaveID: waveID,
                        }
                    });

                    break;
                case EVT_CLOSE_REPORT_WAVE:
                    this.setState({
                                waveModal: {
                                    isOpen: false,
                                    selectWaveID: null,
                                }
                    });

                    break;
                default:
                    return;
            }

        });
    }

    componentWillUnmount() {
        if(this.eventStoreSubscribe != null) {
            this.eventStoreSubscribe()
        }
    }
    
    render() {
        const { alerts, alertsWindow, windowOffset } = this.state;
        const { dateStart, dateEnd } = this.state.query;
        const { showLoading } = this.state;

        return (
            <div className="alert_report__container">
                {
                   showLoading === true ? (
                       <LoadingReport/>
                   ) : (null)
                }

                <div className="report__filter">
                    <div className="report__filter__alert_type">
                        <div><p>알림 항목</p></div>
                        <div>
                            <select name="alert_type" onChange={this.alertTypeChanged.bind(this)}>
                                <option className="option_test" value={this.filterAlertType.All}>전체</option>
                                <option value={this.filterAlertType.Excavation}>공사감지</option>
                                <option value={this.filterAlertType.Inclination}>기울기감지</option>
                                <option value={this.filterAlertType.NoSignal}>신호없음</option>
                            </select>
                        </div>
                    </div>
                    <div className="report__filter__date_range">
                        <div><p>알림 기간 조회</p></div>
                        <div>
                            <DatePicker
                                locale={"ko"}
                                dateFormat={"yyyy. MM. dd"}
                                selected={dateStart}
                                onChange={this.dateStartChanged.bind(this)}
                                customInput={<DateSelector />}/>

                            &nbsp;&nbsp;&nbsp;&nbsp; - &nbsp;&nbsp;&nbsp;&nbsp;

                            <DatePicker
                                locale={"ko"}
                                dateFormat={"yyyy. MM. dd"}
                                selected={dateEnd}
                                onChange={this.dateEndChanged.bind(this)}
                                customInput={<DateSelector />}/>
                        </div>
                    </div>
                    <div className="report__filter__date_preset">
                        <input type="radio" id="yesterday" name="range_preset"/>
                        <label htmlFor="yesterday" onClick={this.setDateRangeBefore.bind(this, 1)}>어제</label>

                        <input type="radio" id="today" name="range_preset"/>
                        <label htmlFor="today" onClick={this.setDateRangeBefore.bind(this, 0)}>오늘</label>

                        <input type="radio" id="week" name="range_preset"/>
                        <label htmlFor="week" onClick={this.setDateRangeBefore.bind(this, 7)}>1주일</label>

                        <input type="radio" id="15days" name="range_preset"/>
                        <label htmlFor="15days" onClick={this.setDateRangeBefore.bind(this, 15)}>15일</label>

                        <input type="radio" id="month" name="range_preset"/>
                        <label htmlFor="month" onClick={this.setDateRangeBefore.bind(this, 30)}>1개월</label>

                        <input type="radio" id="3months" name="range_preset"/>
                        <label htmlFor="3months" onClick={this.setDateRangeBefore.bind(this, 90)}>3개월</label>
                    </div>
                    <div className="report__filter__apply">
                        <button onClick={this.queryReport.bind(this)}>보고서 조회하기</button>
                    </div>
                </div>
                <div className="report__data">
                    <div className="report__chart">
                        <div className="report__chart__summary">
                            <div className="report__chart__summary__pie">
                                <p>총 알림 건수 {this.state.alerts.length}</p>
                                <div>
                                    {
                                        this.state.alerts.length === 0 ? (
                                            <div><p>알림이 없습니다</p></div>
                                        ) : (
                                            <Chart
                                                chartType="PieChart"
                                                loader={<div>Loading...</div>}
                                                width={"100%"}
                                                height={"100%"}
                                                data={this.state.chartData.alertTypePieChart}
                                                options={{
                                                    backgroundColor: "transparent",
                                                    colors: ["#625CFF", "#3Cb2ED", "#C9C9C9"],
                                                    legend: {
                                                        position: "right",
                                                        alignment: "end",
                                                        textStyle: {
                                                            color: "#BDC4CC",
                                                            fontSize: 13
                                                        },
                                                    },
                                                    pieSliceTextStyle: {
                                                        color: "black",
                                                        fontSize: 13
                                                    }
                                                }}/>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="report__chart__summary__bar">
                                <p>주요 디바이스 : 알림 빈도 순</p>
                                <div>
                                    {
                                        this.state.alerts.length === 0 ? (
                                            <div><p>알림이 없습니다</p></div>
                                        ) : (
                                            <Chart
                                                chartType="BarChart"
                                                loader={<div>Loading...</div>}
                                                width={"100%"}
                                                height={"100%"}
                                                data={this.state.chartData.activityBarChart}
                                                options={{
                                                    legend: { position: "none" },
                                                    isStacked: true,
                                                    backgroundColor: "transparent",
                                                    colors: ["#625CFF", "#3Cb2ED", "#C9C9C9"],
                                                    vAxis: {
                                                        textStyle: { color: "white" }
                                                    },
                                                    hAxis: { 
                                                        gridlines: { color: "transparent", count: 0},
                                                        textPosition: "none",
                                                    },
                                                    bar: {
                                                        groupWidth: 30
                                                    }
                                                }}/>
                                        )
                                    }
                                    </div>
                            </div>
                        </div>
                        <div className="report__chart__trend">
                            <p>알림 요약</p>
                            <div className="report__chart__trend__align_container">
                                <div className="report__chart__trend__align">
                                    <input type="radio" id="align_day" name="align" defaultChecked/>
                                    <label htmlFor="align_day" onClick={this.alignTypeChanged.bind(this, this.alignAlertType.Daily)}>일</label>

                                    <input type="radio" id="align_week" name="align"/>
                                    <label htmlFor="align_week" onClick={this.alignTypeChanged.bind(this, this.alignAlertType.Weekly)}>주</label>

                                    <input type="radio" id="align_month" name="align"/>
                                    <label htmlFor="align_month" onClick={this.alignTypeChanged.bind(this, this.alignAlertType.Monthly)}>월</label>
                                </div>
                            </div>
                            <div>
                                {
                                    this.state.alerts.length === 0 ? (
                                        <div><p>알림이 없습니다</p></div>
                                    ) : (
                                        <Chart
                                            chartType="LineChart"
                                            loader={<div>Loading...</div>}
                                            width={"100%"}
                                            height={"100%"}
                                            data={this.state.chartData.trendLineChart}
                                            options={{
                                                backgroundColor: "transparent",
                                                legend: { position: "none" },
                                                colors: ["#625CFF", "#3Cb2ED", "#C9C9C9"],
                                                hAxis: { 
                                                    textStyle: { color: "white" },
                                                    gridlines: { color: "transparent"},
                                                },
                                                vAxis: {
                                                    textStyle: { color: "white" },
                                                    gridlines: { color: "#757F8E" },
                                                    minorGridlines: { color: "#757F8E" },
                                                    baselineColor: "white",
                                                },
                                                pointSize: 5,
                                                chartArea: {
                                                    top: 20,
                                                    bottom: 50,
                                                    left: 50,
                                                    right: 50
                                                }
                                            }}/>
                                    )
                                }
                            </div>
                        </div>
                    </div>

                    <div className="report__list">
                        <div className="report__list__summary"><p>조회 항목 리스트 (전체 알림 {alerts.length})</p></div>
                        <div className="report__list__header">
                            <div className="report__list__no"><p>No.</p></div>
                            <div className="report__list__id"><p>ID</p></div>
                            <div className="report__list__alias"><p>별명</p></div>
                            <div className="report__list__type"><p>알림 항목</p></div>
                            <div className="report__list__time"><p>알림 시각</p></div>
                            <div className="report__list__group"><p>그룹</p></div>
                            <div className="report__list__installer"><p>설치자</p></div>
                            <div className="report__list__install_date"><p>설치 날짜</p></div>
                            <div className="report__list__wave"><p>파형 보기</p></div>
                        </div>
                        <div className="report__list__items" onScroll={(e) => this.onScroll(e)}>
                        {
                            this.state.alerts.length === 0 ? (
                                <div className="report__list__items--empty"><p>알림이 없습니다</p></div>
                            ) : (
                                alertsWindow.map((entity, i) => {
                                    return (
                                        <AlertItem key={i} index={i + windowOffset} alert={entity}
                                            device={this.state.deviceCache[entity.devid]}/>
                                    );
                                })
                            )
                        }
                        </div>
                    </div>
                </div>

                {/* Modal Window for display wave chart. */}
                <WaveChartModal isOpen={this.state.waveModal.isOpen} waveID={this.state.waveModal.selectWaveID}/>
            </div>
        );
    }

    onScroll(e) {
        const heightPerItem = (e.target.scrollHeight / this.state.alertsWindow.length);
        const windowShiftSize = this.ALERT_WINDOW_SIZE / 2;
        const prevWindowOffset = this.state.windowOffset;

        if((e.target.scrollTop + e.target.clientHeight) === e.target.scrollHeight) {
            // Reach to bottom.
            if((prevWindowOffset + this.ALERT_WINDOW_SIZE) < this.state.alerts.length) {
                const newWindowOffset = prevWindowOffset + windowShiftSize
                const newWindowItems = this.state.alerts.slice(newWindowOffset, newWindowOffset + this.ALERT_WINDOW_SIZE);

                e.target.scrollTop -= (windowShiftSize * heightPerItem);

                console.log("Load Next.", newWindowOffset, newWindowOffset + this.ALERT_WINDOW_SIZE, newWindowItems.length, e.target.scrollTop);

                this.setState({
                    windowOffset: newWindowOffset,
                    alertsWindow: newWindowItems,
                });
            }
        } else if(e.target.scrollTop === 0) {
            // Reach to top.
            var newWindowOffset = prevWindowOffset - windowShiftSize;
            if(newWindowOffset < 0) {
                newWindowOffset = 0;
            }

            if(prevWindowOffset !== 0) {
                console.log("Load Prev.", e.target.scrollHeight, e.target.scrollTop, e.target.clientHeight);
                const newWindowItems = this.state.alerts.slice(newWindowOffset, newWindowOffset + this.ALERT_WINDOW_SIZE);
                e.target.scrollTop += (windowShiftSize * heightPerItem);

                this.setState({
                    windowOffset: newWindowOffset,
                    alertsWindow: newWindowItems,
                });
            }
        }
    }

    alertTypeChanged(event) {
        console.log("Report.alertTypeChanged ", event.target.value);
        var queryOptions = this.state.query;
        queryOptions.alertType = event.target.value;

        this.setState({query: queryOptions});
    }

    alignTypeChanged(alignType) {
        console.log("Report.alignTypeChanged ", alignType);

        var chartData = this.state.chartData;

        const trendLineChartData = this.createTrendLineChartData(this.state.alerts, alignType);
        chartData.trendLineChart = trendLineChartData;

        console.log("ChartData ", trendLineChartData);

        this.setState({ trendChartAlign: alignType, chartData: chartData});
    }

    dateStartChanged(newDate) {
        console.log("dateStartChanged ", newDate);
        newDate.setHours(0);
        newDate.setMinutes(0);
        newDate.setSeconds(0);
        newDate.setMilliseconds(0);

        var queryOptions = this.state.query;
        queryOptions.dateStart = newDate;
        this.setState({query: queryOptions});
    }

    dateEndChanged(newDate) {
        console.log("dateEndChanged ", newDate);
        var selectEndDate = newDate;
        if(newDate < this.state.query.dateStart) {
            console.log("Wrong End date");
            selectEndDate = this.state.query.dateStart;
        }

        selectEndDate.setHours(23);
        selectEndDate.setMinutes(59);
        selectEndDate.setSeconds(59);
        selectEndDate.setMilliseconds(999);

        var queryOptions = this.state.query;
        queryOptions.dateEnd = selectEndDate;
        this.setState({query: queryOptions});
    }

    setDateRangeBefore(days) {
        console.log("Report:setDateRangeBefore " + days);
        const current = new Date();
        var endDate = current;
        endDate.setHours(23);
        endDate.setMinutes(59);
        endDate.setSeconds(59);
        endDate.setMilliseconds(999);

        var startDate = new Date(endDate.getTime() - days * 3600 * 24 * 1000);
        startDate.setHours(0);
        startDate.setMinutes(0);
        startDate.setSeconds(0);
        startDate.setMilliseconds(0);

        var queryOptions = this.state.query;
        queryOptions.dateStart = startDate;
        queryOptions.dateEnd = endDate;
        this.setState({query: queryOptions});
    }

    queryReport() {
        console.log("Report:queryReport")
        var queryOptions = this.state.query;
        const { dateStart, dateEnd } = queryOptions;

        this.setState({showLoading: true, windowOffset: 0});
        
        var presets = document.getElementsByName("range_preset");
        for(var i = 0; i < presets.length; i++) {
            presets[i].checked = false;
        }

        listAlarmsByGroup(
            Math.round(dateStart.getTime() / 1000),
            Math.round(dateEnd.getTime() / 1000),
            alerts => {
                console.log("Receive Alerts");

                var filteredAlerts = [];
                const filterType = this.state.query.alertType;
                if(filterType === this.filterAlertType.All) {
                    filteredAlerts = alerts;
                } else {
                    filteredAlerts = alerts.filter(alert => {
                        if(alert.type === filterType) {
                            return true;
                        } else {
                            return false;
                        }
                    });
                }

                const alertTypePieChartData = this.createAlertTypePieChartData(filteredAlerts);
                const activityBarChartData = this.createActivityBarChartData(filteredAlerts);
                const trendLineChartData = this.createTrendLineChartData(filteredAlerts, this.state.trendChartAlign);

                this.setState({
                    alerts: filteredAlerts,
                    alertsWindow: filteredAlerts.slice(0, this.ALERT_WINDOW_SIZE),
                    chartData: {
                        alertTypePieChart: alertTypePieChartData,
                        activityBarChart: activityBarChartData,
                        trendLineChart: trendLineChartData,
                    },
                    showLoading: false,
                });

                var devIDs = {};
                alerts.forEach(alert => {
                    devIDs[alert.devid] = null;
                });

                Object.keys(devIDs).forEach(devid => {
                    detailDevice(devid,
                        data => {
                            var deviceCache = this.state.deviceCache;
                            deviceCache[devid] = data;
                            this.setState({ deviceCache: deviceCache });
                        },
                        err => {
                            console.log(err);
                        });
                });
            },
            err => {
                console.log("listAlarmsByGroup Error ", err);
                this.setState({showLoading: false});
            });
    }

}

class AlertItem extends React.Component {

    showWave(waveID) {
        console.log("AlertItem.showWave ", waveID)
        eventStore.dispatch({ type: EVT_SELECT_REPORT_WAVE, waveID: waveID });
    }

    getAlertTypeName(alertType) {
        switch(alertType) {
            case AlertType.Excavation:
                return "공사감지";
            case AlertType.Inclination:
                return "기울기감지";
            case AlertType.NoSignal:
                return "신호없음";
            default:
                return "--";
        }
    }

    render() {
        const { alert, device } = this.props;
        return (
            <div key={this.props.index} className="report__alert_item">
                <div className="report__list__no"><p>{this.props.index + 1}</p></div>
                <div className="report__list__id"><p>{alert.devid.substring(alert.devid.length - 6).toUpperCase()}</p></div>
                <div className="report__list__alias"><p>{device == null ? "--" : device.alias}</p></div>
                <div className="report__list__type"><p>{this.getAlertTypeName(alert.type)}</p></div>
                <div className="report__list__time"><p>{formatDateString(alert.issued)}</p></div>
                <div className="report__list__group"><p>{alert.groupName === null ? "--" : alert.groupName}</p></div>
                <div className="report__list__installer"><p>{device == null ? "--" : device.installer}</p></div>
                <div className="report__list__install_date">
                    <p>{device == null ? "--" : formatDateString(new Date(device.install_date))}</p>
                </div>
                {
                    alert.type === AlertType.Excavation ? (
                        <div className="report__list__wave"><button onClick={this.showWave.bind(this, alert.waveID)}>파형보기</button></div>
                    ) : (
                        <div className="report__list__wave"><p>--</p></div>
                    )
                }
            </div>
        );
    }

}

class DateSelector extends React.Component {
    render() {
        const {value, onClick} = this.props;
        return (
            <div className="dateselector_container">
                <p className="dateselector__date">{value}</p>
                <img alt={"icon-calender"} className="dateselector__icon" src={icon_calendar} onClick={onClick}/>
            </div>
        );
    }

}

class LoadingReport extends React.Component {
    render() {
        return (
            <div className="loading_container">
                <img alt="loading" src={circle_spinner}/>
            </div>
        );
    }
}

class WaveChartModal extends React.Component {

    componentDidMount(props) {
        console.log("WaveChartModal:componentDidMount");
    }

    UNSAFE_componentWillReceiveProps(props) {
        console.log("WaveChartModal:componentWillReceiveProps");
    }

    onClose() {
        console.log("WaveChartModal.close");
        eventStore.dispatch({ type: EVT_CLOSE_REPORT_WAVE });
    }

    render() {
        const { isOpen, waveID } = this.props;

        if(isOpen) {
            return (<div className="wave_chart_modal_container">
                <div className="wave_chart_modal__chart_area">
                    <div className="wave_chart_modal__close">
                        <CloseButton onClick={this.onClose.bind(this)} options={{ backgroundColor: "white" }}/>
                    </div>
                    <div className="wave_chart_modal__chart_area__chart">
                        <WaveChart waveid={waveID} showTitle={true}/>
                    </div>
                </div>
            </div>);
        } else {
            return null;
        }
    }

}

export default Report;