import React from "react";

import "../css/CloseButton.css";

const CloseButton = ({ options, onClick }) => {
    var buttonStyle = {
        backgroundColor: "black"
    }

    if (options != null && options.backgroundColor != null) {
        buttonStyle.backgroundColor = options.backgroundColor;
    }

    return (
        <div className="close_button_container" onClick={() => {
            if (onClick != null) {
                console.log("CloseButton.onClick()");
                onClick();
            }
        }}>
            <div style={buttonStyle}></div>
            <div style={buttonStyle}></div>
        </div>
    );
}

export default CloseButton;