import React, { useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { createStore } from 'redux';
import { getProfile } from "./InoVibe/user";

import history from './history';
import jwt_decode from 'jwt-decode';
import CircleLoading from './components/CircleLoading';
import Main from './pages/Main';
import Unauthorize from './Unauthorize.js';

import './css/App.css';

export const EVT_SELECT_MARKER = "SELECT_MARKER"; // Deprecated.
export const EVT_SELECT_DEVICE = "SELECT_DEVICE";
export const EVT_DEVICE_SEARCH = "DEVICE_SEARCH";
export const EVT_DEVICE_DETAIL_CLOSE = "DEVICE_DETAIL_CLOSE";
export const EVT_HIDE_INBOX_MODAL = "HIDE_INBOX_MODAL";
export const EVT_NEW_INBOX_MSG = "NEW_INBOX_MSG";
export const EVT_READ_ALL_INBOX_MSG = "READ_ALL_INBOX_MSG";
export const EVT_MODIFY_DEVICE = "MODIFY_DEVICE";

export const EVT_UNAUTHORIZED = "USER_UNAUTHORIZED";

const reduxHandler = (state = { page: 0 }, action) => {
    switch (action.type) {
        case EVT_SELECT_MARKER:
            return Object.assign({}, state, { devid: action.devid, type: EVT_SELECT_MARKER });
        case EVT_SELECT_DEVICE:
            return Object.assign({}, state, { devid: action.devid, type: EVT_SELECT_DEVICE });
        case EVT_DEVICE_SEARCH:
            return Object.assign({}, state, { devid: action.devid, type: EVT_DEVICE_SEARCH });
        case EVT_DEVICE_DETAIL_CLOSE:
            return Object.assign({}, state, { type: EVT_DEVICE_DETAIL_CLOSE });
        case EVT_HIDE_INBOX_MODAL:
            return Object.assign({}, state, { type: EVT_HIDE_INBOX_MODAL });
        case EVT_NEW_INBOX_MSG:
            return Object.assign({}, state, { type: EVT_NEW_INBOX_MSG });
        case EVT_READ_ALL_INBOX_MSG:
            return Object.assign({}, state, { type: EVT_READ_ALL_INBOX_MSG });
        case EVT_MODIFY_DEVICE:
            return Object.assign({}, state, { type: EVT_MODIFY_DEVICE });
        case EVT_UNAUTHORIZED:
            auth.logout(); // TODO:
            return state;
        default:
            return state;
    }
}

export const eventStore = createStore(reduxHandler);

const App = () => {
    const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    const [isProfileLoaded, setProfileLoaded] = useState(false);
    const [isLoadingProfile, setLoadingProfile] = useState(false);

    useEffect(() => {
        console.log("App", isAuthenticated, isLoading);
        if (isAuthenticated) {
            // Load credentials.
            const issueToken = async () => {
                const accessToken = await getAccessTokenSilently();
                localStorage.setItem('access_token', accessToken);

                const authorization = jwt_decode(accessToken)['https://ino-vibe.ino-on.com/app_metadata']['authorization'];
                localStorage.setItem('authorization', JSON.stringify(authorization));

                setLoadingProfile(true);
                getProfile(profile => {
                    console.log("Profile", profile);
                    localStorage.setItem("username", profile.username);
                    setProfileLoaded(true);
                    setLoadingProfile(false);
                }, err => {
                    console.log("Error", err);
                });

                history.replace("/map");
            };

            issueToken();
        }
    }, [isAuthenticated, isLoading]);

    var view = null;
    if (isLoading || isLoadingProfile) {
        view = (
            <div style={{
                display: "flex",
                height: "100%",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <CircleLoading color={"black"} hintColor={"white"} strokeWidth={"5px"} size={"50px"}></CircleLoading>
            </div>
        );
    } else {
        view = (isAuthenticated && isProfileLoaded) ? <Main></Main> : <Unauthorize></Unauthorize>;
    }

    return (
        <div className='app_container'>
            <div className='app_container__contents'>
                {view}
            </div>
        </div>
    );
}

export default App;