import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { eventStore, EVT_DEVICE_SEARCH } from '../App';

import search_icon from '../images/search-icon.svg';
import '../css/Search.css';

const Search = ({ devices }) => {
    const [showResult, setShowResult] = useState(false);
    const [searchResult, setSearchResult] = useState(null);

    const onSearchKeywordChanged = (e) => {
        if (e.target.value.length >= 2) {
            const key = e.target.value.toLowerCase();
            console.log(key);

            var devs = Object.values(devices);

            var results = devs.filter(dev => {
                const devidSuffix = dev.devid.substring(dev.devid.length - 6).toLowerCase();
                const alias = dev.alias.toLowerCase();

                if (devidSuffix.startsWith(key) || (alias.search(key) >= 0)) {
                    return true;
                } else {
                    return false;
                }
            });

            setShowResult(true);
            setSearchResult(results);
        } else {
            setShowResult(false);
            setSearchResult(null);
        }
    }

    const onKeydown = (e) => {
        if (e.keyCode === 13 && searchResult.length === 1) {
            const devid = searchResult[0].devid;
            console.log("Input Enter ", devid);
            eventStore.dispatch({ type: EVT_DEVICE_SEARCH, devid: devid });
        } else if (e.keyCode === 27) {
            console.log("ESC Clear");
            onClickClear(e);
        }
    }

    const onClickClear = (e) => {
        console.log("Search: Clear");
        document.getElementById("search_input").value = "";
        setShowResult(false);
        setSearchResult(null);
    }

    const { t } = useTranslation();

    return (
        <div className={"search_container"}>
            <div className={"search__input"}>
                <img alt={"search"} src={search_icon}></img>
                <input
                    id={"search_input"}
                    type="text"
                    placeholder={t("search-placeholder", "Device ID or Alias.")}
                    onChange={(e) => onSearchKeywordChanged(e)}
                    onKeyDown={(e) => onKeydown(e)}
                />
                <div className={"search__input__clear"} onClick={(e) => onClickClear(e)}></div>
            </div>
            {
                showResult ? (
                    <div className={"search__result"}>
                        {
                            searchResult.map((device) => {
                                return (
                                    <SearchResultItem
                                        key={device.devid}
                                        devid={device.devid}
                                        alias={device.alias} />
                                );
                            })
                        }
                    </div>
                ) : (
                    null
                )
            }
        </div>
    );
}

const SearchResultItem = ({ devid, alias }) => {
    const searchResultClicked = () => {
        console.log("SearchResultItem: Clicked..", devid);
        eventStore.dispatch({ type: EVT_DEVICE_SEARCH, devid: devid });
    }

    return (
        <div className={"search__result__item"} onClick={(e) => { searchResultClicked(e) }}>
            <div></div>
            <div>{devid.substring(devid.length - 6).toUpperCase()}</div>
            <div><p>{alias}</p></div>
        </div>
    );
}

export default Search;