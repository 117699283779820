import React, { useEffect, useState } from "react";
import { Chart } from 'react-google-charts';

import { waveDetail } from '../InoVibe/wave';

import "../css/WaveChart.css";


const WaveChart = ({ waveid, showTitle }) => {

    const [wave, setWave] = useState({});

    useEffect(() => {
        waveDetail(waveid, (resp) => {
            console.log(resp);

            var wave = resp.data;

            var xs = [];
            var ys = [];
            var zs = [];

            var rows = []
            for (var i = 0; i < wave.occupy.length; i++) {
                var t = (wave.interval / 1000.0) * i;
                var x = null;
                var y = null;
                var z = null;

                if (wave.x.length > i) {
                    x = wave.x[i] * wave.resolution;
                    xs.push(x);
                }

                if (wave.y.length > i) {
                    y = wave.y[i] * wave.resolution;
                    ys.push(y);
                }

                if (wave.z.length > i) {
                    z = Number.parseFloat(wave.z[i] * wave.resolution);
                    zs.push(z);
                }
                rows.push([t, x, y, z]);
            }

            var minX, minY, minZ = null;
            var maxX, maxY, maxZ = null;
            var avgX, avgY, avgZ = null;
            var stdX, stdY, stdZ = null;
            var rmsX, rmsY, rmsZ = null;
            if (xs.length > 0) {
                minX = Math.min(...xs);
                maxX = Math.max(...xs);
                avgX = xs.reduce((a, b) => a + b) / xs.length;
                stdX = Math.sqrt(xs.reduce((a, b) => a + Math.pow((b - avgX), 2)) / xs.length);
                rmsX = Math.sqrt(xs.reduce((a, b) => a + Math.pow(b, 2)) / xs.length);
            }
            if (ys.length > 0) {
                minY = Math.min(...ys);
                maxY = Math.max(...ys);
                avgY = ys.reduce((a, b) => a + b) / ys.length;
                stdY = Math.sqrt(ys.reduce((a, b) => a + Math.pow((b - avgY), 2)) / ys.length);
                rmsY = Math.sqrt(ys.reduce((a, b) => a + Math.pow(b, 2)) / ys.length);
            }
            if (zs.length > 0) {
                minZ = Math.min(...zs);
                maxZ = Math.max(...zs);
                avgZ = zs.reduce((a, b) => a + b) / zs.length;
                stdZ = Math.sqrt(zs.reduce((a, b) => a + Math.pow((b - avgZ), 2)) / zs.length);
                rmsZ = Math.sqrt(zs.reduce((a, b) => a + Math.pow(b, 2)) / zs.length);
            }

            console.log(rows);
            setWave({
                devid: wave.devid,
                timeCreated: new Date(wave.time_created),
                rows: rows,
                minX: minX,
                minY: minY,
                minZ: minZ,
                maxX: maxX,
                maxY: maxY,
                maxZ: maxZ,
                avgX: avgX,
                avgY: avgY,
                avgZ: avgZ,
                stdX: stdX,
                stdY: stdY,
                stdZ: stdZ,
                rmsX: rmsX,
                rmsY: rmsY,
                rmsZ: rmsZ,
            });

        }, (error) => {
            console.log(error);
        });
    }, []);

    const { devid, timeCreated } = wave;

    return (
        <div className={"wavechart_container"}>
            {
                showTitle ? (
                    <div className="wavechart__title">
                        <p>ID. {devid == null ? "--" : devid.substring(devid.length - 6).toUpperCase()}</p>
                        <p>{timeCreated == null ? "--" : timeCreated.toLocaleString("default", { hour12: false })}</p>
                    </div>
                ) : (
                    null
                )
            }

            <div className="wavechart__linechart">
                <Chart chartType={"LineChart"}
                    width={"100%"}
                    height={"100%"}
                    columns={[
                        { type: 'number', label: 't' },
                        { type: 'number', label: 'x' },
                        { type: 'number', label: 'y' },
                        { type: 'number', label: 'z' },
                    ]}
                    rows={wave.rows}
                    options={
                        {
                            hAxis: {
                                title: 't',
                                titleTextStyle: {
                                    color: "#FFFFFF",
                                },
                                textStyle: {
                                    color: "#FFFFFF",
                                },
                                baselineColor: "#FFFFFF",
                                gridlines: {
                                    color: "#676767",
                                },
                                minorGridlines: {
                                    color: "#37404C",
                                },
                            },
                            vAxis: {
                                title: 'mG',
                                minValue: -2000,
                                maxValue: 2000,
                                titleTextStyle: {
                                    color: "#FFFFFF",
                                },
                                textStyle: {
                                    color: "#FFFFFF",
                                },
                                baselineColor: "#FFFFFF",
                                gridlines: {
                                    color: "#676767",
                                },
                                minorGridlines: {
                                    color: "#37404C",
                                },
                            },
                            legend: {
                                position: 'bottom',
                                textStyle: {
                                    color: "#FFFFFF",
                                }
                            },
                            backgroundColor: { fill: "transparent" },
                            annotations: { style: 'line' },
                            series: {
                                0: { color: "#1483DF", lineWidth: 1.5 },
                                1: { color: "#F64747", lineWidth: 1.5 },
                                2: { color: "#DDC62C", lineWidth: 1.5 },
                            },
                            chartArea: {
                                width: "70%",
                                height: "60%",
                            },
                        }
                    }
                    legend_toggle />
            </div>
            <div className="wavechart__trend">
                <div>
                    <p>Type</p><p>X</p><p>Y</p><p>Z</p>
                </div>
                <div>
                    <p>Min.</p>
                    <p>{wave.minX == null ? "--" : wave.minX.toFixed(2)}</p>
                    <p>{wave.minY == null ? "--" : wave.minY.toFixed(2)}</p>
                    <p>{wave.minZ == null ? "--" : wave.minZ.toFixed(2)}</p>
                </div>
                <div>
                    <p>Max.</p>
                    <p>{wave.maxX == null ? "--" : wave.maxX.toFixed(2)}</p>
                    <p>{wave.maxY == null ? "--" : wave.maxY.toFixed(2)}</p>
                    <p>{wave.maxZ == null ? "--" : wave.maxZ.toFixed(2)}</p>
                </div>
                <div>
                    <p>Avg.</p>
                    <p>{wave.avgX == null ? "--" : wave.avgX.toFixed(2)}</p>
                    <p>{wave.avgY == null ? "--" : wave.avgY.toFixed(2)}</p>
                    <p>{wave.avgZ == null ? "--" : wave.avgZ.toFixed(2)}</p>
                </div>
                <div>
                    <p>Std.</p>
                    <p>{wave.stdX == null ? "--" : wave.stdX.toFixed(2)}</p>
                    <p>{wave.stdY == null ? "--" : wave.stdY.toFixed(2)}</p>
                    <p>{wave.stdZ == null ? "--" : wave.stdZ.toFixed(2)}</p>
                </div>
                <div>
                    <p>RMS</p>
                    <p>{wave.rmsX == null ? "--" : wave.rmsX.toFixed(2)}</p>
                    <p>{wave.rmsY == null ? "--" : wave.rmsY.toFixed(2)}</p>
                    <p>{wave.rmsZ == null ? "--" : wave.rmsZ.toFixed(2)}</p>
                </div>
            </div>
        </div>
    );

}

export default WaveChart;