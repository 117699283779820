import axios from 'axios';


class InoVibeRequest {

    searchDevice(key, success, fail) {
        var url = "https://apis.prod.ino-vibe.ino-on.net/v3/device/";
        url += "?search_devid=" + key;

        console.log("Load..", url);
        axios({
            method: "GET",
            url: url,
            headers: {
                "accept": "application/json;charset=utf-8",
                "Content-Type": "application/json;charset=utf-8",
                "authorization": "Bearer " + localStorage.getItem("access_token")
            }
        }).then(
            response => {
                console.log("Success");
                success(response.status, response.data.data);
            }
        ).catch(
            error => {
                console.log(error);
                fail(error.status);
            }
        );
    }

}


var inovibe = new InoVibeRequest();


export default inovibe;