import React from "react";
import { useEffect } from "react";

import { TabControl, TabItem } from "../components/TabControl";
import Group from "../pages/Group";
import InstallLog from "../pages/InstallLog";
import Wave from "../pages/Wave";
import LoRaData from "../pages/LoRaData";
import LoRaDevice from "../pages/LoRaDevice";

import { logEvent } from "../event";

import "../css/Admin.css";

const Admin = ({auth}) => {

    useEffect(() => {
        logEvent("Page-Admin");
    }, []);

    return (
        <div className={"admin_container"}>
            <div className={"admin_title"}>
                <p>관리자</p>
            </div>
            <div className={"admin_content"}>
                <TabControl
                    menuColor={"white"}
                    menuSelectColor={"#37404c"}
                    menuBackgroundColor={"#202730"}>
                    <TabItem title={"Device"}>
                        <LoRaDevice/>
                    </TabItem>

                    <TabItem title={"Data"}>
                        <LoRaData/>
                    </TabItem>

                    <TabItem title={"Wave"}>
                        <Wave/>
                    </TabItem>

                    <TabItem title={"Group"}>
                        <Group></Group>
                    </TabItem>

                    <TabItem title={"Install Log"}>
                        <InstallLog/>
                    </TabItem>
                </TabControl>
            </div>
        </div>
    );
}

export default Admin;