import React, { useState, useEffect } from "react";
import { zip } from "rxjs";

import { createFirestore } from "../InoVibe/firebase.js";
import { CommentType, listLoRaDevice, findLoRaDevice, changeDeviceBuyer, listDeviceCommentStream, insertDeviceComment, deleteDeviceComment } from "../InoVibe/loraDevice.js";
import { listGroups, flatGroupTree } from "../InoVibe/group.js";

import TrashButton from "../components/TrashButton";
import "../css/LoRaDevice.css";


const LoRaDevice = () => {

    const PAGE_SIZE = 100;

    const [db, setDB] = useState(null);
    const [devices, setDevices] = useState([]);
    const [selectDevice, setSelectDevice] = useState(null);
    const [searchID, setSearchID] = useState("");
    const [groups, setGroups] = useState([]);

    useEffect(() => {
        createFirestore(db => {
            setDB(db);
        }, err => {
            console.log("LoRaDevice.useEffect: create failed " + err);
        })
    }, []);

    useEffect(() => {
        if (db != null) {
            const loraSrc = listLoRaDevice(db, PAGE_SIZE, null);
            const groupSrc = listGroups();

            zip(loraSrc, groupSrc).subscribe(data => {
                const loraDevice = data[0];
                var groups = flatGroupTree(data[1])
                    .filter(g => { return g.individual })
                    .sort((a, b) => { return a.name < b.name ? -1 : 1});

                groups.forEach(g => {
                    console.log(g);
                });

                setDevices(loraDevice);
                setGroups(groups);

                console.log(groups);
            });
        }
    }, [db]);

    useEffect(() => {
        if (db == null) {
            return;
        }

        setDevices([]);
        setSelectDevice(null);

        if (searchID === "") {
            console.log("Clear search");

            listLoRaDevice(db, PAGE_SIZE, null).subscribe(devices => {
                console.log("List search");
                setDevices(devices);
            });
        } else {
            console.log("Search...");

            findLoRaDevice(db, searchID.toLowerCase()).subscribe(devices => {
                console.log("Search Results " + devices.length);
                setDevices(devices);
            });
        }
    }, [searchID]);

    return (
        <div className={"lora_device_container"}>
            <div className={"lora_device__list_container"} onScroll={e => {
                if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
                    console.log("Bottom");
                    const lastDevice = devices[devices.length - 1];
                    listLoRaDevice(db, PAGE_SIZE, lastDevice).subscribe(devs => {
                        console.log("Load more " + devs.length);
                        setDevices(devices.concat(devs));
                    });
                }
            }}>
                <div className={"lora_device_search"}>
                    <input type="text" placeholder={"6자리 아이디 입력"} onKeyPress={e => {
                        if (e.key === "Enter") {
                            console.log("Do search!" + e.target.value);
                            setSearchID(e.target.value);
                        }
                    }} />
                </div>
                {
                    devices.map(d => {
                        return (
                            <div key={d.ltid} className={"lora_device_entry"} onClick={() => {
                                console.log("Select " + d.ltid);
                                setSelectDevice(d);
                            }}>
                                <div className={"lora_device_entry__title"}>
                                    <div><p>{d.ltid.substring(d.ltid.length - 6)}</p></div>
                                    <div><p>{d.is_registered ? "등록" : "미등록"}</p></div>
                                </div>
                                <div className={"lora_device_entry__info"}><p>{d.time_activate != null ? d.time_activate.toLocaleString() : "--"} 개통</p></div>
                            </div>
                        );
                    })
                }
            </div>

            <div className={"lora_device_detail_container"}>
                {
                    selectDevice == null ? (
                        <div className={"lora_device_detail--empty"}>
                            <p>디바이스를 선택하세요</p>
                        </div>
                    ) : (
                        <div className={"lora_device_detail"}>
                            <DeviceDetailView db={db} selectDevice={selectDevice} groups={groups} />
                            <div className={"lora_device_detail__group"}>
                                <div className={"lora_device_detail__title"}><p>관리 내역</p></div>
                                <div className={"lora_device_detail__content"}><CommentView db={db} device={selectDevice} /></div>
                            </div>

                            <div className={"lora_device_detail__group"}>
                                <div className={"lora_device_detail__title"}><p>설치 상태</p></div>
                                <div className={"lora_device_detail__content"}><StatusView device={selectDevice} /></div>
                            </div>

                            <div className={"lora_device_detail__group"}>
                                <div className={"lora_device_detail__title"}><p>설치 내역</p></div>
                                <div className={"lora_device_detail__content"}><InstallHistoryView device={selectDevice} /></div>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );

}

const DeviceDetailView = ({ db, selectDevice, groups }) => {

    return (
        <div className={"lora_device_detail__basic_info"}>
            <div className={"lora_device_detail__basic_info__info"}>
                <div><p>LTID</p></div>
                <div><p>{selectDevice.ltid}</p></div>
            </div>
            <div className={"lora_device_detail__basic_info__info"}>
                <div><p>등록상태</p></div>
                <div><p>{selectDevice.is_registered ? "등록" : "미등록"}</p></div>
            </div>
            <div className={"lora_device_detail__basic_info__info"}>
                <div><p>개통일</p></div>
                <div><p>{selectDevice.time_activate.toLocaleString()}</p></div>
            </div>
            <div className={"lora_device_detail__basic_info__info"}>
                <div><p>등록일</p></div>
                <div><p>{selectDevice.time_register != null ? selectDevice.time_register.toLocaleString() : "--"}</p></div>
            </div>
            <div className={"lora_device_detail__basic_info__info"}>
                <div><p>출고일</p></div>
                <div><p>{selectDevice.time_outbound != null ? selectDevice.time_outbound.toLocaleString() : "--"}</p></div>
            </div>
            <div className={"lora_device_detail__basic_info__info"}>
                <div><p>출고그룹</p></div>
                <div>
                    <select value={selectDevice.buyer_group_id} onChange={e => {
                        if (window.confirm("그룹을 변경하시겠습니까?")) {
                            console.log("Change group", e.target.value);

                            var foundGroup = null;
                            const findResult = groups.filter(g => g.id === e.target.value);
                            if (findResult.length > 0) {
                                foundGroup = findResult[0];
                            }

                            changeDeviceBuyer(db, selectDevice, foundGroup)
                                .subscribe(() => {
                                    console.log("Change group finish");
                                });
                        } else {
                            console.log("Group change canceled");
                        }
                    }}>
                        <option key="empty" value={"empty"}>그룹 없음</option>
                        {
                            groups.map(g => {
                                return <option key={g.id} value={g.id}>{g.name}</option>;
                            })
                        }
                    </select>
                </div>
            </div>

            <div className={"lora_device_detail__basic_info__info"}>
                <div><p>해지일</p></div>
                <div><p>{"--"}</p></div>
            </div>
        </div>
    );

}

const CommentView = ({ db, device }) => {

    const [comments, setComments] = useState([]);

    useEffect(() => {
        console.log("CommentView of ", device);
        listDeviceCommentStream(db, device.ltid).subscribe(c => {
            console.log("CommentView.receiveComment ", c.length);
            setComments(c);
        })
    }, [device]);

    const commentEntryFactory = (comment) => {
        if (comment.type === CommentType.User) {
            return <UserComment comment={comment} />
        } else if (comment.type === CommentType.GroupChange) {
            return <GroupChangeComment comment={comment} />
        } else if (comment.type === CommentType.InstallTest) {
            return <InstallComment comment={comment} />
        } else if (comment.type === CommentType.Registration) {
            return <RegistrationComment comment={comment} />
        } else {
            return <UnknownTypeComment comment={comment} />
        }
    }

    const UserComment = ({ comment }) => {
        return <div><p>{comment.msg}</p></div>;
    }

    const GroupChangeComment = ({ comment }) => {
        console.log("Comment", comment);
        return <div><p>그룹 변경 => {comment.data != null ? comment.data.name : "없음"}</p></div>;
    }

    const InstallComment = ({ comment }) => {
        return <div><p>설치 테스트</p></div>;
    }

    const RegistrationComment = ({ comment }) => {
        return <div><p>등록 완료</p></div>;
    }

    const UnknownTypeComment = ({ comment }) => {
        return <div>Unknown Comment type {comment.type}</div>;
    }

    return (
        <div className={"comment_container"}>
            <div className={"comment_input"}>
                <input type="text" placeholder="내용을 입력하세요" onKeyPress={e => {
                    if (e.key === "Enter") {
                        console.log("CommentView.insert " + e.target.value);
                        insertDeviceComment(db, device.ltid, CommentType.User, e.target.value)
                            .subscribe(comment => {
                                console.log("Inserted ", comment);
                                setComments([comment].concat(comments));
                            });
                        e.target.value = "";
                    }
                }} />
            </div>
            {
                comments.length > 0 ? (
                    <div className={"comment_msg"}>
                        {
                            comments.map((c, i) => {
                                return (
                                    <div key={i} className={"comment_msg__entry"}>
                                        <div className={"comment_msg__entry__meta"}>
                                            <div><p>{c.time_created.toLocaleString()}</p></div>
                                            <div><p>{c.username}</p></div>
                                        </div>
                                        <div className={"comment_msg__entry__msg"}>{commentEntryFactory(c)}</div>
                                        <div className={"comment_msg__entry__delete"}>
                                            <TrashButton onClick={() => {
                                                var result = window.confirm("정말 삭제하시겠습니까?");
                                                if (result) {
                                                    console.log("CommentView.delete");
                                                    deleteDeviceComment(db, c).subscribe(comment => {
                                                        var index = comments.indexOf(comment);
                                                        console.log("Deleted ", comment, index);
                                                        if (index > -1) {
                                                            var newComments = comments.slice();
                                                            newComments.splice(index, 1);
                                                            setComments(newComments);
                                                        }
                                                    });
                                                }
                                            }} /></div>
                                    </div>
                                );
                            })
                        }
                    </div>
                ) : (
                    <div className={"comment_msg--empty"}><p>내용 없음</p></div>
                )
            }
        </div>
    );

}

const StatusView = () => {
    useEffect(() => {
    }, []);

    return (
        <div className={"status_container"}>
            Status will be here!
        </div>
    );
}

const InstallHistoryView = () => {
    useEffect(() => {

    }, []);

    return (
        <div className={"install_history_container"}>
            Install history will be here!
        </div>
    );
}

export default LoRaDevice;