import React, { useEffect, useState } from "react";

import { useTranslation } from 'react-i18next';
import { isAdmin } from '../InoVibe/user';
import { rxDevices, loadDeviceDetails } from '../InoVibe/device';
import { logEvent } from "../event";
import { formatDateString } from "../utils/dateutils";

import { updateInstallInfo } from "../InoVibe/device";
import { listGroups, flatGroupTree } from "../InoVibe/group";
import { eventStore, EVT_MODIFY_DEVICE } from '../App';

import '../css/DeviceInfo.css';


const ViewMode = Object.freeze({
    ReadOnly: 0,
    Modify: 1,
    Confirm: 2,
    Loading: 3,
});

const DeviceInfo = ({ device }) => {

    const [groupName, setGroupName] = useState("");
    const [installer, setInstaller] = useState("");
    const [timeInstalled, setTimeInstalled] = useState("--");
    const [timeUpdated, setTimeUpdated] = useState("--");

    const [fwVer, setFwVer] = useState("--");
    const [battery, setBattery] = useState("--");
    const [rssi, setRSSI] = useState("--");

    const [recogMode, setRecogMode] = useState(0);
    const [accRange, setAccRange] = useState(0);
    const [intThreshold, setIntThreshold] = useState("--");

    const [mode, setMode] = useState(ViewMode.ReadOnly);

    const { t } = useTranslation();

    useEffect(() => {
        console.log("DeviceInfo.useEffect()", device.devid);
        logEvent("Page-DeviceInfo");

        const devSubscribe = rxDevices.value[device.devid].subscribe(dev => {
            setGroupName(dev.group_name);
            setInstaller(dev.installer);
            setTimeInstalled(formatDateString(dev.time_installed));
            setTimeUpdated(formatDateString(dev.time_updated));

            setFwVer(dev.app_fw_ver);
            setBattery(dev.battery);
            setRSSI(dev.RSSI);

            setRecogMode(device.recog_mode);
            setAccRange(device.sensor_range);
            setIntThreshold(device.int_threshold_mg);
        });

        loadDeviceDetails(device.devid);

        const subscribe = eventStore.subscribe(() => {
            const type = eventStore.getState()['type'];

            if (type === EVT_MODIFY_DEVICE) {
                console.log("DeviceInfo.effect() => eventStore Modify", device.devid);
                setMode(ViewMode.Modify);
            }
        });

        return () => {
            subscribe();
            devSubscribe.unsubscribe();
        }
    }, [groupName, installer, intThreshold]);

    const [groups, setGroups] = useState([])

    useEffect(() => {
        if (mode !== ViewMode.Modify) {
            return;
        }

        const groupSubscribe = listGroups().subscribe(groups => {
            const flatGroups = flatGroupTree(groups);
            flatGroups.sort((a, b) => {
                return (a.name < b.name) ? -1 : 1;
            });

            setGroups(flatGroups);
        });

        return () => {
            groupSubscribe.unsubscribe();
        }
    }, [mode]);

    useEffect(() => {
    }, [groups]);

    const getRecogModeName = (mode) => {
        switch (mode) {
            case 0:
                return "No Filter";
            case 1:
                return "V3 Filter";
            case 2:
                return "V4 Filter";
            default:
                return "--";
        }
    }

    const getSensorRangeGravity = (range) => {
        switch (range) {
            case 1:
                return 2;
            case 2:
                return 4;
            case 3:
                return 8;
            case 4:
                return 16;
            default:
                return 0;
        }
    }

    return (
        <div className={"deviceinfo__info_container"}>
            <div className="deviceinfo__row">
                <p className="deviceinfo__row__title">{ t("devinfo-title-group", "Group") }</p>
                {
                    mode == ViewMode.Modify && groups.length > 0 ? (
                        <GroupSelectComponents groups={groups} selectName={groupName} onApplyHandler={id => {
                            updateInstallInfo(device.devid, null, id).subscribe(r => {
                                setMode(ViewMode.ReadOnly);
                                loadDeviceDetails(device.devid);
                            });
                        }} onCancelHandler={() => {
                            setMode(ViewMode.ReadOnly);
                        }}></GroupSelectComponents>
                    ) : (
                        <p className="deviceinfo__row__value">{groupName === "" ? "--" : groupName}</p>
                    )
                }
            </div>
            <div className="deviceinfo__row">
                <p className="deviceinfo__row__title">{ t("devinfo-title-installer", "Installer") }</p>
                <p className="deviceinfo__row__value">{installer}</p>
            </div>
            <div className="deviceinfo__row">
                <p className="deviceinfo__row__title">{ t("devinfo-title-installdate", "Install Date") }</p>
                <p className="deviceinfo__row__value">{timeInstalled}</p>
            </div>
            <div className="deviceinfo__row">
                <p className="deviceinfo__row__title">{ t("devinfo-title-update", "Update Date") }</p>
                <p className="deviceinfo__row__value">{timeUpdated}</p>
            </div>

            <div className="deviceinfo__row">
                <p className="deviceinfo__row__title">{ t("devinfo-title-fw", "FW. Ver.") }</p>
                <p className="deviceinfo__row__value">{fwVer}</p>
            </div>

            <div className="deviceinfo__row">
                <p className="deviceinfo__row__title">{ t("devinfo-title-bat-rssi", "BAT. / RSSI") }</p>
                <p className="deviceinfo__row__value">{battery}% / {rssi}dBm</p>
            </div>
            {
                isAdmin() ? (
                    <div>
                        <div className="deviceinfo__row">
                            <p className="deviceinfo__row__title">{ t("devinfo-title-recog-mode", "Recog. Mode") }</p>
                            <p className="deviceinfo__row__value">{getRecogModeName(recogMode)}</p>
                        </div>
                        <div className="deviceinfo__row">
                            <p className="deviceinfo__row__title">{ t("devinfo-title-sensor-range", "Sensor Range") }</p>
                            <p className="deviceinfo__row__value">{getSensorRangeGravity(accRange)}G</p>
                        </div>
                        {/* <div className="deviceinfo__row">
                            <p className="deviceinfo__row__title">Int. Threshold</p>
                            <p className="deviceinfo__row__value">{intThreshold}mG</p>
                        </div> */}
                    </div>
                ) : (
                    null
                )
            }
        </div>
    );

}

const GroupSelectComponents = ({ groups, selectName, onApplyHandler, onCancelHandler }) => {

    const [modeConfirm, setModeConfirm] = useState(false);
    const [selectGroupID, setSelectGroupID] = useState(null);

    useEffect(() => {
        console.log("GroupSelectComponents.useEffect()", groups);
    }, [groups]);

    return (
        <div className={"deviceinfo__group_select"}>
            <select name={"new_group"} id={"group-select"} onChange={e => {
                console.log("Change", e.target.value);
                setSelectGroupID(e.target.value);
                setModeConfirm(true);
            }}>
                <option key={"no_group"} value={""}>그룹 없음</option>
                {
                    groups.map(g => {
                        if (g.name === selectName) {
                            return (<option key={g.id} value={g.id} selected>{g.name}</option>);
                        } else {
                            return (<option key={g.id} value={g.id}>{g.name}</option>);
                        }
                    })
                }
            </select>

                {
                    modeConfirm ? (
                        <div className={"deviceinfo__group_confirm"}>
                            <p onClick={e => {
                                if (onApplyHandler != null && selectGroupID != null) {
                                    onApplyHandler(selectGroupID);
                                }
                            }}>Apply</p>
                            <p onClick={e => {
                                if (onCancelHandler != null) {
                                    onCancelHandler();
                                }
                                setSelectGroupID(null);
                                setModeConfirm(false);
                            }}>Cancel</p>
                        </div>
                    ) : (
                        null
                    )
                }
        </div>
    );

}

export default DeviceInfo;