import React, { useEffect, useState } from "react";

import { useTranslation } from 'react-i18next';
import { TabControl, TabItem } from "./TabControl";
import DeviceInfo from "./DeviceInfo";
import AlarmList from "../components/AlarmList";
import TrendChart from "../components/TrendChart";
import InoVibeGDetailInfo from "../components/InoVibeGDetailView";

import { rxDevices, updateInstallInfo, DeviceType } from "../InoVibe/device";
import { getAddressOfLocation, loadDeviceDetails, deviceResetAlarm } from '../InoVibe/device';

import { formatDateString } from "../utils/dateutils";
import { eventStore, EVT_MODIFY_DEVICE } from '../App';
import '../css/SideView.css';


const ViewMode = Object.freeze({
    ReadOnly: 0,
    Modify: 1,
    Confirm: 2,
    Loading: 3,
});

const SideView = ({ device }) => {

    const [mode, setMode] = useState(ViewMode.ReadOnly);

    useEffect(() => {
        console.log("SideView.useEffect", device.devid);

        const sub = eventStore.subscribe(() => {
            const type = eventStore.getState()['type'];

            if (type === EVT_MODIFY_DEVICE) {
                console.log("InovibeSBasicInfo.effect() => eventStore Modify", device.devid);
                setMode(ViewMode.Modify);
            }
        });

        return () => {
            sub();
        };
    }, [device]);

    return (
        <div className={"sideview_container"}>
            <div className={"sideview__brief"}>
                {basicInfoViewFactory(device)}
            </div>
            <div className={"sideview__detail"}>
                {detailInfoViewFactory(device)}
            </div>
        </div>
    );

}

const basicInfoViewFactory = (dev) => {
    var view = <div></div>;

    if (dev.dev_type === DeviceType.InoVibe || dev.dev_type === DeviceType.InoVibeS) {
        view = <InoVibeSBasicInfo device={dev}></InoVibeSBasicInfo>
    } else if (dev.dev_type === DeviceType.InoVibeG) {
        view = <InoVibeGBasicInfo device={dev}></InoVibeGBasicInfo>
    }

    return view;
}

const InoVibeSBasicInfo = ({ device }) => {

    const [address, setAddress] = useState(null);
    const [alias, setAlias] = useState(device.alias);
    const [alarmed, setAlarmed] = useState(device.is_alarmed);
    const [mode, setMode] = useState(ViewMode.ReadOnly);

    const { t } = useTranslation();

    useEffect(() => {
        console.log("InoVibeSBasicInfo.useEffect()", device, device.time_alarmed);

        getAddressOfLocation(device.lat, device.lng)
            .subscribe({
                next: (addr) => {
                    console.log(addr);
                    setAddress(addr);
                }
            });

        const sub = eventStore.subscribe(() => {
            const type = eventStore.getState()['type'];

            if (type === EVT_MODIFY_DEVICE) {
                console.log("InovibeSBasicInfo.effect() => eventStore Modify", device.devid);
                setMode(ViewMode.Modify);
            }
        });

        const deviceSubscriber = rxDevices.value[device.devid].subscribe(dev => {
            console.log("Device Changed", dev.devid);
            setAlias(dev.alias);
            setAlarmed(dev.is_alarmed);
        });

        return () => {
            sub();
            deviceSubscriber.unsubscribe();
        };
    }, [device, address]);

    var stateColor = null;
    var showAlarmView = false;

    if (device.is_alive) {
        if (device.install_status == 3) {
            if (device.is_alarmed) {
                stateColor = "#EB4626";
                showAlarmView = true;
            } else {
                stateColor = "#54B644";
            }
        } else {
            stateColor = "#A6A7A8";
        }
    } else {
        stateColor = "#A6A7A8";
    }

    return (
        <div className={"basic_info_container"}>
            <div className={"basic_info__title"}>
                <div><p style={{ color: stateColor }}>{device.devid.substring(device.devid.length - 6).toUpperCase()}</p></div>
                <div><p>Ino-Vibe S</p></div>
            </div>
            <div className={"basic_info__alias"}>
                {
                    mode !== ViewMode.ReadOnly ? (
                        <div>
                            <TextChangeComponents placeholder={alias} msg={"Update?"} onOkCallback={(value) => {
                                console.log("New Value", value);
                                updateInstallInfo(device.devid, value, null).subscribe(r => {
                                    console.log("InoVibeSBasic", r);
                                    setMode(ViewMode.ReadOnly);
                                    loadDeviceDetails(device.devid);
                                }, e => {
                                    console.log("InoVibeSBasic", e);
                                });
                            }} onCancelCallback={e => {
                                setMode(ViewMode.ReadOnly);
                            }}></TextChangeComponents>
                        </div>
                    ) : (
                        <p>{alias}</p>
                    )
                }
            </div>
            <div className={"basic_info__address"}>{address == null ? "Loading" : address}</div>

            {
                alarmed ? (
                    <div className={"basic_info__alarm"}>
                        <p>{ formatDateString(device.time_alarmed) }</p>
                        <button onClick={e => {
                            console.log("Clear alarm");
                            if (window.confirm(t("devinfo-confim-alarm-clear-msg", "Clear Alarm?"))) {
                                deviceResetAlarm(device.devid, (resp) => {
                                    console.log("ResetAlarm Success ", resp);
                                    setAlarmed(false);
                                    loadDeviceDetails(device.devid);
                                }, (err) => {
                                    console.log("ResetAlarm Failed ", err);
                                });
                            }
                        }}>
                            {t("devbrief-alarm-clear", "Reset Alarm")}
                        </button>
                    </div>
                ): (
                        null
                )
            }

        </div>
    );

}

const TextChangeComponents = ({ placeholder, msg, onOkCallback, onCancelCallback }) => {

    const [inputValue, setInputValue] = useState("");
    const [confirm, setConfirm] = useState(false);

    const buttonStyle = {
        margin: "0px 5px 0px 5px",
        padding: "0px",
        fontSize: "11px",
        cursor: "pointer",
    };

    useEffect(() => {

    }, []);

    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
        }}>
            <input type='text' placeholder={placeholder} onKeyPress={e => {
                setInputValue(e.target.value);

                if (e.key === 'Enter') {
                    setConfirm(true);
                }
            }}></input>
            {
                confirm ? (
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                    }}>
                        <p style={{
                            fontSize: "12px",
                            margin: "0px",
                            padding: "0px",
                        }}>{msg}</p>
                        <p style={buttonStyle} onClick={() => onOkCallback(inputValue)}>OK</p>
                        <p style={buttonStyle} onClick={() => onCancelCallback()}>Cancel</p>
                    </div>
                ) : (
                    null
                )
            }
        </div>
    );

}

const InoVibeGBasicInfo = ({ device }) => {

    const [address, setAddress] = useState("--");

    useEffect(() => {
        console.log("InoVibeGBasicInfo.useEffect()");

        getAddressOfLocation(device.installLocation.latitude, device.installLocation.longitude)
            .subscribe({
                next: (addr) => {
                    console.log(addr);
                    setAddress(addr);
                }
            });
    }, [device]);

    return (
        <div className={"basic_info_container"}>
            <div className={"basic_info__title"}>
                <div><p style={{ color: "#54B644" }}>{device.devid.toUpperCase()}</p></div>
                <div><p>Ino-Vibe G</p></div>
            </div>
            <div className={"basic_info__alias"}>{device.alias}</div>
            <div className={"basic_info__address"}>{address}</div>
        </div>
    );

}

const detailInfoViewFactory = (dev) => {
    var view = <div></div>;

    if (dev.dev_type === DeviceType.InoVibe || dev.dev_type === DeviceType.InoVibeS) {
        view = <InoVibeSDetailInfo device={dev}></InoVibeSDetailInfo>
    } else if (dev.dev_type == DeviceType.InoVibeG) {
        view = <InoVibeGDetailInfo device={dev}></InoVibeGDetailInfo>
    }

    return view;
}

const InoVibeSDetailInfo = ({ device }) => {

    const { t } = useTranslation();

    useEffect(() => {
        console.log("InoVibeSDetailInfo.useEffect()", device.devid);
    }, [device]);

    return (
        <div className={"detail_info_container"}>
            <TabControl
                menuSelectColor={"#37404c"}
                menuBackgroundColor={"#202730"}>
                <TabItem title={t("tab-info", "Info")} contentBackgroundColor={"#37404c"}>
                    <DeviceInfo key={device.devid} device={device}></DeviceInfo>
                </TabItem>

                <TabItem title={t("tab-alarm", "Alarm")}>
                    <AlarmList key={device.devid} device={device}></AlarmList>
                </TabItem>

                <TabItem title={t("tab-chart", "Chart")}>
                    <TrendChart key={device.devid} device={device}></TrendChart>
                </TabItem>
            </TabControl>
        </div>
    );

}

export default SideView;