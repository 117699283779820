import React, { useState, useEffect } from "react";

import { useTranslation } from 'react-i18next';
import { isAdmin } from '../InoVibe/user';
import { logEvent } from "../event";

import { rxDevices, InstallStatus } from '../InoVibe/device';
import { eventStore, EVT_SELECT_DEVICE } from "../App";

import '../css/StatisticPanel.css';


const StatisticPanel = () => {
    const [statistics, setStatistics] = useState(
        {
            total: { device: [], count: 0 },
            normal: { device: [], count: 0 },
            alarm: { device: [], count: 0 },
            uninstalling: { device: [], count: 0 },
            no_signal: { device: [], count: 0 },
            error: { device: [], count: 0 },
        }
    );

    const [selectStatistic, setSelectStatistic] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        rxDevices.subscribe(devices => {
            console.log("Rx update");

            var total = 0;
            var nosignal = 0;
            var alarm = 0;
            var err = 0;
            var normal = 0;
            var uninstalling = 0;

            var totalDevs = [];
            var nosignalDevs = [];
            var alarmDevs = [];
            var errDevs = [];
            var normalDevs = [];
            var uninstallingDevs = [];

            for (var key in devices) {
                total++;
                totalDevs.push(devices[key].value);

                if (devices[key].value.install_status === InstallStatus.Uninstalling) {
                    uninstalling++;
                    uninstallingDevs.push(devices[key].value);
                } else {
                    if (devices[key].value.is_alive) {
                        if (isAdmin() && devices[key].value.is_device_ok === false) {
                            err++;
                            errDevs.push(devices[key].value);
                        } else {
                            if (devices[key].value.is_alarmed) {
                                alarm++;
                                alarmDevs.push(devices[key].value);
                            } else {
                                normal++;
                                normalDevs.push(devices[key].value);
                            }
                        }
                    } else {
                        nosignal++;
                        nosignalDevs.push(devices[key].value);
                    }
                }
            }

            if (statistics.total.count !== total || statistics.alarm.count !== alarm || statistics.no_signal.count !== nosignal
                || statistics.error.count !== err || statistics.normal.count !== normal || statistics.uninstalling.count !== uninstalling) {
                setStatistics({
                    total: { device: totalDevs, count: total },
                    normal: { device: normalDevs, count: normal },
                    alarm: { device: alarmDevs, count: alarm },
                    uninstalling: { device: uninstallingDevs, count: uninstalling },
                    no_signal: { device: nosignalDevs, count: nosignal },
                    error: { device: errDevs, count: err },
                });
            }
        });
    }, []);

    const statConfig = {
        total: { name: t("stat-total", "Total"), onlyAdmin: false },
        normal: { name: t("stat-normal", "Normal"), onlyAdmin: false },
        alarm: { name: t("stat-alarm", "Alarm"), onlyAdmin: false },
        no_signal: { name: t("stat-nosignal", "No signal"), onlyAdmin: false },
        uninstalling: { name: t("stat-suspend", "Suspend"), onlyAdmin: false },
        error: { name: t("stat-error", "Err"), onlyAdmin: true },
    };

    return (
        <div className={"stat_container"}>
            <div className={"stat__count"}>
                {
                    Object.keys(statistics).map((key) => {
                        const entry = statistics[key];
                        const cfg = statConfig[key];

                        var style = (selectStatistic === entry) ? "stat_entry--select" : "stat_entry";
                        style += " " + key;
                        if (!entry.onlyAdmin || (cfg.onlyAdmin && isAdmin())) {
                            return (
                                <div key={key} className={style} onClick={e => {
                                    console.log("Select Statistic", key)
                                    if (selectStatistic === entry) {
                                        setSelectStatistic(null);
                                    } else {
                                        logEvent(`stat-${key}`);
                                        setSelectStatistic(statistics[key]);
                                    }
                                }}>
                                    <p className={"stat_entry__title"}>{cfg.name}</p>
                                    <p className={"stat_entry__value"}>{entry.count}</p>
                                </div>
                            )
                        } else {
                            return null;
                        }
                    })
                }
            </div>
            {
                selectStatistic == null ? (
                    null
                ) : (
                    <div className={"stat__list"}>
                        <div className={"stat__list__scroll"}>
                            {
                                selectStatistic.device.map(dev => {
                                    return <StatusDeviceListItem
                                        key={dev.devid}
                                        devid={dev.devid}
                                        alias={dev.alias}
                                        is_alive={dev.is_alive}
                                        is_alarmed={dev.is_alarmed}>
                                    </StatusDeviceListItem>;
                                })
                            }
                        </div>
                    </div>
                )
            }
        </div>
    );
}

const StatusDeviceListItem = ({ devid, alias, is_alive, is_alarmed }) => {
    var colorCode = "#54B644";
    if (is_alive) {
        if (is_alarmed) {
            colorCode = "#EB4626";
        }
    } else {
        colorCode = "#A6A6A8";
    }

    return (
        <div className={"status_dev_list_item_container"}
            onClick={(e) => {
                console.log("StatusDeviceListItem Click ", devid);
                eventStore.dispatch({ type: EVT_SELECT_DEVICE, devid: devid });
            }}>
            <div style={{ backgroundColor: colorCode }}></div>
            <div style={{ color: colorCode }}>{devid.substring(devid.length - 6).toUpperCase()}</div>
            <div>{alias}</div>
        </div>
    );
}

export default StatisticPanel;