import axios from 'axios';
import * as Rx from 'rxjs';

import { eventStore, EVT_UNAUTHORIZED } from '../App';


const InstallStatus = Object.freeze({
    Initial: 0,
    Requested: 1,
    Installing: 2,
    Installed: 3,
    Uninstalling: 4,
    Discarded: 5,
    WaitInstallComplete: 6,
})

export const DeviceType = Object.freeze({
    InoVibe: 2,
    InoVibeS: 3,
    InoVibePro: 100,
    InoVibeG: 101,
});

const BASE_URL = "https://rest.ino-vibe.ino-on.dev";

// TODO: Deprecated
const listDevice = (installStatus, success, error) => {
    var url = `${BASE_URL}/rest/v3/device`;
    if (installStatus != null) {
        url += "?install_status=" + installStatus;
    }

    axios({
        method: "GET",
        url: url,
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "Authorization": "Bearer " + localStorage.getItem("access_token"),
        }
    }).then(resp => {
        if (success != null) {
            success(resp.data.devs);
        }
    }).catch(err => {
        if (err.status_code === 401) {
            eventStore.dispatch({ type: EVT_UNAUTHORIZED });
        }

        if (error != null) {
            error(err);
        }
    });
}

const detailDevice = (devid, success, error) => {
    axios({
        method: "GET",
        url: BASE_URL + "/rest/v3/device/" + devid,
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "Authorization": "Bearer " + localStorage.getItem("access_token")
        }
    }).then(resp => {
        success(resp.data);
    }).catch(err => {
        console.log("ERROR", err);
        error(err);
    });
}

const deviceResetAlarm = (devid, success, error) => {
    axios({
        method: "PUT",
        url: BASE_URL + "/rest/v3/device/" + devid + "/reset_alarm",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "Authorization": "Bearer " + localStorage.getItem("access_token")
        }
    }).then(resp => {
        success(resp);
    }).catch(err => {
        console.log("ERROR", err);
        error(err);
    });
}

const alarmList = (devid, offset, count, success, error) => {
    var url = BASE_URL + "/rest/v3/device/" + devid;
    url += "/alarm?";
    url += "offset=" + offset;
    url += "&count=" + count;

    console.log(url);

    axios({
        method: "GET",
        url: url,
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "Authorization": "Bearer " + localStorage.getItem("access_token")
        }
    }).then(resp => {
        success(resp);
    }).catch(err => {
        console.log("ERROR", err);
        error(err);
    });
}

var rxDevices = new Rx.BehaviorSubject({});
var installedDevSubscribe = null;
var uninstallingDevSubscribe = null;

const syncDeviceList = () => {
    console.log("Start sync device");

    installedDevSubscribe = Rx.timer(0, 10000).subscribe(val => {
        fetchDevice(InstallStatus.Installed);
    });

    uninstallingDevSubscribe = Rx.timer(0, 30000).subscribe(val => {
        fetchDevice(InstallStatus.Uninstalling);
    });
}

const fetchDevice = (installStatus) => {
    var isChanged = false;

    listDevice(installStatus, (devs) => {
        devs.forEach(device => {
            var newDevice = Device.createFromDict(device);

            if (!(device.devid in rxDevices.value)) {
                var newSubject = new Rx.BehaviorSubject(newDevice);
                rxDevices.value[device.devid] = newSubject;
                isChanged = true;
            } else {
                var oldDevice = rxDevices.value[device.devid].value;

                if (!oldDevice.equal(newDevice)) {
                    console.log("Update", device.devid);
                    rxDevices.value[device.devid] = new Rx.BehaviorSubject(newDevice);
                    isChanged = true;
                }
            }
        });

        if (isChanged) {
            rxDevices.next(rxDevices.value);
        }
    },
        (err) => {
            console.log(err);
        });
}

const stopDeviceSync = () => {
    if (installedDevSubscribe != null) {
        installedDevSubscribe.unsubscribe();
    }

    if (uninstallingDevSubscribe != null) {
        uninstallingDevSubscribe.unsubscribe();
    }
}

class Device {
    constructor() {
        this.devid = "";
        this.alias = "";

        this.dev_type = 0;

        this.install_status = 0;
        this.is_alarmed = false;
        this.is_alive = false;
        this.is_device_ok = false;

        this.lat = 0;
        this.lng = 0;

        this.address = "";
        this.alias = "";
        this.group_name = "";
        this.installer = "";
        this.time_installed = null;
        this.time_updated = null;
        this.time_alarmed = null;
        this.app_fw_ver = "";
        this.battery = 0;
        this.RSSI = 0;

        this.recog_mode = 0;
        this.sensor_range = 0;
        this.int_threshold_mg = 0;
    }

    static createFromDict(data) {
        var newDevice = new Device();
        newDevice.devid = data.devid;
        newDevice.alias = data.alias;
        newDevice.dev_type = data.dev_type;

        newDevice.install_status = data.install_status;
        newDevice.is_alarmed = data.is_alarmed;
        newDevice.time_alarmed = data.alarm_date === "" ? null : new Date(data.alarm_date);
        newDevice.is_alive = data.is_alive;
        newDevice.is_device_ok = data.device_ok;
        newDevice.lat = data.lat;
        newDevice.lng = data.lng;

        return newDevice;
    }

    equal(otherDevice) {
        if (this.is_device_ok !== otherDevice.is_device_ok) {
            return false;
        }
        if (this.install_status !== otherDevice.install_status) {
            return false;
        }
        if (this.is_alarmed !== otherDevice.is_alarmed) {
            return false;
        }
        if (this.is_alive !== otherDevice.is_alive) {
            return false;
        }
        return true;
    }

    getDeviceTypeName() {
        if (this.dev_type === 2) {
            return "Ino-Vibe";
        } else if (this.dev_type === 3) {
            return "Ino-Vibe S";
        } else if (this.dev_type === 100) {
            return "Ino-Vibe Pro";
        } else {
            return "--";
        }
    }

    getRecogModeName() {
        switch (this.recog_mode) {
            case 0:
                return "No Filter";
            case 1:
                return "V3 Filter";
            case 2:
                return "V4 Filter";
            default:
                return "--";
        }
    }

    getSensorRangeGravity() {
        switch (this.sensor_range) {
            case 1:
                return 2;
            case 2:
                return 4;
            case 3:
                return 8;
            case 4:
                return 16;
            default:
                return 0;
        }
    }
}

const requestAddress = (latitude, longitude, success, error) => {
    let geoCodeApiBase = "https://maps.googleapis.com/maps/api/geocode/json";
    let latlng = "" + latitude + "," + longitude;
    let language = navigator.language || navigator.userLanguage;

    axios({
        method: "GET",
        url: geoCodeApiBase + "?key=" + process.env.REACT_APP_API_KEY + "&latlng=" + latlng + "&language=" + language,
    }).then(response => {
        success(response.data.results[0].formatted_address);
    }).catch(reason => {
        error(reason);
    });
}

const loadDeviceDetails = (devid) => {
    console.log("loadDeviceDetails ", devid);
    var rxDevice = rxDevices.value[devid];

    detailDevice(rxDevice.value.devid,
        (device) => {
            rxDevice.value.alias = device.alias;
            rxDevice.value.group_name = device.group_name;
            rxDevice.value.installer = device.installer;

            rxDevice.value.time_installed = new Date(device.install_date);
            rxDevice.value.time_updated = device.update_date === "" ? null : new Date(device.update_date);
            rxDevice.value.time_alarmed = device.alarm_date === "" ? null : new Date(device.alarm_date);
            rxDevice.value.battery = device.bat;
            rxDevice.value.RSSI = device.rssi;
            rxDevice.value.app_fw_ver = device.app_fw_ver;

            rxDevice.value.recog_mode = device.recog_type;
            rxDevice.value.sensor_range = device.sensor_range;
            rxDevice.value.int_threshold_mg = device.int_thres_mg;

            rxDevice.next(rxDevice.value);
        },
        (err) => {

        });

    requestAddress(rxDevice.value.lat, rxDevice.value.lng,
        (addr) => {
            rxDevice.value.address = addr;
            rxDevice.next(rxDevice.value);
        }, (err) => {
        });
}

export const getStatusLog = (devid, dateFrom, pageSize) => {
    const publisher = new Rx.Subject();

    var pageNo = 0;
    _getStatusLog(devid, dateFrom, pageSize, pageNo)
        .subscribe({
            next: (data) => {
                publisher.next(data);
            },
        });

    return publisher;
}

const _getStatusLog = (devid, dateFrom, pageSize, pageNo) => {
    const publisher = new Rx.Subject();

    var url = `${BASE_URL}/rest/v3/device/${devid}/status_log`;
    url += "?page_no=" + pageNo;
    url += "&page_size=" + pageSize;
    url += `&time_start=${dateFrom.getFullYear()}${dateFrom.getMonth() + 1}${dateFrom.getDate()}`;

    axios({
        method: "GET",
        url: url,
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "Authorization": "Bearer " + localStorage.getItem("access_token"),
        }
    }).then(resp => {
        var currentPage = resp.data.page_no != null ? resp.data.page_no : 0;
        var hasNext = resp.data.logs.length === pageSize ? true : false;

        console.log("Resp", resp.data);
        console.log("ID", resp.data.devid);
        console.log("Lengh", resp.data.logs.length);
        console.log("Page", currentPage);
        console.log("Next?", hasNext);

        var batteryData = [];
        var temperatureData = [];
        var rssiData = [];

        resp.data.logs.forEach((item, i) => {
            batteryData.push([new Date(item.time_created), item.battery]);
            rssiData.push([new Date(item.time_created), item.rssi]);

            const BMA_ERR_FLAG = 127
            if (item.temperature !== BMA_ERR_FLAG) {
                temperatureData.push([new Date(item.time_created), item.temperature]);
            }
        });

        publisher.next({
            battery: batteryData,
            temperature: temperatureData,
            rssi: rssiData,
            hasNext: hasNext,
        });
    }).catch(reason => {
        console.log("Err", reason);
    });

    return publisher;
}

export const getAddressOfLocation = (latitude, longitude) => {
    const subject = new Rx.Subject();

    let geoCodeApiBase = "https://maps.googleapis.com/maps/api/geocode/json";
    let latlng = "" + latitude + "," + longitude;
    let language = navigator.language || navigator.userLanguage;

    const apiKey = "AIzaSyAf8YQ4mAy95k1HLmSKFvmHbKQ_bvakTyA";
    // const apiKey = process.env.REACT_APP_API_KEY;

    axios({
        method: "GET",
        url: geoCodeApiBase + "?key=" + apiKey + "&latlng=" + latlng + "&language=" + language,
    }).then(response => {
        // success(response.data.results[0].formatted_address);
        subject.next(response.data.results[0].formatted_address)
    }).catch(reason => {
        // error(reason);
    });

    return subject;
}

export const listDevices = (installStatus, groupID) => {
    const subject = new Rx.Subject();

    var params = {};
    if (installStatus != null) {
        params["install_status"] = installStatus;
    }

    if (groupID != null)  {
        params["group_id"] = groupID;
    }

    var url = `${BASE_URL}/rest/v3/device`;

    Object.keys(params).forEach((k, i) => {
        if (i === 0) {
            url += "?";
        } else {
            url += "&";
        }

        url += `${k}=${params[k]}`;

        console.log(k, i);
    });

    console.log("llstDevices URL", url);

    axios({
        method: "GET",
        url: url,
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "Authorization": "Bearer " + localStorage.getItem("access_token"),
        }
    }).then(resp => {
        console.log("listDevices", resp.data.devs);
        subject.next(resp.data.devs);
    }).catch(err => {
        console.log("listDevices error", err);
    });

    return subject;
}

export const updateInstallInfo = (devid, alias = null, groupID = null) => {
    console.log("updateInstallInfo");

    const subject = new Rx.Subject();

    var body = {};
    if (alias != null) {
        body["alias"] = alias;
    }

    if (groupID != null) {
        body["group_id"] = groupID;
    }

    const url = `${BASE_URL}/rest/v3/device/${devid}/install`;
    console.log(body, url);

    axios({
        method: "PUT",
        url: url,
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "Authorization": "Bearer " + localStorage.getItem("access_token"),
        },
        data: body,
    }).then(resp => {
        console.log("updateInstallInfo", resp);
        subject.next(resp.data);
    }).catch(err => {
        console.log("updateInstallInfo", err);
        subject.error(err);
    });

    return subject;
}

export {
    InstallStatus,
    rxDevices,
    syncDeviceList,
    stopDeviceSync,
    loadDeviceDetails,
    deviceResetAlarm,
    alarmList,
    detailDevice,
};