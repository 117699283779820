import React from 'react';
import axios from 'axios';
import '../css/LoRaData.css';
import inovibe from "./ino-vibe.js";
import { formatDateString } from "../utils/dateutils";


const LoRaData = () => {
    var roles = [];
    if (localStorage.getItem('authorization') != null) {
        roles = JSON.parse(localStorage.getItem('authorization'))['roles'];
    }

    return (
        roles.includes("admin") ? (
            <LoRaDataTable></LoRaDataTable>
        ) : (
            <span>No Permission</span>
        )
    );
}

class LoRaDataTable extends React.Component {
    LIST_PER_PAGE = 50;

    constructor(props) {
        super(props);

        this.state = {
            loadPage: 0,
            loraData: [],
            searchKey: null,
            searchDevid: null
        }
    }

    componentDidMount() {
        this.requestData(0, this.LIST_PER_PAGE);
    }

    componentWillUnmount() {
        this.source.cancel("LoRaData: Request canceled");
    }

    requestData(page, pageSize, deviceId = null) {
        this.source = axios.CancelToken.source();

        var url = "https://apis.prod.ino-vibe.ino-on.net/v3/data/";
        url += "?page=" + page + "&page_size=" + pageSize;
        if (deviceId !== null && deviceId !== "") {
            url += "&devid=" + deviceId;
        }

        console.log("RequestData", page, pageSize, deviceId, url);

        axios({
            method: "GET",
            url: url,
            headers: {
                "accept": "application/json;charset=utf-8",
                "Content-Type": "application/json;charset=utf-8",
                "authorization": "Bearer " + localStorage.getItem("access_token")
            },
            cancelToken: this.source.token,
        }).then(response => {
            console.log("Resp", response.data);

            var loraData = this.state.loraData;

            response.data.data.forEach((data, i) => {
                loraData.push({
                    devid: data.devid,
                    create: data.create,
                    receive: data.receive,
                    data: data.data,
                    desc: data.desc
                });
            });

            this.setState({
                loadPage: page,
                loraData: loraData,
            });
        }).catch(error => {
            console.log("Error", error);
        });
    }

    loadButtonPushed(event) {
        this.requestData(this.state.loadPage + 1, this.LIST_PER_PAGE, this.state.searchDevid);
    }

    searchInputChanged(event) {
        console.log("Search Value", event.target.value);
        this.setState({
            searchKey: event.target.value
        });
    }

    searchInputKeyPressed(event) {
        if (event.charCode === 13) {
            console.log('Enter Search', this.state.searchKey);

            if (this.state.searchKey == null || this.state.searchKey.length === 0) {
                this.setState({
                    loraData: []
                });
                this.requestData(0, this.LIST_PER_PAGE, null);
            } else if (this.state.searchKey.length < 6) {
                alert("At least 6 digits are required");
            } else {
                inovibe.searchDevice(this.state.searchKey,
                    (status, data) => {
                        console.log("Success receive");
                        if (data.length === 0) {
                            alert("No valid devices");
                        } else {
                            console.log(data[0]);
                            this.setState({
                                loraData: [],
                                searchDevid: data[0].devid
                            });
                            this.requestData(0, this.LIST_PER_PAGE, this.state.searchDevid);
                        }
                    },
                    (status) => {
                    }
                );
            }
        }
    }

    render() {
        return (
            <div className={"lora_data_container"}>
                <div className={"lora_data_container__search"}>
                    <input type="text" placeholder="Enter last 6 digits"
                        onChange={(e) => this.searchInputChanged(e)}
                        onKeyPress={(e) => this.searchInputKeyPressed(e)} />
                    <a className={"lora_data__reload"} onClick={() => {
                        this.setState({
                            loraData: []
                        });
                        this.requestData(0, this.LIST_PER_PAGE, null);
                    }}>Reload</a>
                </div>
                <div className={"lora_data_container__list--header"}>
                    <div className={"lora_data_container__list__id"}>ID</div>
                    <div className={"lora_data_container__list__create"}>Date</div>
                    <div className={"lora_data_container__list__desc"}>Packet</div>
                </div>
                <div className={"lora_data_container__list__wrapper"}>
                    <div className={"lora_data_container__list"}>
                        {
                            this.state.loraData.map((data, i) => {
                                var date = new Date(data.create);
                                return (
                                    <div key={i} className={"lora_data_container__list--list"}>
                                        <div className={"lora_data_container__list__id"}>
                                            {data.devid.substring(data.devid.length - 6)}
                                        </div>
                                        <div className={"lora_data_container__list__create"}>
                                            {formatDateString(date)}
                                        </div>
                                        <div className={"lora_data_container__list__desc"}>
                                            {
                                                data.desc.split("\n").map((token, i) => {
                                                    return (<div key={i}>{token}</div>);
                                                })
                                            }
                                            <div className={"lora_data_container__list__desc--raw"}>
                                                RAW | {data.data}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>

                    <div>
                        <button className={"lora_data_container__load"}
                            onClick={(e) => this.loadButtonPushed(e)}>More</button>
                    </div>
                </div>
            </div>
        );
    }
}


export default LoRaData;