import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { logEvent } from "../event";

import WaveChart from "./WaveChart";
import CircleLoading from "../components/CircleLoading";
import { alarmList } from '../InoVibe/device';
import { formatDateString } from "../utils/dateutils";

import '../css/AlarmList.css';


const AlarmList = ({ device }) => {

    const DEFAULT_ALARM_COUNT = 10;

    const [alarms, setAlarms] = useState(null);
    const [selectIndex, setSelectIndex] = useState(null);
    const [offset, setOffset] = useState(0);

    const { t } = useTranslation();

    const requestDeviceAlarms = (devid, offset, count) => {
        console.log("requestDeviceAlarms", devid, offset, count);

        setAlarms(null);
        setSelectIndex(null);

        alarmList(devid, offset, count, (resp) => {
            var alarms = [];
            resp.data.alarms.forEach((alarm) => {
                alarms.push({
                    devid: alarm.devid,
                    issued: new Date(alarm.issued),
                    type: alarm.type,
                    waveid: alarm.waveid,
                });
            });
            console.log("requestDeviceAlarms", alarms.length);

            setAlarms(alarms);
            setOffset(resp.data.req_offset);
        },
            (error) => {
                console.log("Alarm", error);
            });
    }

    const selectExcavation = (index) => {
        console.log("Excavation selected ", index);
        console.log("WaveID ", alarms[index].waveid);

        if (selectIndex === index) {
            setSelectIndex(null);
        } else {
            setSelectIndex(index);
        }
    }

    useEffect(() => {
        logEvent("Page-AlarmList");
        requestDeviceAlarms(device.devid, 0, DEFAULT_ALARM_COUNT);
    }, []);

    if (alarms == null) {
        return (
            <div className={"alarmlist_container"}>
                <CircleLoading color={"white"} hintColor={"#35404d"} size={"25px"} strokeWidth={"3px"}></CircleLoading>
            </div>
        );
    } else {
        return (
            <div className={"alarmlist_container"}>
                {
                    alarms.length === 0 ? (
                        <div className={"alarmlist_no_content"}><p>{ t("devalarm-no-content", "No Content") }</p></div>
                    ) : (
                        <div>
                            {
                                alarms.map((alarm, i) => {
                                    var isSelected = selectIndex === i;
                                    return (<div key={i} className={"alarmlist__row"}>
                                        <div className={isSelected ? "alarmlist__row__info--selected" : "alarmlist__row__info"}
                                            onClick={e => selectExcavation(i)}>
                                            <div>{formatDateString(alarm.issued)}</div>
                                            <div>{ t(alarm.type, alarm.type) }</div>
                                            {
                                                alarm.type === "excavation" ? (
                                                    <ExpandButton selected={isSelected} hidden={false} />
                                                ) : (
                                                    <ExpandButton selected={isSelected} hidden={true} />
                                                )
                                            }
                                        </div>
                                        {
                                            (alarm.type === "excavation") && (selectIndex === i) ? (
                                                <div className={"alarmlist__row__wave"}>
                                                    <WaveChart waveid={alarm.waveid} showTitle={false}></WaveChart>
                                                </div>
                                            ) : null
                                        }
                                    </div>);
                                })
                            }
                        </div>
                    )
                }
                <div className={"alarmlist__page_control"}>
                    {
                        offset === 0 ? (
                            <div>&nbsp;</div>
                        ) : (
                            <div onClick={e => requestDeviceAlarms(device.devid, offset - DEFAULT_ALARM_COUNT, DEFAULT_ALARM_COUNT)}>
                                { t("previous", "prev")}
                            </div>
                        )
                    }
                    {
                        alarms.length < DEFAULT_ALARM_COUNT ? (
                            <div>&nbsp;</div>
                        ) : (
                            <div onClick={e => requestDeviceAlarms(device.devid, offset + DEFAULT_ALARM_COUNT, DEFAULT_ALARM_COUNT)}>
                                { t("next", "next") }
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }

}

const ExpandButton = ({ selected, hidden }) => {
    return (
        <div className={selected ? "expandbutton_container--selected" : "expandbutton_container"}>
            <svg viewBox="0 0 100 100" visibility={hidden ? "hidden" : "visible"}>
                <path d="M25 33 L50 66 L75 33" stroke="white" strokeWidth="6" fill="none" />
            </svg>
        </div>
    );
}

export default AlarmList;