import ReactDOM from 'react-dom';

import { Auth0Provider } from "@auth0/auth0-react";
import registerServiceWorker from './registerServiceWorker';
import { makeMainRoutes } from './routes';

import "./i18n";
import './css/index.css';

const routes = makeMainRoutes();

ReactDOM.render(
    <Auth0Provider
        domain={"ino-vibe.auth0.com"}
        clientId={"s1vySCWnNYsAGwESTL2bMiv6Q7pWE6Ii"}
        redirectUri={window.location.origin}
        audience={"https://api.prod.ino-vibe.ino-on.net/web/"}>
        { routes }
    </Auth0Provider>
    , document.getElementById('root'));

registerServiceWorker();