import React from 'react';
import { Route } from 'react-router-dom';
import Header from '../components/Header';
import NaverMapMonitor from './NaverMapMonitor';
import Report from "./Report";
import Admin from "./Admin";

import '../css/Main.css';


const Main = ({ auth }) => {

    return (
        <div className="main__container">
            <div className={"main__header"}>
                <Header auth={auth} />
            </div>
            <Route path='/report' render={() => <Report auth={auth}></Report>} />
            <Route path='/map' render={() => <NaverMapMonitor auth={auth}></NaverMapMonitor>} />
            <Route path='/admin' render={() => <Admin auth={auth}></Admin>} />
        </div>
    );

}

export default Main;
