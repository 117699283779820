export const weekNumber = (date) => {
    var firstDateOfYear = new Date(date.getFullYear(), 0, 1);
    var dateDiff = (date - firstDateOfYear) / 86400000 + 1;
    var weeks = Math.ceil((dateDiff + firstDateOfYear.getDay()) / 7);

    return weeks;
}

export const weekRange = (year, weekNo) => {
    var firstDateOfYear = new Date(year, 0, 1);
    var dayOfFirstDate = firstDateOfYear.getDay();

    var dateDiff = firstDateOfYear.getTime() + ((weekNo-1) * 7 * 86400000) - (dayOfFirstDate * 86400000);
    var firstDateOfWeek = new Date(dateDiff);
    var lastDateOfWeek = new Date(firstDateOfWeek.getTime() + (6 * 86400000));

    if(firstDateOfWeek.getFullYear() !== year) {
        firstDateOfWeek = firstDateOfYear;
    }

    if(lastDateOfWeek.getFullYear() !== year) {
        lastDateOfWeek = new Date(year, 11, 31);
    }

    return [firstDateOfWeek, lastDateOfWeek];
}

export const formatDateString = (date) => {
    if (date == null) {
        return "--";
    }

    var localeDate = date.toLocaleDateString();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();

    var localeTime = "";
    if (hours < 10) {
        localeTime += "0";
    }
    localeTime += hours + ":";

    if (minutes < 10) {
        localeTime += "0";
    }
    localeTime += minutes + ":";

    if (seconds < 10) {
        localeTime += "0";
    }
    localeTime += seconds;

    return localeDate + " " + localeTime;
}