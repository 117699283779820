import React, { Component } from 'react';
import { Chart } from 'react-google-charts';
import axios from 'axios';
import '../css/Wave.css';
import inovibe from './ino-vibe.js';
import { createStore } from "redux";
import { formatDateString } from "../utils/dateutils";


const reduxHandler = (state = { page: 0 }, action) => {
    console.log("Dispatch", action.type);
    switch (action.type) {
        case "SELECT_WAVE":
            return Object.assign({}, state, { waveId: action.waveId, devId: action.devId });
        default:
            return state

    }
}

const eventStore = createStore(reduxHandler);

class Wave extends Component {
    render() {
        var roles = [];
        if (localStorage.getItem('authorization') != null) {
            roles = JSON.parse(localStorage.getItem('authorization'))['roles'];
        }

        return (
            <div className="wave_container">
                {
                    roles.includes("admin") ? (
                        <WaveList />
                    ) : (
                            <span>No Permission</span>
                        )
                }
            </div>
        );
    }
}

class WaveList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            waveData: [],
            page: 0,
            searchKey: null,
            searchDevid: null,
            loading: true,
        };

    }

    componentDidMount() {
        this.requestWave(0, this.state.searchDevid, (wave) => {
            var prevWaves = this.state.waveData;
            wave.forEach(wave => {
                prevWaves.push(wave);
            });

            this.setState({
                waveData: prevWaves
            });
        });
    }

    componentWillUnmount() {
        this.source.cancel("WaveList: Request canceled");
    }

    requestWave(page, devid, callback) {
        this.source = axios.CancelToken.source();

        var url = "https://apis.prod.ino-vibe.ino-on.net/v3/wave/";
        url += "?page=" + page;
        url += "&page_size=" + 50;

        if (devid !== null && devid !== "") {
            url += "&devid=" + devid;
        }

        axios({
            method: "GET",
            url: url,
            headers: {
                "accept": "application/json;charset=utf-8",
                "Content-Type": "application/json;charset=utf-8",
                "authorization": "Bearer " + localStorage.getItem("access_token")
            },
            cancelToken: this.source.token,
        }).then(
            response => {
                callback(response.data.data);
                this.setState({ page: page });
            }
        ).catch(
            error => {
                console.log(error);
            }
        );
    }

    searchEnterPress(event) {
        if (event.charCode === 13) {
            console.log('Enter Search', this.state.searchKey);

            if (this.state.searchKey == null || this.state.searchKey.length === 0) {
                this.clearSearch();
            } else if (this.state.searchKey.length < 6) {
                alert("At least 6 digits are required");
            } else {
                this.searchWave(this.state.searchKey)
            }
        }
    }

    searchWave(devid) {
        this.setState({ waveData: [] });

        inovibe.searchDevice(devid,
            (status, data) => {
                if (data.length === 0) {
                    alert("No valid devices");
                } else {
                    var device = data[0];
                    console.log(device.devid);
                    this.requestWave(0, device.devid, (wave) => {
                        var prevWave = [];
                        wave.forEach(wave => {
                            prevWave.push(wave);
                        });

                        this.setState({
                            waveData: prevWave,
                            searchDevid: device.devid
                        });
                    });
                }
            },
            (status) => {
                console.log("receive fail");
            }
        );

    }

    clearSearch() {
        console.log("Clear Search");
        this.setState({
            searchDevid: null,
            searchKey: null,
            waveData: []
        });

        this.requestWave(0, null, (wave) => {
            var prevWaves = this.state.waveData;
            wave.forEach(wave => {
                prevWaves.push(wave);
            });

            this.setState({
                waveData: prevWaves
            });
        });
    }

    updateDeviceSearchInput(event) {
        console.log("Update");
        this.setState({
            'searchKey': event.target.value
        });
    }

    loadButtonPushed(event) {
        console.log("Load more")
        this.requestWave(this.state.page + 1, this.state.searchDevid, (wave) => {
            var prevWaves = this.state.waveData;
            wave.forEach(wave => {
                prevWaves.push(wave);
            });

            this.setState({
                waveData: prevWaves
            });
        });
    }

    render() {
        return (
            <div className="wave-list">
                <div className="wave-list__wrapper">
                    <div className={"wave-list__search"}>
                        <input type="text" placeholder="Enter last 6 digits"
                            onChange={(e) => this.updateDeviceSearchInput(e)}
                            onKeyPress={(e) => this.searchEnterPress(e)} />
                        <a className={"wave-list__reload"} onClick={() => {
                            console.log("Reload");
                            this.setState({
                                waveData: [],
                            })

                            this.requestWave(0, this.state.searchDevid, (wave)=> {
                                this.setState({
                                    waveData: wave
                                });
                            });
                        }}>Reload</a>
                    </div>
                    <WaveColumn />
                    {
                        this.state.waveData.map((wave) => {
                            return (<WaveItem
                                key={wave.wave_id}
                                waveId={wave.wave_id}
                                deviceId={wave.devid}
                                datetime={wave.create}
                                groupName={wave.group_name}
                                isDone={wave.is_done}
                                predict={wave.predict != null ? wave.predict : '--'}
                                predictTest={wave['predict-test'] != null ? wave['predict-test'] : '--'}
                                notify={wave['notify'] != null ? wave['notify'].toString() : '--'} />);
                        })
                    }
                    <div className="wave-list__container__loading">
                        <button className="wave-list__container__load"
                            onClick={(e) => this.loadButtonPushed(e)}>More</button>
                    </div>
                </div>
                <WaveDetailPanel />
            </div>
        );
    }
}

class WaveColumn extends Component {
    render() {
        return (
            <div className="wave-header">
                <p className="wave-header__column wave-list__column--device">Device</p>
                <p className="wave-header__column wave-list__column--time">Time</p>
                <p className="wave-header__column wave-list__column--time">Group</p>
                <p className="wave-header__column wave-list__column--complete">Finish</p>
                <p className="wave-header__column wave-list__column--prediction">Prediction</p>
                <p className="wave-header__column wave-list__column--notify">Notify</p>
            </div>
        );
    }
}

class WaveItem extends Component {
    constructor() {
        super();
        this.state = {
            selected: false
        }

        this.unsubscribe = null;
    }

    componentDidMount() {
        this.unsubscribe = eventStore.subscribe(() => {
            let waveId = eventStore.getState()['waveId'];
            if (waveId !== this.props.waveId) {
                this.setState({ selected: false });
            }
        });
    }

    componentWillUnmount() {
        this.unsubscribe();
    }

    openWaveDetailPanel(waveId, devId) {
        eventStore.dispatch({ type: "SELECT_WAVE", waveId: waveId, devId: devId });
    }

    selectItem() {
        this.setState({ selected: true });
        this.openWaveDetailPanel(this.props.waveId, this.props.deviceId);
    }

    render() {
        let createDate = new Date(this.props.datetime);
        let className = this.state.selected ? "wave-list__item--selected" : "wave-list__item--deselected";
        return (
            <div className={className}
                onClick={() => this.selectItem()}>
                <p className="wave-list__column wave-list__column--device">{this.props.deviceId.substring(this.props.deviceId.length - 6)}</p>
                <p className="wave-list__column wave-list__column--time">{formatDateString(createDate)}</p>
                <p className="wave-list__column wave-list__column--time">{this.props.groupName}</p>
                <p className="wave-list__column wave-list__column--complete">{this.props.isDone.toString()}</p>
                <p className="wave-list__column wave-list__column--prediction">{this.props.predictTest}</p>
                <p className="wave-list__column wave-list__column--notify">{this.props.notify}</p>
            </div>
        );
    }
}

class WaveDetailPanel extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            columns: [
                {
                    type: 'number',
                    label: 'Time',
                },
                {
                    type: 'number',
                    label: 'x',
                },
                {
                    type: 'number',
                    label: 'y',
                },
                {
                    type: 'number',
                    label: 'z',
                },
            ],
            rows: [
                [0, 0, 0, 0],
            ],
            options: {
                hAxis: { title: 't' },
                vAxis: { title: 'G', minValue: -2, maxValue: 2 },
                legend: 'bottom',
                annotations: {
                    style: 'line',
                }

            },
            stats: {
                min: {x: NaN, y: NaN, z: NaN},
                max: {x: NaN, y: NaN, z: NaN},
                avg: {x: NaN, y: NaN, z: NaN},
                std: {x: NaN, y: NaN, z: NaN},
                rms: {x: NaN, y: NaN, z: NaN},
                partialRms: [],
            },
            show: props.isShow
        }

    }

    componentDidMount() {
        this.unsubscribe = eventStore.subscribe(() => {
            console.log("Subscribe called", eventStore.getState()['waveId']);
            let devId = eventStore.getState()['devId'];
            axios({
                method: "GET",
                url: "https://apis.prod.ino-vibe.ino-on.net/v3/device/?search_devid=" + devId,
                headers: {
                    "accept": "application/json;charset=utf-8",
                    "Content-Type": "application/json;charset=utf-8",
                    "authorization": "Bearer " + localStorage.getItem("access_token")
                }
            }).then(response => {
                console.log(response);
                let waveId = eventStore.getState()['waveId'];
                let dev = response.data.data[0];
                this.openWaveChart(waveId, dev);
            }).catch(error => {
                console.log(error);
            })
        });

    }

    componentWillUnmount() {
        this.unsubscribe();
    }

    openWaveChart(waveId, dev) {
        console.log('Request open wave chart', dev);
        var url = "https://apis.prod.ino-vibe.ino-on.net/v3/wave/";
        url += waveId;

        axios({
            method: "GET",
            url: url,
            headers: {
                "accept": "application/json;charset=utf-8",
                "Content-Type": "application/json;charset=utf-8",
                "authorization": "Bearer " + localStorage.getItem("access_token")
            }
        }).then(
            response => {
                console.log("Receive Wave detail", response.data);

                var resolution = response.data["resolution"] / 1000;

                var x = response.data.x.map((v) => (v === null) ? null : v * resolution);
                var y = response.data.y.map((v) => (v === null) ? null : v * resolution);
                var z = response.data.z.map((v) => (v === null) ? null : v * resolution);

                var length = Math.max(x.length, y.length, z.length);

                const rows = [];
                const hz = 1000 / response.data.interval_ms;

                for (let i = 0; i < length; i++) {
                    rows.push([i / hz, x[i], y[i], z[i]]);
                }

                var stats = {};
                stats['min'] = {};
                stats['min']['x'] = Math.min.apply(null, x);
                stats['min']['y'] = Math.min.apply(null, y);
                stats['min']['z'] = Math.min.apply(null, z);

                stats['max'] = {};
                stats['max']['x'] = Math.max.apply(null, x);
                stats['max']['y'] = Math.max.apply(null, y);
                stats['max']['z'] = Math.max.apply(null, z);

                stats['avg'] = {};
                stats['avg']['x'] = this.avg(x);
                stats['avg']['y'] = this.avg(y);
                stats['avg']['z'] = this.avg(z);

                stats['std'] = {};
                stats['std']['x'] = this.std(x);
                stats['std']['y'] = this.std(y);
                stats['std']['z'] = this.std(z);

                stats['rms'] = {};
                stats['rms']['x'] = this.rms(x);
                stats['rms']['y'] = this.rms(y);
                stats['rms']['z'] = this.rms(z);

                stats['partialRms'] = [];

                const SLICE_COUNT = 4;
                const POINT_PER_SLICE = length / SLICE_COUNT;
                console.log(SLICE_COUNT);
                for(var i = 0; i < SLICE_COUNT; i++) {
                    var stds = [];

                    var slice = x.slice(i * POINT_PER_SLICE, (i+1) * POINT_PER_SLICE);
                    stds.push(this.std(slice));

                    slice = y.slice(i * POINT_PER_SLICE, (i+1) * POINT_PER_SLICE);
                    stds.push(this.std(slice));

                    slice = z.slice(i * POINT_PER_SLICE, (i+1) * POINT_PER_SLICE);
                    stds.push(this.std(slice));

                    stats['partialRms'].push(stds);
                    // rows[(i+1) * sliceCount - 1][4] = this.getNumberString(sliceStd);
                }

                console.log(stats['partialRms']);

                this.setState({
                    rows: rows,
                    stats: stats,
                });
            }
        ).catch(
            error => {
                console.log("Wave Detail Failed", error);
            }
        );
    }

    avg(values) {
        var cleanValues = values.filter((v) => v != null);
        var mean = cleanValues.reduce((sum, v) => sum + v, 0) / cleanValues.length;
        return mean;
    }

    std(values) {
        var cleanValues = values.filter((v) => v != null);
        var mean = cleanValues.reduce((sum, v) => sum + v, 0) / cleanValues.length;
        var subSquareValues = cleanValues.map((v) => Math.pow(v - mean, 2));
        var subMeanSqureValues = subSquareValues.reduce((sum, v) => sum + v, 0) / subSquareValues.length;
        var stdResult = Math.sqrt(subMeanSqureValues);
        return stdResult;
    }

    rms(values) {
        var cleanValues = values.filter((v) => v != null);
        var meanSquare = cleanValues.map(v => Math.pow(v, 2));
        var rms = Math.sqrt(meanSquare.reduce((sum, v) => sum + v, 0) / cleanValues.length);
        return rms
    }

    getNumberString(number) {
        if(isFinite(number)) {
            return number.toFixed(3).toString();
        } else {
            return "--";
        }
    }

    render() {
        return (
            <div className="wave-detail__container--show">
                <Chart chartType="LineChart"
                    rows={this.state.rows}
                    columns={this.state.columns}
                    options={this.state.options}
                    graph_id="line_chart"
                    width={'60%'}
                    height={'95%'}
                    legend_toggle />
                <div className="wave-detail__statistics">
                    <div className="wave-detail__statistics__title">
                        <div className="wave-detail__statistics__column">Type</div>
                        <div className="wave-detail__statistics__column">X</div>
                        <div className="wave-detail__statistics__column">Y</div>
                        <div className="wave-detail__statistics__column">Z</div>
                    </div>
                    <div className="wave-detail__statistics__values">
                        <div className="wave-detail__statistics__column">Min</div>
                        <div className="wave-detail__statistics__column">{this.getNumberString(this.state.stats.min.x)}</div>
                        <div className="wave-detail__statistics__column">{this.getNumberString(this.state.stats.min.y)}</div>
                        <div className="wave-detail__statistics__column">{this.getNumberString(this.state.stats.min.z)}</div>
                    </div>
                    <div className="wave-detail__statistics__values">
                        <div className="wave-detail__statistics__column">Max</div>
                        <div className="wave-detail__statistics__column">{this.getNumberString(this.state.stats.max.x)}</div>
                        <div className="wave-detail__statistics__column">{this.getNumberString(this.state.stats.max.y)}</div>
                        <div className="wave-detail__statistics__column">{this.getNumberString(this.state.stats.max.z)}</div>
                    </div>
                    <div className="wave-detail__statistics__values">
                        <div className="wave-detail__statistics__column">Avg</div>
                        <div className="wave-detail__statistics__column">{this.getNumberString(this.state.stats.avg.x)}</div>
                        <div className="wave-detail__statistics__column">{this.getNumberString(this.state.stats.avg.y)}</div>
                        <div className="wave-detail__statistics__column">{this.getNumberString(this.state.stats.avg.z)}</div>
                    </div>
                    <div className="wave-detail__statistics__values">
                        <div className="wave-detail__statistics__column">Std</div>
                        <div className="wave-detail__statistics__column">{this.getNumberString(this.state.stats.std.x)}</div>
                        <div className="wave-detail__statistics__column">{this.getNumberString(this.state.stats.std.y)}</div>
                        <div className="wave-detail__statistics__column">{this.getNumberString(this.state.stats.std.z)}</div>
                    </div>
                    <div className="wave-detail__statistics__values">
                        <div className="wave-detail__statistics__column">RMS</div>
                        <div className="wave-detail__statistics__column">{this.getNumberString(this.state.stats.rms.x)}</div>
                        <div className="wave-detail__statistics__column">{this.getNumberString(this.state.stats.rms.y)}</div>
                        <div className="wave-detail__statistics__column">{this.getNumberString(this.state.stats.rms.z)}</div>
                    </div>
                    {
                        this.state.stats.partialRms.map((rms, i) => {
                            return (
                            <div key={i} className="wave-detail__statistics__values">
                                <div className="wave-detail__statistics__column">Std #{i}</div>
                                <div className="wave-detail__statistics__column">{this.getNumberString(this.state.stats.partialRms[i][0])}</div>
                                <div className="wave-detail__statistics__column">{this.getNumberString(this.state.stats.partialRms[i][1])}</div>
                                <div className="wave-detail__statistics__column">{this.getNumberString(this.state.stats.partialRms[i][2])}</div>
                            </div>
                            );
                        })
                    }
                </div>
            </div>
        );
    }
}

export default Wave;
