import React, { useEffect, useState } from "react";
import "../css/InstallLog.css"

import { createFirestore } from "../InoVibe/firebase.js";
import { formatDateString } from "../utils/dateutils.js"

const InstallLog = () => {

    const [devices, setDevices] = useState([]);
    const [installLogs, setInstallLogs] = useState([]);
    const [selection, setSelection] = useState({device: null, installLog: null});

    useEffect(() => {

        createFirestore(db => {
            console.log("Test.contructor: DB create success");
            loadReports(db);
        }, err => {
            console.log("Test.contructor: DB create failed " + err);
        })

    }, []);

    const loadReports = (db) => {
        console.log("Test.loadReports");
        db.collection("report")
            .orderBy("timeUpdated", "desc")
            .get()
            .then(reports => {
                console.log("onSnapshot");
                var docs = [];
                reports.forEach(deviceDoc => {
                    docs.push(deviceDoc);
                });

                setDevices(docs);
            })
            .catch(err => {
                console.log("onSnapshot Error " + err);
            });
    }

    const viewDeviceList = () => {
        const selectDevice = selection.device;

        return (
            <div className={"device_report__device"}>
                <h1>Devices</h1>
                <div className={"device_report__device__list_container"}>
                    {
                        devices.map(device => {
                            const className = device === selectDevice ? "device_report_device__list_entry--select" : "device_report_device__list_entry"
                            const data = device.data()
                            const timeUpdated = new Date(data.timeUpdated.seconds * 1000);
                            return (
                                <div className={className}
                                    key={device.id} onClick={e => pushDeviceEntry(device)}>
                                    <div className={"device_report_device__list__devid"}>{device.id.substring(device.id.length - 6)}</div>
                                    <div className={"device_report_device__list__time"}>({formatDateString(timeUpdated)})</div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        );
    }

    const pushDeviceEntry = (device) => {
        console.log("Select report " + device);
        setInstallLogs([]);
        setSelection({
            device: device,
            installLog: null,
        })

        device.ref.collection("install")
            .orderBy("timeIssued", "desc")
            .limit(100)
            .onSnapshot(logs => {
                var installLogs = [];

                logs.forEach(log => {
                    var logData = log.data();
                    logData["id"] = log.id;
                    installLogs.push(logData);
                });

                installLogs.sort((a, b) => {
                    return b.timeIssued.seconds - a.timeIssued.seconds;
                });

                setInstallLogs(installLogs);
            }, err => {
                console.log("Test.selectReport: Load install collection failed " + err);
            }, () => {
            });
    }

    const viewInstallLogList = () => {
        const selectedInstallLog = selection.installLog;

        return (
            <div className={"device_report__install_list"}>
                <h1>Install List</h1>
                <div className={"device_report__install_list__container"}>
                    {
                        installLogs.map(logEntry => {
                            const timeIssued = new Date(logEntry["timeIssued"].seconds * 1000);
                            const className = (selectedInstallLog !== null && logEntry.id === selectedInstallLog.id) ? "device_report__install_list__entry--select" : "device_report__install_list__entry"
                            const key = logEntry.id;

                            return (
                                <div className={className} key={key} onClick={e => pushInstallLogEntry(key)}>
                                    <InstallResultIndicator status={logEntry["installResult"]} />
                                    <div>{timeIssued.toLocaleString()}</div>
                                </div>
                            )
                        })
                    }
                </div>

            </div>
        );
    }

    const pushInstallLogEntry = (logID) => {
        console.log("Push install log " + logID);

        installLogs.forEach(installLog => {
            if (installLog.id === logID) {
                selection.installLog = installLog;
                console.log("Log", installLog);
                setSelection({
                    device: selection.device,
                    installLog: installLog,
                });
            }
        })
    }

    const viewInstallLogDetails = () => {
        const { installLog } = selection;
        return (
            <div className={"device_report__install_details"}>
                <h1>Details</h1>
                {
                    installLog == null ? (
                        <div>No selection</div>
                    ) : (
                        <div className={"device_report__install_details__container"}>
                            <h2>Environment</h2>
                            <div className={"device_report__install_details__environ"}>
                                <div>Result</div><div>{installLog["installResult"]}</div>
                            </div>
                            <div className={"device_report__install_details__environ"}>
                                <div>App Ver</div><div>{installLog["appVersion"]}</div>
                            </div>
                            <div className={"device_report__install_details__environ"}>
                                <div>Build Type</div><div>{installLog["buildType"]}</div>
                            </div>
                            <div className={"device_report__install_details__environ"}>
                                <div>OS</div><div>{installLog["mobileOS"]}</div>
                            </div>
                            <div className={"device_report__install_details__environ"}>
                                <div>Manufacturer</div><div>{installLog["mobileManufacturer"]}</div>
                            </div>
                            <div className={"device_report__install_details__environ"}>
                                <div>Mobile Model</div><div>{installLog["mobileModelName"]}</div>
                            </div>
                            <div className={"device_report__install_details__environ"}>
                                <div>User</div><div>{installLog["userID"]}</div>
                            </div>

                            {/* List Logs */}
                            <h2>Logs</h2>
                            <div className={"device_report__install_details__log"}>
                                <div className={"device_report__install_details__log__title"}>
                                    <div>Time</div>
                                    <div>Tag</div>
                                    <div>Msg</div>
                                </div>
                                {
                                    installLog["installLogs"].map((log, i) => {
                                        const logTokens = log.split(", ");
                                        return (
                                            <div key={i} className={"device_report__install_details__log__log"}>
                                                <div>{logTokens[0]}</div>
                                                <div>{logTokens[1]}</div>
                                                <div>{logTokens[2]}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }

    return (
        <div className={"device_report__container"}>
            {viewDeviceList()}
            {viewInstallLogList()}
            {viewInstallLogDetails()}
        </div>
    );

}

const InstallResultIndicator = ({ status }) => {
    var className = "install_result_indicator__container ";
    if (status === "Success") {
        className += "install_result_indicator--success";
    } else if (status === "Fail") {
        className += "install_result_indicator--fail";
    } else {
        className += "install_result_indicator--cancel";
    }

    return (
        <div className={className}></div>
    );
}

export default InstallLog;