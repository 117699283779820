import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import symbol from "./images/bi_symbol.svg";
import logo from "./images/bi_logo.svg";
import './Unauthorize.css';


const Unauthorize = () => {
    const { loginWithRedirect } = useAuth0();

    return (
        <div className="unauthorize">
            <object aria-label="bi-symbol" type="image/svg+xml" data={symbol}></object>
            <object aria-label="bi-logo" type="image/svg+xml" data={logo}></object>
            <div onClick={loginWithRedirect}><p>Login</p></div>
        </div>
    );
}

export default Unauthorize;