import React, { useEffect, useState } from "react";

import { useTranslation } from 'react-i18next';
import CircleLoading from "../components/CircleLoading";
import { Chart } from 'react-google-charts'
import { TabControl, TabItem } from "./TabControl";

import { createFirestore } from "../InoVibe/firebase";
import { getGroup } from "../InoVibe/group";

import "../css/InoVibeGDetailView.css"


const itemsPerPage = 10;

const accItems = (db, deviceID, limit) => {
    var q = db
        .collection(`testbed-device/${deviceID}/acc`)
        .orderBy("time_created", "desc");
    if (limit != null) {
        q = q.limit(limit);
    }
    return q;
}

const tensions = (db, deviceID) => {
    return db 
        .collection(`testbed-device/${deviceID}/tension`)
        .orderBy("time_created", "asc");
}

const InoVibeGDetailInfo = ({ device }) => {

    const { t } = useTranslation();

    return (
        <div className={"g_detail_container"}>
            <TabControl
                menuSelectColor={"#37404c"}
                menuBackgroundColor={"#202730"}>
                <TabItem title={ t("tab-info", "Info") } contentBackgroundColor={"#37404c"}>
                    <InoVibeGInfo device={device}></InoVibeGInfo>
                </TabItem>
                {
                    device.isEnableDisplacement ? (
                        <TabItem title={ t("tab-displacement", "Displacement") }>
                            <InoVibeGDistance device={device}></InoVibeGDistance>
                        </TabItem>
                    ) : (
                        null
                    )
                }
                <TabItem title={ t("tab-accelerometer", "Acc.") }>
                    <InoVibeGAcc device={device}></InoVibeGAcc>
                </TabItem>

            </TabControl>
        </div>
    );

}

const InoVibeGInfo = ({ device }) => {
    const [groupName, setGroupName] = useState("--");
    const { t } = useTranslation();

    useEffect(() => {
        console.log("InoVibeGInfo.useEffect()");

        if (device.groupID === "") {
            return;
        }

        getGroup(device.groupID).subscribe(g => {
            console.log("useEffect()", g);
            setGroupName(g.name);
        });

    }, [device]);

    return (
        <div className={"g_detail_info_container"}>

            <div className={"g_detail_info__row"}>
                <div className={"g_detail_info__row__title"}>{ t("devinfo-title-group") }</div>
                <div className={"g_detail_info__row__value"}>{groupName}</div>
            </div>

            <div className={"g_detail_info__row"}>
                <div className={"g_detail_info__row__title"}>{ t("devinfo-title-installer") }</div>
                <div className={"g_detail_info__row__value"}>{device.installer}</div>
            </div>

            <div className={"g_detail_info__row"}>
                <div className={"g_detail_info__row__title"}>{ t("devinfo-title-installdate") }</div>
                <div className={"g_detail_info__row__value"}>{device.timeInstalled.toLocaleString()}</div>
            </div>

            <div className={"g_detail_info__row"}>
                <div className={"g_detail_info__row__title"}>{ t("devinfo-title-update") }</div>
                <div className={"g_detail_info__row__value"}>{device.timeUpdated.toLocaleString()}</div>
            </div>

            <div className={"g_detail_info__row"}>
                <div className={"g_detail_info__row__title"}>{ t("devinfo-title-temperature", "Temperature") }</div>
                <div className={"g_detail_info__row__value"}>{device.temperature.toFixed(2)}℃</div>
            </div>

            <div className={"g_detail_info__row"}>
                <div className={"g_detail_info__row__title"}>{ t("devinfo-title-battery", "Battery") }</div>
                <div className={"g_detail_info__row__value"}>{device.battery}mV</div>
            </div>

            <div className={"g_detail_info__row"}>
                <div className={"g_detail_info__row__title"}>{ t("devinfo-title-humid", "Humid") }</div>
                <div className={"g_detail_info__row__value"}>{device.humid}%</div>
            </div>

        </div>
    );

}

const InoVibeGDistance = ({ device }) => {
    var deviceID = device == null ? "" : device.devid;
    console.log(deviceID);

    const [db, setDB] = useState(null);
    const [x, setX] = useState([]);
    const [y, setY] = useState([]);
    const [z, setZ] = useState([]);

    // Load image from Firestore.
    // TODO: Configurable range of query date required.
    useEffect(() => {
        console.log("DistanceChart.useEffect");

        if (db == null) {
            createFirestore(db => {
                setDB(db);
            })
            return;
        }

        db.collection(`testbed-device/${deviceID}/image`)
            .orderBy("time_created", "desc")
            .get()
            .then(q => {
                console.log("DistanceChart query");

                var xChartData = [];
                xChartData.push([{ type: "date", label: "D" }, "X", "Temperature"]);

                var yChartData = [];
                yChartData.push([{ type: "date", label: "D" }, "Y", "Temperature"]);

                var zChartData = [];
                zChartData.push([{ type: "date", label: "D" }, "Z", "Temperature"]);

                q.forEach(doc => {
                    var x, y, z;

                    const docData = doc.data();
                    if (docData.x != null) {
                        x = docData.x;
                        xChartData.push([docData.time_created.toDate(), x, docData.temperature]);
                    }
                    if (docData.y != null) {
                        y = docData.y;
                        yChartData.push([docData.time_created.toDate(), y, docData.temperature]);
                    }
                    if (docData.z != null) {
                        z = docData.z;
                        zChartData.push([docData.time_created.toDate(), z, docData.temperature]);
                    }

                });

                setX(xChartData);
                setY(yChartData);
                setZ(zChartData);
            })
            .catch(e => {
                console.log("e");
            });
    }, [db]);

    const chartOptions = {
        series: {
            0: { color: "#48cdd8", lineWidth: 1.5, targetAxisIndex: 0, title: "mm" },
            1: { color: "orange", lineWidth: 1.5, targetAxisIndex: 1 },
        },
        vAxes: {
            0: {
                title: "mm",
                titleTextStyle: { color: "#FFFFFF" },
                textStyle: { color: "#FFFFFF" },
                gridLines: { color: "#676767" },
                minorGridlines: { color: "#37404c" },
            },
            1: {
                title: "Temperature",
                titleTextStyle: { color: "#FFFFFF" },
                textStyle: { color: "#FFFFFF" },
                gridLines: { color: "none" },
                minorGridlines: { color: "none" },
                viewWindow: { min: -20, max: 50 },
            }
        },
        hAxis: {
            title: "Time",
            titleTextStyle: { color: "#FFFFFF" },
            gridLines: {color: "#676767" },
            minorGridlines: { color: "#37404c" },
        },
        backgroundColor: { fill: "transparent" },
        chartArea: {
            width: "80%",
            height: "75%",
        },
        legend: { position: "top", textStyle: { color: "white" }},
        pointSize: 2,
    };

    return (
        <div className={"distance_container"}>
            <div className={"distance__chart"}>
                <p>X축 거리</p>
                {
                    x.length > 1 ? (
                        <Chart
                            chartType={"LineChart"}
                            loader={<div>Loading</div>}
                            options={chartOptions}
                            data={x} />
                    ) : (
                        <div>변위 데이터가 없습니다.</div>
                    )
                }
            </div>

            <div className={"distance__chart"}>
                <p>Y축 거리</p>
                {
                    y.length > 1 ? (
                        <Chart
                            chartType={"LineChart"}
                            loader={<div>Loading</div>}
                            options={chartOptions}
                            data={y} />
                    ) : (
                        <div>변위 데이터가 없습니다.</div>
                    )
                }
            </div>

            <div className={"distance__chart"}>
                <p>Z축 거리</p>
                {
                    z.length > 1 ? (
                        <Chart
                            chartType={"LineChart"}
                            loader={<div>Loading</div>}
                            options={chartOptions}
                            data={z} />
                    ) : (
                        <div>변위 데이터가 없습니다.</div>
                    )
                }
            </div>
        </div>
    );

}

const InoVibeGAcc = ({ device }) => {

    var deviceID = device.devid;

    const [docList, setDocList] = useState(null);
    const [refDocFirst, setRefDocFirst] = useState(null);
    const [refDocLast, setRefDocLast] = useState(null);
    const [db, setDB] = useState(null);
    const [tension, setTension] = useState([]);

    const handleAccQuery = (q) => {
        var docs = [];
        var docRefs = [];
        q.forEach(doc => {
            docRefs.push(doc);
            docs.push({ id: doc.id, data: doc.data() });
        });

        setDocList(docs);
        setRefDocFirst(docs.length > 0 ? docRefs[0] : null);
        setRefDocLast(docs.length > 0 ? docRefs[docs.length - 1] : null);
    };

    useEffect(() => {
        console.log(`Acc.useEffect ${deviceID}`);

        createFirestore(db => {
            setDB(db);
        });

        if (db == null) {
            return;
        }

        accItems(db, deviceID, itemsPerPage)
            .get()
            .then(q => {
                var docs = [];
                var docRefs = [];
                q.forEach(doc => {
                    docRefs.push(doc);
                    docs.push({ id: doc.id, data: doc.data() });
                });

                setDocList(docs);
                setRefDocFirst(docs.length > 0 ? docRefs[0] : null);
                setRefDocLast(docs.length > 0 ? docRefs[docs.length - 1] : null);
            })
            .catch(e => {
                console.log("Acc ", e);
            });

        if (device.isEnableTension) {
            tensions(db, deviceID)
                .get()
                .then(docs => {
                    console.log("Get tensions Success");

                    var t = [];
                    t.push([{ type: "date", label: "T" }, "T"])
                    docs.forEach(doc => {
                        var d = doc.data();
                        t.push([d.time_created.toDate(), d.tension]);
                    });

                    setTension(t);
                })
                .catch(e => {
                    console.log("Get tensions Failed");
                });

        }

    }, [deviceID, db, tension]);

    var listView = null;
    if (docList == null) {
        listView = (
            <div style={{
                display: "flex",
                width: "100%",
                margin: "20px 0px 20px 0px",
                justifyContent: "center",
            }}>
                <CircleLoading color={"white"} hintColor={"#35404d"} size={"25px"} strokeWidth={"3px"}></CircleLoading>
            </div>
        );
    } else {
        if (docList.length > 0) {
            var tensionView = null;
            if (device.isEnableTension) {
                tensionView = <TensionChart tension={ tension }></TensionChart>
            }

            listView = (
                <div>
                    { tensionView }
                    <AccList docList={docList} />
                </div>
            )
        } else {
            listView = <NoItem></NoItem>
        }
    }

    return (
        <div className={"g_acc_container"}>
            { listView }

            {
                docList != null && docList.length > 0 ? (
                    <div className={"g_acc__lists__page"}>
                        <div onClick={() => {
                            setDocList(null);
                            accItems(db, deviceID, itemsPerPage)
                                .endBefore(refDocFirst)
                                .get()
                                .then(q => {
                                    handleAccQuery(q);
                                })
                                .catch(e => {
                                    console.log("Acc ", e);
                                })
                        }}>Prev.</div>

                        <div onClick={() => {
                            setDocList(null);
                            accItems(db, deviceID, itemsPerPage)
                                .startAfter(refDocLast)
                                .get()
                                .then(q => {
                                    handleAccQuery(q);
                                })
                                .catch(e => {
                                    console.log("Acc ", e);
                                })
                        }}>Next</div>
                    </div>
                ) : (
                    <div></div>
                )
            }
        </div>
    );

};

const AccList = ({ docList }) => {

    const [selectDocID, setSelectDocID] = useState(null);

    const accChartOptions = {
        vAxis: {
            title: "mG",
            titleTextStyle: { color: "#FFFFFF" },
            textStyle: { color: "#FFFFFF" },
            gridLines: { color: "#676767" },
            minorGridlines: { color: "#37404c" },
        },
        hAxis: {
            title: "Second",
            titleTextStyle: { color: "#FFFFFF" },
            gridLines: { color: "#676767" },
            minorGridlines: { color: "#37404c" },
        },
        backgroundColor: { fill: "transparent" },
        series: {
            0: { color: "#48cdd8", lineWidth: 1.5 },
        },
        chartArea: {
            width: "70%",
            height: "70%",
        },
        legend: { position: "none" },
    };

    const psdChartOptions = {
        vAxis: {
            title: "psd",
            titleTextStyle: { color: "#FFFFFF" },
            textStyle: { color: "#FFFFFF" },
            gridLines: { color: "#676767" },
            minorGridlines: { color: "#37404c" },
        },
        hAxis: {
            title: "Frequency",
            titleTextStyle: { color: "#FFFFFF" },
            gridLines: { color: "#676767" },
            minorGridlines: { color: "#37404c" },
        },
        backgroundColor: { fill: "transparent" },
        series: {
            0: { color: "#48cdd8", lineWidth: 1.5 },
        },
        chartArea: {
            width: "70%",
            height: "70%",
        },
        legend: { position: "none" },
    };

    return (<div className={"g_acc__lists"}>
        {
            docList.map(doc => {
                const data = doc.data;
                var x_psd = [["f", "x"]];
                var y_psd = [["f", "y"]];
                var z_psd = [["f", "z"]];

                if (data.psd_f != null) {
                    data.psd_f.forEach((f, i) => {
                        x_psd.push([f, data.psd_x[i]]);
                        y_psd.push([f, data.psd_y[i]]);
                        z_psd.push([f, data.psd_z[i]]);
                    });
                }

                var x_acc = [["t", "x"]];
                var y_acc = [["t", "y"]];
                var z_acc = [["t", "z"]];

                var time_tick = 1.0 / data.sample_rate;

                if (data.x != null && data.y != null && data.z != null) {
                    var acc_len = data.x.length;
                    for (var i = 0; i < acc_len; i++) {
                        x_acc.push([time_tick * i, data.x[i]]);
                        y_acc.push([time_tick * i, data.y[i]]);
                        z_acc.push([time_tick * i, data.z[i]]);
                    }
                }

                return (
                    <div className={"g_acc__entity"} key={doc.id}>
                        <div
                            className={doc.id === selectDocID ? "g_acc__entity__title--select" : "g_acc__entity__title"}
                            onClick={() => {
                                if (doc.id === selectDocID) {
                                    console.log("Clear selection");
                                    setSelectDocID(null);
                                } else {
                                    console.log("Select acc doc ", doc.id);
                                    setSelectDocID(doc.id);
                                }
                            }}>
                            <p className={"g_acc__entity__title__name"}>{data.time_created.toDate().toLocaleString()}</p>
                        </div>
                        {
                            (doc.id === selectDocID) ? (
                                <div className={"g_acc__entity__chart_container"}>
                                    <div className={"g_acc__entity__chart"}>
                                        <p>X축 가속도</p>
                                        <div>
                                            <Chart
                                                chartType={"LineChart"}
                                                loader={<div>Loading</div>}
                                                options={accChartOptions}
                                                data={x_acc} />
                                        </div>
                                    </div>

                                    <div className={"g_acc__entity__chart"}>
                                        <p>X축 PSD</p>
                                        <div>
                                            <Chart
                                                chartType={"LineChart"}
                                                loader={<div>Loading</div>}
                                                options={psdChartOptions}
                                                data={x_psd} />
                                        </div>
                                    </div>

                                    <div className={"g_acc__entity__chart"}>
                                        <p>Y축 가속도</p>
                                        <div>
                                            <Chart
                                                chartType={"LineChart"}
                                                loader={<div>Loading</div>}
                                                options={accChartOptions}
                                                data={y_acc} />
                                        </div>
                                    </div>

                                    <div className={"g_acc__entity__chart"}>
                                        <p>Y축 PSD</p>
                                        <Chart
                                            chartType={"LineChart"}
                                            loader={<div>Loading</div>}
                                            options={psdChartOptions}
                                            data={y_psd} />
                                    </div>

                                    <div className={"g_acc__entity__chart"}>
                                        <p>Z축 가속도</p>
                                        <div>
                                            <Chart
                                                chartType={"LineChart"}
                                                loader={<div>Loading</div>}
                                                options={accChartOptions}
                                                data={z_acc} />
                                        </div>
                                    </div>

                                    <div className={"g_acc__entity__chart"}>
                                        <p>Z축 PSD</p>
                                        <Chart
                                            chartType={"LineChart"}
                                            loader={<div>Loading</div>}
                                            options={psdChartOptions}
                                            data={z_psd} />
                                    </div>
                                </div>
                            ) : (
                                <div></div>
                            )
                        }
                    </div>
                );
            })
        }
    </div>);
}

const TensionChart = ({ tension }) => {

    const chartOptions = {
        vAxis: {
            title: "T",
            titleTextStyle: { color: "#FFFFFF" },
            textStyle: { color: "#FFFFFF" },
            gridLines: { color: "#676767" },
            minorGridlines: { color: "#37404c" },
        },
        hAxis: {
            title: "Time",
            titleTextStyle: { color: "#FFFFFF" },
            gridLines: { color: "#676767" },
            minorGridlines: { color: "#37404c" },
        },
        backgroundColor: { fill: "transparent" },
        series: {
            0: { color: "#48cdd8", lineWidth: 1.5 },
        },
        chartArea: {
            width: "80%",
            height: "75%",
        },
        legend: { position: "none" },
        pointSize: 2,
    };

    return (
        <div className={"acc__tension"}>
            <p>장력</p>
            <Chart
                chartType={"LineChart"}
                loader={<div>Loading</div>}
                options={chartOptions}
                data={tension} />
        </div>
    );

}

const NoItem = () => {
    return (
        <div style={{
            fontSize: "14px",
            textAlign: "center",
            margin: "20px 0px 20px 0px",
        }}>
            가속도 데이터가 없습니다.
        </div>
    );
};

export default InoVibeGDetailInfo;