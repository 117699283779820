import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import axios from 'axios';


const firebaseConfig = {
    apiKey: "AIzaSyBphjmpGD33BbPIa0HULnnlt23gfgWhNtc",
    authDomain: "crash-detector.firebaseapp.com",
    databaseURL: "https://crash-detector.firebaseio.com",
    projectId: "crash-detector",
    storageBucket: "crash-detector.appspot.com",
    messagingSenderId: "760711940122",
    appId: "1:760711940122:web:20e3707e3d8697eb"
};

firebase.initializeApp(firebaseConfig);

var db = null;

const authFirebase = (accessToken, callbackSuccess, callbackError) => {
    console.log("authFirebase");
    axios({
        method: "POST",
        url: "https://rest.ino-vibe.ino-on.dev/rest/v3/auth_firebase",
        headers: {
            "Content-Type": "Application/json; charset=UTF-8",
            "Authorization": "Bearer " + accessToken,
        }
    }).then(resp => {
        localStorage.setItem('firebase_token', resp.data);
        console.log("Success firebase auth");
        callbackSuccess();
    }).catch(err => {
        callbackError(err);
        console.log(`Error: ${err.error}. Firebase token failed.`);
    })
}

export const createFirestore = (callbackSuccess, callbackError) => {
    console.log("createFirestore");

    if (db != null) {
        callbackSuccess(db);
        return;
    }

    authFirebase(
        localStorage.getItem("access_token"),
        () => {
            console.log("authFirebase success ");
            firebase
                .auth()
                .signInWithCustomToken(localStorage.getItem("firebase_token"))
                .then(cred => {
                    console.log("sign firebase success ");
                    db = firebase.firestore();
                    callbackSuccess(db);
                })
                .catch(err => {
                    console.log("sign firebase failed " + err);
                    callbackError(err);
                });

        }, err => {
            console.log("authFirebase failed " + err);
            callbackError(err);
        });
}