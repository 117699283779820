import React, { useEffect, useState } from "react";
import {createStore} from 'redux';

import { ContextMenu, ContextMenuItem } from "../components/ContextMenu";

import { listGroups, createGroup, deleteGroup, listMember } from "../InoVibe/group";
import { listDevices, InstallStatus } from "../InoVibe/device";

import "../css/Groups.css"
import CircleLoading from "../components/CircleLoading";
import CircleMark from "../components/CircleMark";

const EVT_GROUP_CREATED = "GROUP_CREATED";
const EVT_GROUP_DELETED = "GROUP_DELETED";

const reduxHandler = (state, action) => {
    switch(action.type) {
        case EVT_GROUP_CREATED:
            console.log("reducHandler", action);
            return Object.assign({}, state, action);
        default:
            break;
    }
}

const eventStore = createStore(reduxHandler);

const Group = () => {
    const [groups, setGroups] = useState([[]]);
    const [selectedGroups, setSelectedGroups] = useState([null]);
    const [menuOption, setMenuOption] = useState({
        group_id: "",
        show: false,
        x: 0,
        y: 0,
    });

    useEffect(() => {
        listGroups().subscribe((groupResponse) => {
            groupResponse.sort((a, b) => {
                return (a.name < b.name) ? -1 : 1;
            });
            let newGroups = [groupResponse];
            setGroups(newGroups);
        });

        eventStore.subscribe(() => {
            console.log("Redux subscribe", eventStore.getState());
            listGroups().subscribe((groupResponse) => {
                groupResponse.sort((a, b) => {
                    return (a.name < b.name) ? -1 : 1;
                });
                let newGroups = [groupResponse];

                setGroups(newGroups);
            });
        });
    }, []);

    const selectGroup = (groups, depth, id) => {
        console.log(`Select Group layer ${depth} => ${id}`);
        let group = findGroup(groups[depth], id);

        var newSelectedGroups = [];
        for (var i = 0; i < depth; i++) {
            newSelectedGroups.push(selectedGroups[i]);
        }

        newSelectedGroups.push(group);
        setSelectedGroups(newSelectedGroups);

        console.log("Selection", newSelectedGroups);

        var childrenGroups = [];
        if (group.children != null) {
            childrenGroups = group.children;
        }

        childrenGroups.sort((a, b) => {
            return (a.name < b.name) ? -1 : 1;
        })

        var newGroups = [];
        for (i = 0; i < depth + 1; i++) {
            newGroups.push(groups[i].slice(0));
        }

        newGroups.push(childrenGroups);

        setGroups(newGroups);
    };

    return (
        <div className={"group_container"} onClick={() => { 
            console.log("click menu reset", menuOption);
            setMenuOption({show: false})
        }} onScroll={() => { 
            if(menuOption.show === true) {
                console.log("scroll menu reset", menuOption);
                setMenuOption({show: false})
            }
        }}>
            <ContextMenu show={menuOption.show} x={menuOption.x} y={menuOption.y}>
                <ContextMenuItem name={"Delete Group"} clickHandler={ () => {
                    console.log("Delete", menuOption.group_id);
                    if (window.confirm("Delete Group?")) {
                        deleteGroup(menuOption.group_id).subscribe(resp => {
                            console.log("Delete resp", resp);
                            eventStore.dispatch({ type: EVT_GROUP_DELETED, group_id: resp.id });
                        }, e => {
                            console.log("Delete resp", e);
                        });
                    }
                }} />
                <div className={"menu__seperator"}></div>
                <ContextMenuItem name={"Close"} clickHandler={e => {
                    console.log("Close");
                }} />
            </ContextMenu>
            <div className={"group_lane_container"}>
                {
                    groups.map((group, depth) => {
                        if (group.length > 0) {
                            return <div key={depth} className={"group__lane"}>
                                {
                                    group.map(g => {
                                        var className = (selectedGroups[depth] != null && selectedGroups[depth].id === g.id) ? "group__lane__entry--selected" : "group__lane__entry";
                                        return (
                                            <div
                                                key={g.id}
                                                className={className}
                                                onClick={() => selectGroup(groups, depth, g.id)}
                                                onContextMenu={(e) => {
                                                    console.log("onContextMenu", g.id);
                                                    e.preventDefault();
                                                    setMenuOption({
                                                        group_id: g.id,
                                                        show: true,
                                                        x: e.pageX,
                                                        y: e.pageY,
                                                    });
                                                }}>
                                                {
                                                    g.individual ? (
                                                        <CircleMark></CircleMark>
                                                    ) : (
                                                        null
                                                    )
                                                }
                                                <p className={"group__lane__entry__name"}>{g.name}</p>
                                            </div>)
                                    })
                                }
                                <div className={"group__lane__new"}>
                                    <GroupAddComponent parentGroup={selectedGroups[depth - 1]} />
                                </div>
                            </div>
                        } else {
                            return (<div key={depth} className={"group__lane--empty"}>
                                <p>하위 그룹 없음</p>
                                <div className={"group__lane__new"}>
                                    <GroupAddComponent parentGroup={selectedGroups[depth - 1]} />
                                </div>
                            </div>)
                        }
                    })
                }
            </div>
            <div className={"group_detail_container"}>
                <div className="group_detail__members">
                    <Members selectedGroups={selectedGroups} />
                </div>
                <div className="group_detail__devices">
                    <Devices selectedGroups={selectedGroups} />
                </div>
            </div>
        </div>
    );
}

const GroupAddComponent = ({ parentGroup }) => {
    const Mode = Object.freeze({
        Initial: 0,
        Input: 1,
        Confirm: 2,
        Requested: 3,
        Completed: 4,
        Failed: 5,
    });

    const [mode, setMode] = useState(Mode.Initial);
    const [newGroupName, setNewGroupName] = useState("");

    let element;
    switch (mode) {
        case Mode.Initial:
            element = <p onClick={() => setMode(Mode.Input)}>New</p>
            break;
        case Mode.Input:
            element = (
                <div className={"group_add__input"}>
                    <input type="text" placeholder="Group name?" onChange={(evt) => {
                        setNewGroupName(evt.target.value);
                    }}></input>
                    <button onClick={() => { setMode(Mode.Confirm) }}>Add</button>
                    <button onClick={() => setMode(Mode.Initial)}>Cancel</button>
                </div>
            );
            break;
        case Mode.Confirm:
            element = (
                <div className={"group_add__input"}>
                    <p>Proceed?</p>
                    <div className={"group_add__input__spacer"}></div>
                    <button onClick={() => {
                        console.log("Create new group", newGroupName, parentGroup);

                        createGroup(newGroupName, parentGroup).subscribe(resp => {
                            console.log("Create resp", resp);
                            eventStore.dispatch({type: EVT_GROUP_CREATED, group_id: resp.id});
                            setMode(Mode.Completed);
                        }, e => {
                            console.log("Create Error", e);
                            setMode(Mode.Failed);
                            alert(`${e}`);
                        });
                        setMode(Mode.Requested);
                    }}>Y</button>
                    <button onClick={() => setMode(Mode.Initial)}>N</button>
                </div>
            );
            break;
        case Mode.Requested:
            element = (
                <div className={"group_add__input"}>
                    <p>Waiting...</p>
                </div>
            );
            break;
        case Mode.Completed:
            element = (
                <div className={"group_add__input"}>
                    <p>Done</p>
                    <div className={"group_add__input__spacer"}></div>
                    <button onClick={() => setMode(Mode.Initial)}>ok</button>
                </div>
            );
            break;
        case Mode.Failed:
            element = (
                <div className={"group_add__input"}>
                    <p>Failed</p>
                    <div className={"group_add__input__spacer"}></div>
                    <button onClick={() => setMode(Mode.Initial)}>ok</button>
                </div>
            );
            break;
        default:
            element = <p>Somthings wrong</p>;
            break;
    }

    return (<div className={"group_add_container"}>{ element }</div>);
}

const findGroup = (groups, id) => {
    for (var i = 0; i < groups.length; i++) {
        if (groups[i].id === id) {
            return groups[i];
        } else if (groups[i].children != null) {
            let childFind = findGroup(groups[i].children, id);
            if (childFind != null) {
                return childFind;
            }
        }
    }

    return null;
}

const Members = ({ selectedGroups }) => {
    console.log("Members for ", selectedGroups);
    var lastSelectedGroup = selectedGroups[selectedGroups.length - 1];

    const [users, setUsers] = useState([]);
    const [loading, setLoadingState] = useState(false);

    useEffect(() => {
        if (lastSelectedGroup == null) {
            return;
        }

        setUsers([]);
        setLoadingState(true);

        listMember(lastSelectedGroup.id).subscribe((users => {
            users.forEach(u => {
                console.log(u.email);
            });

            setUsers(users);
            setLoadingState(false);
        }));
    }, [selectedGroups]);

    if (loading) {
        return (
            <div className={"member__loading"}>
                <CircleLoading color={"#151B21"} hintColor={"white"} strokeWidth={"3px"} size={"30px"} />
            </div>
        );
    }

    if (lastSelectedGroup != null) {
        return (
            <div className={"member_container"}>
                <p>Members for {lastSelectedGroup.name} </p>
                <div className="member__list">
                    {
                        users.map((u, i) => {
                            return (
                                <div key={u.id} className={"member__list__entry"}>
                                    <p>{i + 1}</p>
                                    <p>{u.email}</p>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        );
    } else {
        return (
            <div className={"member_container--empty"}><p className={"member_empty"}>그룹을 선택하세요</p></div>
        );
    }
}

const Devices = ({ selectedGroups }) => {

    const lastGroup = selectedGroups[selectedGroups.length - 1];

    const [devs, setDevs] = useState(null);

    useEffect(() => {
        if (lastGroup == null) {
            return;
        }

        console.log("Devices.useEffect()", lastGroup.id, lastGroup.name);

        listDevices(InstallStatus.Installed, lastGroup.id)
            .subscribe(devs => {
                setDevs(devs);
            }, err => {
                console.log("listDevices", err);
            });
    }, [selectedGroups]);

    var view = null;
    if (devs == null) {
        if (lastGroup == null) {
            view = <div className={"admin_group_device__msg"}><p>그룹을 선택하세요</p></div>;
        } else {
            view = (<div className={"admin_group_device__msg"}>
                <CircleLoading color={"#151B21"} hintColor={"white"} strokeWidth={"3px"} size={"30px"} />
            </div>);
        }
    } else {
        if (devs.length === 0) {
            view = <div className={"admin_group_device__msg"}><p>No devices</p></div>;
        } else {
            view = (
                <div className={"admin_group_device__list_container"} >
                    {
                        devs.map((dev, i) => {
                            return (
                                <div key={i} className={"admin_group_device__list"}>
                                    <p>{ dev.devid.toUpperCase() }</p>
                                    <p>{ dev.alias }</p>
                                    <p>{ dev.installer }</p>
                                </div>
                            );
                        })
                    }
                </div>
            );
        }
    }

    return (
        <div className={"device_container"}>
            {
                devs != null && devs.length > 0 ? (

                    <div className={"admin_group_device__header"}>
                        <p>Total</p>
                        <p>{devs.length}</p>
                    </div>
                ) : (
                    null
                )
            }
            { view}
        </div>
    );
}

export default Group;