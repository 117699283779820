import React from "react";

import "../css/TrashButton.css";

const TrashButton = ({ onClick }) => {

    return (
        <svg className={"trash_button__container"} onClick={() => {
            if (onClick != null) {
                onClick();
            }
        }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64.25 38.82">
            <g id="레이어_2" data-name="레이어 2">
                <g id="레이어_1-2" data-name="레이어 1">
                    <rect className="cls-1" width="64.25" height="38.82" rx="19.41" />
                    <rect className="cls-2" x="26" y="6.02" width="12.25" height="3.15" rx="1.27" />
                    <rect className="cls-2" x="20.7" y="11.54" width="22.86" height="3.15" rx="1.57" />
                    <rect className="cls-2" x="20.01" y="20.94" width="19.08" height="2.59" rx="1.3" transform="translate(51.78 -7.32) rotate(90)" />
                    <rect className="cls-2" x="25.04" y="20.94" width="19.08" height="2.59" rx="1.3" transform="translate(56.81 -12.35) rotate(90)" />
                    <path className="cls-2" d="M37.82,11.54H26.43a3.06,3.06,0,0,0-3.05,3.05V29.75a3.06,3.06,0,0,0,3.05,3H37.82a3.06,3.06,0,0,0,3-3V14.59A3.06,3.06,0,0,0,37.82,11.54Zm.39,15.91a2.13,2.13,0,0,1-2.12,2.12H28.16A2.13,2.13,0,0,1,26,27.45V16.9a2.14,2.14,0,0,1,2.12-2.13h7.93a2.13,2.13,0,0,1,2.12,2.13Z" />
                </g>
            </g>
        </svg>
    );

}

export default TrashButton;