import React from "react";

import { Translation } from 'react-i18next';
import { eventStore, EVT_SELECT_DEVICE } from "../App";
import { logEvent } from "../event";

import { getAlertSoundPlayConfig } from "../config.js";
import CloseButton from "./CloseButton";
import { userInboxMsgs, setMsgRead, markMsgClosed } from "../InoVibe/inbox";

import icon_excavation from "../images/icon-excavation.svg";
import icon_inclination from "../images/icon-inclination.svg";
import icon_nosignal from "../images/icon-nosignal.svg";
import "../css/NotificationList.css"

import alert_sound from "../raw/inbox_alert.wav";


class NotificationList extends React.Component {
    MAX_LIST_ENTRIES = 3;

    constructor(props) {
        super(props);

        this.state = {
            msgs: []
        };

        this.inboxSubscribe = null;
    }

    componentDidMount() {
        console.log("NotificationList: componentDidMount");

        this.inboxSubscribe = userInboxMsgs.subscribe(newMsgs => {
            if(this.state.msgs.length === 0 && newMsgs.length === 0) {
                return;
            }

            var msgList = [];
            Object.keys(newMsgs).forEach(msgID => {
                var curMsg = newMsgs[msgID];
                if(!curMsg.is_read && !curMsg.is_closed) {
                    msgList.push(curMsg);
                }
            });
            msgList.sort((a, b) => { return b.time_issued - a.time_issued });

            var msgByDevID = {};
            msgList.forEach(msg => {
                if(msgByDevID[msg.devid] == null) {
                    msgByDevID[msg.devid] = [];
                }
                msgByDevID[msg.devid].push(msg);
            });

            var sortedMergeList = [];
            Object.keys(msgByDevID).forEach(devid => {
                console.log("Device Msg ", devid);
                sortedMergeList.push(msgByDevID[devid]);
            });

            sortedMergeList.sort((a, b) => { return b[0].time_issued - a[0].time_issued });

            console.log(this.state.msgs.length, sortedMergeList.length);

            var isChanged = false;

            if(this.state.msgs.length === 0) {
                isChanged = true;
            } else if(sortedMergeList.length !== 0) {
                let originLast = this.state.msgs[0][0];
                let newLast = sortedMergeList[0][0];

                console.log(originLast.time_issued, newLast.time_issued);
                if(originLast.time_issued < newLast.time_issued) {
                    isChanged = true;
                }
            }

            if(isChanged && getAlertSoundPlayConfig()) {
                console.log("NoficiationList -> Play Alert");
                new Audio(alert_sound).play();
            }

            this.setState({msgs: sortedMergeList.slice(0, this.MAX_LIST_ENTRIES)});
        });
    }

    componentWillUnmount() {
        if(this.inboxSubscribe != null) {
            this.inboxSubscribe.unsubscribe();
        }
    }

    render() {
        console.log("NotificationList: render", this.state.msgs);

        return (
            <div className="notification_container">
            {
                this.state.msgs.map(msgs => {
                    return (
                        <NotificationItem key={msgs[0].devid} msgs={msgs}/>
                    );
                })
            }
            </div>
        );
    }
}

class NotificationItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            onClosing: false,
        }

        const { msgs } = this.props;
        this.devID = msgs[0].devid;
        this.msgIDs = [];
        msgs.forEach(msg => {
            this.msgIDs.push(msg.id);
        });

        this.animationCallback = null;
    }

    render() {
        const { msgs } = this.props;
        const type = msgs[0].msg_type;
        const alias = msgs[0].alias;
        const time_issued = msgs[0].time_issued;

        var icon = null;
        var titleStyle = {"fontWeight": "bolder"};
        var title = "";

        if(type === "no_signal") {
            icon = icon_nosignal;
            title = "no signal"
            titleStyle["color"] = "black";
        } else if (type === "inclination") {
            icon = icon_inclination;
            title = "inclination"
            titleStyle["color"] = "#EB4A26";
        } else {
            icon = icon_excavation;
            title = "excavation"
            titleStyle["color"] = "#EB4A26";
        }

        const { onClosing } = this.state;

        return (
            <div id={this.devID}
                className={"notification_item_container" + (onClosing ? " closing" : "")}
                onAnimationEnd={() => {this.onAnimationEnd()}}>
            {
                msgs.length > 1 ? (
                    <div className="notification_item__stack_count">
                        { msgs.length > 9 ? <p>9+</p> : <p>{msgs.length}</p> }
                    </div>
                ) : ( null )
            }
                <div className="notification_item__close">
                    <CloseButton onClick={() => this.startTransition(this.closeNotificationItem, false)}/>
                </div>
                <div className="notification_item__msg_container" onClick={() => this.startTransition(this.readNotificationItem, true)}>
                    <div className="notification_item__icon"><img alt="icon" src={icon}/></div>
                    <div className="notification_item__msg">
                        <Translation>
                            {
                                (t) => <p style={titleStyle}>{ t(title, title) } { t("alarm", "alarm") }</p>
                            }
                        </Translation>
                        <p>{time_issued.toLocaleString("default", {dateStyle: "full", timeStyle: "medium", hour12: false})}</p>
                        <p>{this.devID.substring(this.devID.length - 6).toUpperCase()} ・ {alias}</p>
                    </div>
                </div>
            </div>
        );
    }

    startTransition(finishCallback, focusDevice) {
        if(focusDevice) {
            eventStore.dispatch({type: EVT_SELECT_DEVICE, devid: this.devID});
        }

        this.setState({ onClosing: true });
        this.animationCallback = finishCallback;
    }

    onAnimationEnd() {
        if(this.animationCallback != null) {
            this.animationCallback();
        }
    }

    readNotificationItem() {
        console.log("NotificationItem: click item");
        setMsgRead(this.msgIDs);
        logEvent("notification-click");
    }

    closeNotificationItem() {
        console.log("NotificationItem: close item");
        markMsgClosed(this.msgIDs);
        logEvent("notification-close");
    }
}

export default NotificationList;