import React from 'react';
import { useTranslation } from 'react-i18next';
import { isAdmin } from '../InoVibe/user';
import { useAuth0 } from "@auth0/auth0-react";

import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Inbox from "./Inbox";
import { getAlertSoundPlayConfig, setAlertSoundPlayConfig } from "../config.js";

import { logEvent } from "../event";
import { eventStore, EVT_HIDE_INBOX_MODAL, EVT_NEW_INBOX_MSG, EVT_READ_ALL_INBOX_MSG } from "../App";
import { initializeInbox } from "../InoVibe/inbox";

import '../css/Header.css';
import launcher from '../images/launcher.png';
import icon_inbox from '../images/icon-inbox.svg';
import icon_inbox_new from '../images/icon-inbox-new.svg';
import icon_report from '../images/icon-web-report.svg';
import icon_map from '../images/icon-map.svg';

import speaker_on from "../images/web-icon-sound-on.svg";
import speaker_off from "../images/web-icon-sound-off.svg";


const Header = () => {

    const [hasNewMsg, setHasNewMsg] = useState(false);
    const [showInbox, setShowInbox] = useState(false);
    const [playAlertSound, setPlayAlertSound] = useState(getAlertSoundPlayConfig());
    const { user, logout, isAuthenticated } = useAuth0();

    const { t } = useTranslation();

    useEffect(() => {
        logEvent("Page-Header");

        initializeInbox();

        eventStore.subscribe(() => {
            const type = eventStore.getState()["type"];
            console.log("Header receive...", type);

            if (type === EVT_HIDE_INBOX_MODAL) {
                console.log("HIDE...");
                setShowInbox(false);
            } else if (type === EVT_NEW_INBOX_MSG) {
                console.log("New message!!");
                setHasNewMsg(true);
            } else if (type === EVT_READ_ALL_INBOX_MSG) {
                setHasNewMsg(false);
            }
        })

        console.log("user", user);

    }, []);

    const version = "" + process.env.REACT_APP_VERSION;

    return (
        <header className="header_container">
            <div className={"header__launcher"} onClick={e => this.onClickRefresh(e)}>
                <NavLink exact to="/map" className="header__launcher_link">
                    <img alt="launcher" src={launcher}></img>
                </NavLink>
            </div>
            <div>
                <div className={"header__menu_item_container"}>
                    <NavLink exact to="/map" className={"header__menu_link"}>
                        <div>
                            <img alt="menu_map" src={icon_map}></img>
                        </div>
                    </NavLink>
                </div>

                <div className={"header__menu_item_container"}>
                    <NavLink exact to="/report" className={"header__menu_link"}>
                        <div>
                            <img alt="menu_report" src={icon_report}></img>
                        </div>
                    </NavLink>
                </div>

                {
                    isAdmin() ? (
                        <div className={"header__menu_item_container"}>
                            <NavLink to="/admin" className="header__menu_link">
                                <div><p>{ t("header-admin", "Adm") }</p></div>
                            </NavLink>
                        </div>
                    ) : (
                        null
                    )
                }
            </div>

            <div className={"app__header__sound"}>
                <div onClick={e => {
                    const currentAlertSetting = getAlertSoundPlayConfig();
                    setAlertSoundPlayConfig(!currentAlertSetting);
                    setPlayAlertSound(!currentAlertSetting);

                    logEvent("Click-Sound");
                }}>
                    {
                        playAlertSound ? (
                            <img alt={"spearker"} src={speaker_on} />
                        ) : (
                                <img alt={"spearker"} src={speaker_off} />
                            )
                    }
                </div>
            </div>

            <div className="app__header__inbox" onClick={() => setShowInbox(true)}>
                {
                    hasNewMsg ? (
                        <div className="app__header__inbox__new"><img alt="inbox-new" src={icon_inbox_new} /></div>
                    ) : null
                }
                <img alt="inbox" src={icon_inbox} />
            </div>

            <div className="app__header__logbutton">
                <div>
                    {
                        isAuthenticated ? (
                            <button onClick={() => logout({ returnTo: window.location.origin })}>
                                <a rel="tooltip" title={version}>{ t("header-logout", "Logout") }</a>
                            </button>
                        ) : (
                            null
                        )
                    }
                </div>
            </div>
            <Inbox show={showInbox}></Inbox>
        </header>
    );
}

export default Header;