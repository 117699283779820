import React from 'react';

import { withTranslation } from 'react-i18next';
import { isAdmin } from '../InoVibe/user';
import { eventStore, EVT_SELECT_DEVICE, EVT_DEVICE_SEARCH, EVT_DEVICE_DETAIL_CLOSE, EVT_MODIFY_DEVICE } from '../App';

import SideView from '../components/SideView';
import Search from '../components/Search';
import StatisticPanel from "../components/StatisticPanel";
import NotificationList from "../components/NotificationList";
import { ContextMenu, ContextMenuItem } from "../components/ContextMenu";

import { createFirestore } from "../InoVibe/firebase";
import { rxDevices, syncDeviceList, stopDeviceSync, InstallStatus, DeviceType } from '../InoVibe/device';
import { listInoVibeG } from '../InoVibe/InoVibeG';
import { logEvent } from "../event";

import icon_green from "../images/icon-sensor-normal.svg";
import icon_red from '../images/icon-sensor-detect.svg';
import icon_grey from '../images/icon-sensor-nosignal.svg';
import icon_uninstalling from "../images/icon_delete_waiting.png";

import icon_green_active from '../images/icon-sensor-normal-select.svg';
import icon_red_active from '../images/icon-sensor-detect-select.svg';
import icon_grey_active from '../images/icon-sensor-nosignal-select.svg';
import icon_uninstalling_active from "../images/icon_delete_waiting_selected.png";

import '../css/NaverMapMonitor.css';


class NaverMapMonitor extends React.Component {

    constructor() {
        super();

        this.state = {
            selectDevice: null,
            menuContext: null,
            modifyMode: false,
        };

        this.map = null;
        this.devices = {};
        this.markers = {};

        this.eventUnsubscribe = null;
        this.rxSubscriber = null;
    }

    componentDidMount() {
        logEvent("Page-NaverMap");

        var mapOptions = {
            zoom: 17,
            zoomControl: true,
            zoomControlOptions: {
                style: window.naver.maps.ZoomControlStyle.SMALL,
                position: window.naver.maps.Position.RIGHT_BOTTOM
            },
            scaleControl: false,
            logoControl: false,
            logoControlOptions: {
                position: window.naver.maps.Position.LEFT_BOTTOM
            },
            mapDataControl: false,
            useStyleMap: true,
        };

        this.map = new window.naver.maps.Map(document.getElementById('navermap'), mapOptions);
        window.naver.maps.Event.addListener(this.map, "zoom_changed", () => {
            console.log("Map zoomed");
            this.displayInboundMarkers(this.map, this.markers);
        });

        window.naver.maps.Event.addListener(this.map, "dragend", () => {
            console.log("Map dragend");
            this.displayInboundMarkers(this.map, this.markers);
        });

        window.naver.maps.Event.addListener(this.map, "click", () => {
            console.log("Map clicked");

            this.clearMarkerSelection();
            this.setState({ selectDevice: null, menuContext: null });
        });

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.map.setCenter(new window.naver.maps.LatLng(position.coords.latitude, position.coords.longitude));
                this.displayInboundMarkers(this.map, this.markers);
            });
        }

        syncDeviceList();

        this.rxSubscriber = rxDevices.subscribe(devices => {
            var markerChanged = true;

            for (var devid in devices) {
                var dev = devices[devid].value;

                if (devid in this.devices) {
                    if (!this.devices[devid].equal(dev)) {
                        this.devices[devid] = dev;

                        this.markers[devid].setMap(null);

                        this.markers[devid] = MarkerFactory.create(dev, false,
                            d => this.markerLeftClickHandler(d),
                            (d, x, y) => this.markerRightClickHandler(d, x, y));

                        if (this.state.selectDevice !== null && devid === this.state.selectDevice.devid) {
                            this.clearMarkerSelection();
                            this.selectDeviceMarker(devid);
                        }
                        markerChanged = true;
                    }
                } else {
                    this.devices[devid] = dev;

                    this.markers[devid] = MarkerFactory.create(dev, false,
                        d => this.markerLeftClickHandler(d),
                        (d, x, y) => this.markerRightClickHandler(d, x, y));
                    markerChanged = true;
                }
            }

            if (markerChanged) {
                this.displayInboundMarkers(this.map, this.markers);
            }
        });

        this.eventUnsubscribe = eventStore.subscribe(() => {
            const type = eventStore.getState()["type"];
            if (type === EVT_DEVICE_SEARCH || type === EVT_SELECT_DEVICE) {
                var devid = eventStore.getState()["devid"];
                console.log("Search | Select device ", devid);

                if (this.devices[devid] == null) {
                    const { t } = this.props;
                    alert(t("map-uninstalled-device", "Selected device was already removed."));
                    return;
                }

                this.clearMarkerSelection();
                this.selectDeviceMarker(devid);

                var device = this.devices[devid];
                this.map.setCenter(new window.naver.maps.LatLng(device.lat, device.lng));
                this.map.setZoom(17, true);
                this.displayInboundMarkers(this.map, this.markers);
            } else if (type === EVT_DEVICE_DETAIL_CLOSE) {
                this.clearMarkerSelection();
                this.setState({ selectDevice: null });
            }
        });

        if (isAdmin()) {
            createFirestore(db => {
                console.log("NaverMapMonitor.createFirestore");
                listInoVibeG(db).subscribe({
                    next: (devs) => {
                        devs.forEach(dev => {
                            this.devices[dev.devid] = dev;

                            const newMarker = MarkerFactory.create(dev, false, (d) => {
                                console.log("Marker clicked ", d.devid);
                                this.clearMarkerSelection();
                                this.selectDeviceMarker(d.devid);
                            });

                            newMarker.setMap(this.map);
                            this.markers[dev.devid] = newMarker;

                            console.log(dev.devid, dev.alias, dev.dev_type);
                        });
                    },
                });
            }, err => {
                console.log("NaverMapMonitor.createFirestore Failed", err);
            });
        }
    }

    componentWillUnmount() {
        if (this.rxSubscriber != null) {
            this.rxSubscriber.unsubscribe();
        }

        if (this.eventUnsubscribe != null) {
            this.eventUnsubscribe();
        }

        stopDeviceSync();
    }

    displayInboundMarkers(map, markers) {
        for (var key in markers) {
            var marker = markers[key];
            if (map.getBounds().hasLatLng(marker.getPosition())) {
                marker.setMap(map);
            } else {
                marker.setMap(null);
            }
        }
    }

    clearMarkerSelection() {
        const { selectDevice } = this.state;

        if (selectDevice == null) {
            return;
        }

        console.log("Clear prev marker selection ", selectDevice.devid);
        this.markers[selectDevice.devid].setMap(null);

        var newMarker = MarkerFactory.create(selectDevice, false,
            d => this.markerLeftClickHandler(d),
            (d, x, y) => this.markerRightClickHandler(d, x, y));

        newMarker.setMap(this.map);
        this.markers[selectDevice.devid] = newMarker;

        this.setState({ selectDevice: null });
    }

    selectDeviceMarker(devid) {
        console.log("selectDeviceMarker ", devid);

        const selectDevice = this.devices[devid];

        this.markers[devid].setMap(null);

        var newMarker = MarkerFactory.create(selectDevice, true,
            d => this.markerLeftClickHandler(d),
            (d, x, y) => this.markerRightClickHandler(d, x, y));

        newMarker.setMap(this.map);
        this.markers[devid] = newMarker;

        this.setState({ selectDevice: selectDevice });
    }

    markerLeftClickHandler(device) {
        console.log("Marker clicked ", device.devid);
        this.clearMarkerSelection();
        this.selectDeviceMarker(device.devid);
    }

    markerRightClickHandler(device, x, y) {
        console.log("Marker right clicked", device.devid, x, y);
        this.clearMarkerSelection();
        this.selectDeviceMarker(device.devid);
        this.setState({
            menuContext: {
                device: device,
                x: x,
                y: y,
            },
        });
    }

    render() {
        const { selectDevice } = this.state;

        console.log("NaverMapView.render ", selectDevice);

        const { menuContext } = this.state;

        return (
            <div className={"navermap_container"}>
                <div className={"navermap__wrapper"}>
                    <div id={"navermap"}></div>
                    <div className={"navermap__info_panel"}>
                        <Search devices={this.devices}></Search>
                    </div>
                    <div className={"navermap__statistics"}>
                        <StatisticPanel></StatisticPanel>
                    </div>
                </div>
                {
                    selectDevice != null ? (
                        <div className={"map__device_detail_container"}>
                            <SideView device={selectDevice}></SideView>
                        </div>
                    ) : (
                        <div></div>
                    )
                }
                <div className="map__notification_container">
                    <NotificationList />
                </div>

                <ContextMenu show={menuContext != null} x={menuContext ? menuContext.x : 0} y={menuContext ? menuContext.y : 0}>
                    <ContextMenuItem name={"modify"} clickHandler={e => {
                        console.log("Push modify");
                        this.setState({ menuContext: null, modifyMode: true });
                        eventStore.dispatch({ type: EVT_MODIFY_DEVICE });
                    }}></ContextMenuItem>
                    <ContextMenuItem name={"close"} clickHandler={e => {
                        this.setState({ menuContext: null });
                    }}></ContextMenuItem>
                </ContextMenu>
            </div>
        );
    }
}

class MarkerFactory {
    static create(device, selected, leftClickHandler, rightClickHandler) {
        if (device.dev_type === DeviceType.InoVibeG) {
            return new InoVibeGMarker(device, selected, leftClickHandler);
        } else {
            return new InoVibeSMarker(device, selected, leftClickHandler, rightClickHandler);
        }
    }
}

class InoVibeSMarker extends window.naver.maps.Marker {
    constructor(device, highlight, clickListener, menuClickListener) {
        const markerIcons = {
            0: icon_green,
            1: icon_red,
            2: icon_grey,
            3: icon_uninstalling,

            4: icon_green_active,
            5: icon_red_active,
            6: icon_grey_active,
            7: icon_uninstalling_active,
        };

        var isAlived = device.is_alive;
        var isAlarmed = device.is_alarmed;

        var weight = 0;

        if (device.install_status === InstallStatus.Uninstalling) {
            weight += 3;
        } else if (!isAlived) {
            weight += 2;
        } else if (isAlarmed) {
            weight += 1;
        }

        if (highlight) {
            weight += 4;
        }

        var markerOption = {
            title: device.devid,
            position: new window.naver.maps.LatLng(device.lat, device.lng),
        };

        if (device.install_status === InstallStatus.Uninstalling) {
            markerOption.icon = {
                url: markerIcons[weight],
                scaledSize: new window.naver.maps.Size(32, 32),
                origin: new window.naver.maps.Point(0, 0),
                anchor: new window.naver.maps.Point(16, 16)
            };
        } else {
            markerOption.icon = {
                url: markerIcons[weight],
                scaledSize: new window.naver.maps.Size(23.4, 34.2),
                origin: new window.naver.maps.Point(0, 0),
                anchor: new window.naver.maps.Point(11.7, 34.2)
            };
        }

        super(markerOption);

        window.naver.maps.Event.addListener(this, "click", (e) => {
            if (clickListener != null) {
                clickListener(device);
            }
        });

        window.naver.maps.Event.addListener(this, "rightclick", (e) => {
            if (menuClickListener != null) {
                console.log("Right click", e);
                menuClickListener(device, e.domEvent.clientX, e.domEvent.clientY);
            }
        });
    }
}

class InoVibeGMarker extends window.naver.maps.Marker {
    constructor(device, highlight, clickListener) {
        const loc = device.installLocation;

        var option = {
            title: device.devid,
            position: new window.naver.maps.LatLng(loc.latitude, loc.longitude),
        };

        super(option);

        window.naver.maps.Event.addListener(this, "click", () => {
            clickListener(device);
        });
    }
}

export default withTranslation()(NaverMapMonitor);