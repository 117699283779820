import React from "react";

export const ContextMenu = (props) => {
    const style = {
        top: `${props.y}px`,
        left: `${props.x}px`,
    };

    return props.show ? (
        <div className={"menu_container"} style={style}>
            { props.children }
        </div>
    ) : null;
}

export const ContextMenuItem = ({name, clickHandler}) => {
    return (
        <div className={"menu__item"} onClick={e => {
            if (clickHandler != null) {
                clickHandler();
            }
        }}>
            <p>{ name }</p>
        </div>
    );
}