import React from 'react';
import { Route, Router } from 'react-router-dom';
import App from './App';
import history from './history';


export const makeMainRoutes = () => {
    var style = {
        display: 'flex',
        height: '100%',
        width: '100%'
    };

    return (
        <Router history={history}>
            <div style={style}>
                <Route path="/" component={(props) => (
                    <App {...props} />
                )} />
            </div>
        </Router>
    );
}
